@use '../../_typography';

.gideChooserContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  .title {
    @extend .TEXTH3blackhigh-emphasisleft;
    margin-top: 24px;
    margin-left: 16px;
    width: 100%;
  }
  .subtitle {
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 16px;
    width: 100%;
  }
  .gideChooserItem {
    margin-bottom: 3px;
    border-bottom: solid 1px var(--COLOR-SECONDARY-200);
  }
  .gideChooserSelectionActions {
    position: absolute;
    right: 20px;
    bottom: 24px;
    display: flex;
    align-items: center;
  }
}
