.emptyChildArticleContent {
    font-family: Aieleron-Bold;
    font-size: 28px;
    color: $gold;
}

.completeChildArticleEditingContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center; // margin-left: -22px;
    // margin-right: -22px;
    margin-bottom: -22px; // border-left: solid 2px #656464;
    // border-right: solid 2px #656464;
    // border-top: solid 2px orange;
    height: 50px; // background-color: rgba(0,0,0,.6);

    .finishChildArticleEditingButton {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        width: 90px;
        height: 26px;
        border-radius: 14px;
        background-image: linear-gradient(280deg, #faa114, #fab317);
        opacity: .8;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        img {
            width: 26px;
            height: 26px;
        }
    }
}

.CHILDARTICLEEDITOR {
    // background-color: rgba(0, 0, 0, .1);
    background: url('/images/bg-small-retro.png') repeat;
    border: solid 2px;
    border-color: transparent $gold $gold $gold;
    padding: 10px;
    margin-top: -4px; // Pushes it up so it will be connected with owner slide.

    &.card {
        background-color: transparent !important;
    }

    &.viewer {
        border-top-color: $gold;
    }

    .CHILDARTICLEEDITOR {
        border: none;
    }

    .slideComponent.TEXT {
        background: white !important;
    }

    .slideFooter {
        height: 32px !important;
        background: transparent;
    }
}

.childArticleThreeDot {
    position: absolute;
    right: 5px;
    bottom: -5px;
    opacity: .6;
}



.childArticleEditorTabs {
    display: flex;
    margin-bottom: 20px;

    .tab {
        width: 110px;
        cursor: pointer;
        opacity: .7; // border: solid 1px rgb(212, 212, 212);       

        // padding: 5px;
        .tabDisplay {
            border-radius: 12px 0% 0% 0%;
            background: white;
            opacity: .8;
            padding: 5px;

            .tabIcon {
                color: rgb(39, 37, 37);
                width: 20px;
                height: 20px;
                margin-right: 3px;
                margin-left: 3px;
                filter: invert(95%) sepia(8%) saturate(16%) hue-rotate(321deg) brightness(86%) contrast(86%);
            }

            &:hover {
                opacity: 1;
                background: rgb(141, 132, 108);
                color: white;

                .tabIcon {
                    filter: invert(95%) sepia(0%) saturate(0%) hue-rotate(119deg) brightness(103%) contrast(107%);
                }
            }

            &.active {
                border-color: $gold;
                opacity: 1;
                background: $gold;
                color: white;

                .tabIcon {
                    color: rgb(39, 37, 37);
                    filter: invert(95%) sepia(0%) saturate(0%) hue-rotate(119deg) brightness(103%) contrast(107%);
                }
            }
        }
    }
}

.addInquiryActionContainer {
    @extend %flexRowFlexEnd;
    width: 96.5%;
    margin-bottom: 5px;

    .addInquiryButton {
        background-color: $gold;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        height: 24px;
        width: 24px; // padding: 3px;
        opacity: .8;

        &:hover {
            opacity: 1;
        }

        .addInquiryLabel,
        .editInquiryLabel {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Aileron;
            font-size: 20px;
            line-height: 22px;
            color: rgba(255, 255, 255, 1);
        }
    }
}