@import '../../../vars';
@import './../../typography';
.inputBarContainer {
  display: flex;
  flex: 1;
  min-height: 50px;  
  align-items: center;
  justify-content: center;
  .inputBar {
    display: flex;
    flex: 1;
    max-width: 746px; // $MAXSLIDEWIDTH;
    align-items: flex-end;
    .inputBarEditor {
      display: flex;
      flex: 1;
      margin-left: 9px;
      margin-right: 7px;
      @media (max-width: 400px) {
        margin-left: 5px;
        margin-right: 7px;
        &.cameraVisible {
          textarea {
            max-width: calc(100vw - 164px);
          }
        }
      }
      .cameraIcon {
        margin-right: 11px;
        margin-bottom: 5px;
        cursor: pointer;
        @media (max-width: 359px) {
          margin-right: 5px;
        }
      }
      .textSettingsIcon {
        margin-right: 6px;
        margin-bottom: 5px;
        cursor: pointer;
        @media (max-width: 359px) {
          margin-right: 2px;
        }
      }
    }
    .slideNumberContainer {
      @extend .TEXTSUBTITLE-2orangecenter;
      display: flex;
      align-items: center;
      justify-content: center;;
      margin-bottom: 8px;
      height: 22px;
      width: 25px;
      border: solid 2px var(--COLOR-PRIMARY-600);
      border-radius: 5px;
      cursor: pointer;
      &.outOfOrder {
          background-color: #a050e6;
          border-color: #a050e6;
          color: white;
      }
    }
  }
}
