@use '../../../../typography';
@use '../../../../gides-colors';

.gideTilePreviewContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--WHITES-NORMAL-1000);
  .logoBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 4px;
    background: var(--GREYS-NORMAL-50);
  }
}
.gideTilePreviewDetails {
  display: flex;
  flex-direction: column;
  .gideTilePreviewHeader {
    display: flex;
    position: relative;
  }
}
.gideTileImage {
  img {
    width: 176px;
    height: 118px;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
  }
}
.gideLanguage {
  top: 97px;
  left: 157px;
  position: absolute;
  img {
    height: 16px;
    border: solid 1px #979797;
    border-radius: 50%;
    vertical-align: inherit;
  }
}
.purchaseGide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 138px;
  width: 38px;
  height: 13px;
  position: absolute;
  border-radius: 5px;
  background-image: linear-gradient(293deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%);
  @extend .TEXTSUBTITLE-2whitehigh-emphasiscenter;
  &.donate {
    width: 50px;
    height: 13px;
    left: 126px;
    border-radius: 5px;
    background-color: var(--COLOR-GREEN-100);
    background-image: none;
  }
  &.subscription {
    width: 47px;
    height: 24px;
    left: 129px;
  }
}
.gideTileTitle {
  width: 166px;
  height: 36px;
  margin-left: 4px;
  @extend .TEXTSUBTITLEblackhigh-emphasisleft;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.gideViewDetails {
  display: flex;
  justify-content: space-between;
  @extend .TEXTSUBTITLE-2blackmedium-emphasisright;
  margin-left: 4px;
  margin-top: 2px;
  margin-right: 4px;
  .draftGide {
    @extend .TEXTSUBTITLE-2redhigh-emphasisleft;
  }
}
.createDate {
  display: flex;
  .calendarImage {
    width: 10px;
    height: 11px;
    margin-right: 2px;
  }
}
.officalGide {
  @extend .TEXTSUBTITLE-2blackhigh-emphasisleft;
  margin-left: 4px;
  margin-top: 2px;
  color: var(--COLOR-PRIMARY-700);
}
.authorDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 176px;
  height: 19px;
  margin-top: 11px;
  z-index: 1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: var(--GREYS-NORMAL-50);
  @extend .TEXTSUBTITLE-2blackhigh-emphasisleft;
}
.authorMenu {
  color: var(--COLOR-SECONDARY-600);
  margin-right: 3px;
  cursor: pointer;
  &:global(.hide) {
    display: none;
  }
}
