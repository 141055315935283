@use '../typography';

.page {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
}

.card {  
  position: relative;
}

.gidesLogo {
  margin-top: 270px;
  margin-left: 16px;
  cursor: pointer;
}

.monkeyContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100.4%;
  height: 264px;
}

.monkeyBackground {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100.4%;
  height: 264px;
  background-color: white;
  clip-path: ellipse(96.96% 110.15% at 88.51% -10.15%);
}

.monkey {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 261px;
  background-clip: border-box;
  background-image: url(/images/spacemonkey.gif);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  clip-path: ellipse(96.96% 110.15% at 88.51% -10.15%);
}

.title {
  @extend .TEXTTIwhiteboldhigh-emphasisleft;
  margin-top: 40px;
  margin-left: 16px;
}

.description {
  @extend .TEXTSUBTITLEwhitemedium-emphasisleft;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 500px;
}

.buttonContainer {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.button {
  width: 100%;
  height: 32px;
  border-radius: 3px;
  background-image: linear-gradient(287deg, var(--COLOR-PRIMARY-700) 101%, #fab317 29%);  
}

.bigDot {
  border-radius: 6px;
  width: 12px;
  height: 12px;
  border: solid 3px var(--WHITES-NORMAL-1000);
  background-image: linear-gradient(321deg, var(--COLOR-PRIMARY-700) 111%, #fab317 31%);
  position: absolute; 
  top: 50%;
  left: 7.8%; 
  z-index: 9999;
}

.littleDot {
  border-radius: 4px;
  width: 8px;
  height: 8px;
  border: solid 3px var(--WHITES-NORMAL-1000);
  background-image: linear-gradient(321deg, var(--COLOR-PRIMARY-700) 111%, #fab317 31%);
  position: absolute;
  top: 90%;
  left: 52%;
  z-index: 9999;
}

@media (min-width: 768px) {
  .page {    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    width: 784px;
    height: 437px;
    border-radius: 15px;
    background-color: var(--GREYS-NORMAL-200);
  }
  
  .gidesLogo {
    margin-top: 18px;
    margin-left: 26px;
  }

  .monkeyContainer {
    width: 652px;
    height: 264px;
  }

  .monkeyBackground {
    width: 652px;
    height: 264px;
    background-color: white;
    clip-path: ellipse(76.96% 110.15% at 78.51% -10.15%);
  }

  .monkey {    
    width: 649px;
    height: 261px;
    clip-path: ellipse(76.96% 110.15% at 78.51% -10.15%);
  }

  .title {
    margin-top: 188px;
  }
  
  .description {
  }
  
  .buttonContainer {
    
  }
  .button {    
    width: 125px;
  }

  .bigDot {
    left: 14.6%;
  }

  .littleDot {
    left: 49%;
  }
}


