$breakpoint: 1074px;

@media (max-width: $breakpoint) {
    .sidebar {
        position: fixed;
        z-index: 1000; // 9999;
    }

    .sidebarDimmer {
        display: block !important;
        position: fixed !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.forceOverlay {
    .sidebar {
        position: fixed;
        z-index: 1000; // 9999;
    }

    .sidebarDimmer {
        display: block !important;
        position: fixed !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.sidebar {
    flex: 0 0 auto;
    top: 0;
    bottom: 0;    
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
}

.sidebarInner {
    // overflow: auto;
    height: 100%;
    max-width: 100vw;
}

.sidebarDimmer {
    display: none;
}
