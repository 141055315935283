@use '../../../gides-colors';
@use '../../../typography';

.gideTitleContainer {
  display: flex;
  align-items: center;
  .gideImageDropZone {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 45px;
    height: 32px;
    background-color: var(--COLOR-SECONDARY-200);
    border-radius: 4px;
    &.hasImage {
      background-color: var(--WHITES-NORMAL-1000);
    }
    &.hideImageInMobile {
      @media (max-width: 520px) {
        display: none;
      }
    }
    // &.topHeaderImage {
    //   @media (max-width: 380px) {
    //     display: none;
    //   }
    // }
  }
  .hideExpanderInDesktop {
    display: flex;
    align-items: center;
    @media (min-width: 520px) {
      display: none;
    }
  }
  .gideBarImageContainer {
    width: 46px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    .gideBarGideImage {
        border-radius: 4px;
        width: 46px;
        height: 32px;
        object-fit: cover;
    }
  }
  .gideBarGideTitle {
    display: flex;
    padding-left: 12px;
    align-items: center;
    cursor: default;
    .gideBarTitleText {
      display: block;
      @extend .TEXTBODYblackregularhigh-emphasisleft;
      max-width: calc(100vw - 430px);
      // width: calc(100vw - 430px) !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &.leftSidebarOpen { // 340
        @media (min-width: 1075px) {
          max-width: calc(100vw - 770px);
        }
      }
      &.guestViewer {
        max-width: calc(100vw - 180px);
        &.leftSidebarOpen {
          @media (min-width: 1075px) {
            max-width: calc(100vw - 520px);
          }
        }
      }
      &.mobileView {
        @media (max-width: 520px) {
          max-width: calc(100vw - 180px);
        }
      }
      &.hideGideTitleOnMobile {
        @media (max-width: 520px) {
          display: none;
        }
      }
    }
    .editIcon {
      margin-left: 10px;
      &.hideEditIconInMobile {
        @media (max-width: 520px) {
          display: none;
        }
      }
    }
  }
}
