@use '../../../typography';

.imageCarouselContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 0px;
  &.dark {
    background-color: var(--GREYS-NORMAL-1000);
  }
  .gideCarousel {
    position: relative;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    flex: 1;
    img {
      max-width: 100%;
      object-fit: scale-down;
    }
    .leftNavigation {
      position: absolute;
      top: 0; // calc(50% - 30px);
      bottom: 0;
      left: 0px;
      width: 50px;
      background-color: rgba(0,0,0, .05);
      opacity: .8;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
        opacity: 1;
        background-color: rgba(0,0,0, .1);
      }
      .navIcon {
        position: absolute;
        left: 10px;
        top: var(--navTop);
      }
    }
    .rightNavigation {
      position: absolute;
      top: 0; // calc(50% - 30px);
      bottom: 0;//
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0px;
      width: 50px;
      opacity: .8;
      background-color: rgba(0,0,0, .05);
      &:hover {
        cursor: pointer;
        opacity: 1;
        background-color: rgba(0,0,0, .1);
      }
      .navIcon {
        position: absolute;
        right: 10px;
        top: var(--navTop);
      }
    }
    .bottomNavigation {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      .imageListItemCircle {
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, 0.35);
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 4px;
        &:hover {
          cursor: pointer;
        }
        &.selected {
          background-color: #ffffff;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .customAudioPlayer {
    width: 100%;
  }
  .attributionCaption,
  .imageCaption {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0 10px 0 10px;
  }
  .imageCaption {
    flex: 1;
    max-height: 192px;
    overflow: hidden;
    @extend .TEXTBODYblackregularmedium-emphasisleft;
  }

  .carouselIcon {
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  .carouselImage {
    object-fit: cover;
    &:hover {
      cursor: pointer;
    }
    // transition: src 1s;
  }

  .thumbnailContainer {
    margin-top: 9px;
    margin-bottom: 15px;
    display: flex;
    align-content: center;
    align-items: center;

    .thumbnailImage {
      object-fit: cover;
      margin: 3px;
      width: 48px;
      height: 48px;
      max-width: 48px;
      max-height: 48px;
      border-radius: 3px;
      opacity: 0.9;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);

      &:hover {
        // border: solid 1px $gold;
        opacity: 1;
        cursor: pointer;
      }

      &.active {
        // border: solid 1px #ffffff;
        opacity: 1;
        width: 54px;
        height: 54px;
      }
    }
  }
}



@media only screen and (max-width: 570px) {
  .imageCarouselContainer {
    .imageCaption {
      max-height: 96px;
    }
  }
}

// .EDGE, .HALF, .NORMAL {
  .imageCarouselContainer {
    .gideCarousel.respectEdge {
      width: 100% !important;
      img.carouselImage {
        min-width: 100%;
        object-fit: cover;
      }
    }
  }
// }