@use '../../../../typography';

.gideBlogImageTopPreviewContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0 1px 11px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    background: rgba(65, 105, 225, 0.068);
    cursor: pointer;
  }
  .linkPreviewImage {
    position: relative;
    .primaryImage {
      object-fit: cover;
      height: 451px;
      max-height: 451px;
      border-radius: 5px 5px 0 0;
      width: 100%;
      max-width: 752px;
    }
    .circleBackground {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--GREYS-NORMAL-300);
    }
  }
  .linkPreviewDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 10px 2px 10px;
    height: 153px;
    .linkPreviewTitleContainer {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .linkPreviewTitle {
        @extend .TEXTH1blackhigh-emphasisleft;
        max-height: 36px;
        overflow: hidden;
      }
      .linkPreviewDescription {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        height: 54px !important;
        max-height: 54px !important;
        overflow: hidden !important;
        padding: 0px 6px 0 6px;
      }
      .footerInfo {
        display: flex;
        align-items: center;
      }
      // .linkPreviewUrl {
      //   padding-left: 6px;
      //   padding-bottom: 6px;
      //   @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      //   a {
      //     @extend .TEXTSUBTITLEblueleft;
      //     margin-left: 2px;
      //   }
      // }
    }
  }
}
