.imageEditorCropperContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: black;
    max-width: 100% !important;
    .imageEditorCropper {
        display: flex;
        max-width: calc(100% - 30px) !important;
        max-height: calc(100% - 50px) !important;
        
        max-width: 100%;
    }

    .imageEditorActions {
        display: flex;
        justify-content: center;
        margin-top: 43px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        .rotationSliderComponent {
            display: flex;
            flex: 1;
            // width: 263px;
            max-width: 263px;
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .saveImageEdits {
        display: flex;
        justify-content: flex-end;
    }

    .e-control-wrapper.e-slider-container.e-material-slider .e-slider .e-handle.e-handle-first {
        background: white !important;
        border-color: white !important;
        top: 3px !important;
        width: 6px !important;
        height: 6px !important;
    }

    .e-control-wrapper.e-slider-container .e-slider .e-handle {
        background: white !important;
        border-color: white !important;
        top: 3px;
        width: 6px !important;
        height: 6px !important;
    }

    .e-control-wrapper.e-slider-container.e-horizontal .e-slider-track {
        height: 4px;
        border-radius: 2px;
    }
}

.rotationSliderComponentTooltip {
    &.e-slider-tooltip.e-tooltip-wrap.e-popup {
        @extend .TEXTSUBTITLEwhitehigh-emphasiscenter;
        z-index: 1010 !important;
        display: block;
        background-color: transparent !important;
        transform: translateY(10px) rotate(45deg) !important;
        z-index: 10;
        transition: none 0s ease 0s !important;
        width: 50px !important;
    }
    &.e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default .e-tip-content.e-material-tooltip-hide,
    .e-slider-tooltip.e-tooltip-wrap.e-popup .e-tip-content.e-material-tooltip-hide {
      color: white !important;
    }
}
