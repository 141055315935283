@use '../../_typography';

.tabMenu {
    display: flex;
    width: 100%;
    min-height: 40px;
    .tab {
        @extend .TEXTSUBTITLE-2blacklow-emphasiscenter;
        &.default {
          @extend .TEXTSUBTITLEblacklow-emphasiscenter;
        }
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding-top: 1px;
        margin-bottom: -2px;
        padding-bottom: 3px;
        border-bottom: 2px solid rgba(0,0,0,0);
        &.active, 
        &.active:hover {
            @extend .TEXTSUBTITLE-2orangeleft;
            border-bottom: 2px solid var(--COLOR-PRIMARY-600);            
        }
        &.active.default,
        &.active:hover.default {         
          @extend .TEXTSUBTITLEblackhigh-emphasiscenter;
          border-bottom: 2px solid var(--COLOR-PRIMARY-600);            
        }        
        &:hover {
            @extend .TEXTSUBTITLE-2blackmedium-emphasiscenter;
            border-bottom: 2px solid var(--GREYS-NORMAL-200);
            &.default {
              @extend .TEXTSUBTITLEblackhigh-emphasiscenter;
            }
        }
    }
}