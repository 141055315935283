.collapseSlide {
    .collapseHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .card {
        width: 100%;
    }

    .childArticleEditorSlide {
        border: solid 1px purple;
        // background: url(/images/bg-small-retro.png) repeat;
    }

    .editButton {
        margin-right: 10px;
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

    .childArticleViewer {
        &.removeBorder {
            border-width: 0px;
            background: transparent;
            padding: 0px;
        }
    }
}