.resizeableModal {
  display: flex !important;
  flex: 1;
  position: relative;
  background-color: var(--WHITE-BACKGROUND-01);
  z-index: 2;
  .ui.modal > .content {
    display: flex !important;
  }
  .modalDragHandleContainer {
    display: none;
    width: 100%;
    justify-content: center;
    @media (max-width: 520px) {
      display: inherit;
    }
    .modalDragHandle {
      margin: 5px;
      width: 45px;
      height: 6px;
      border-radius: 3px;
      background-color: #dedede;
      &:hover {
        cursor: grab;
      }
    }
  }
  .resizeableModalContentContainer {
    background-color: var(--WHITE-BACKGROUND-01);
    @extend %flexColumnBase;
    flex: 1;
    position: relative;
    &.content {
      display: flex !important;
    }
    &.EDGE,
    &.HALF {
      .slideView .card {
        max-width: 100%;
        width: 100% !important;
      }
    }
    .resizeableModalHeader {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding-left: 12px;
      padding-right: 7px;
      .resizeableModalCloseIcon {
        margin-left: 10px;
        width: 24px;
        height: 24px;
        &:hover {
          cursor: pointer;
        }
      }
      .resizeableModalToolbar {
        display: flex;
        flex: 1;
        justify-content: space-between;
        .resizeableModalLeftToolbarContent {
          display: flex;
          // flex: 1;
        }
        .resizeableModalCenterToolbarContent {
          display: flex;
          // flex: 1;
          justify-content: center;
        }
        .resizeableModalRightToolbarContent {
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }
      }
    }
    .resizeableModalContent {
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      flex: 1;
    }
    .resizeableModalFooterActionContainer {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: top;
      border-radius: 3px;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      box-shadow: 0 -2px 10px 7px rgba(0, 0, 0, 0.02);
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      .footerOverlay {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  &.ui.fullscreen.modal {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    width: 100% !important;
    margin: 0px !important;
    // position: fixed;
    // max-height: calc(100% - 52px);

    top: var(--modalTop);
    bottom: var(--modalBottom);
    width: 100%;
    max-width: var(--modalWidth);
    left: 0px;
    right: 0px;
    &.noMargin {
      max-height: 100%;
      left: auto !important;
      right: auto !important;
    }
    // height: auto !important;
    height: calc(100% - (var(--modalTop) + var(--modalBottom)));
    position: absolute;
    &.useMaxWidth {
      left: unset !important;
      right: calc((100vw - #{$MAXSLIDEWIDTH}) / 2) !important;
      &.bindRightOnly {
        left: unset !important;
        right: calc((100vw - #{$MAXSLIDEWIDTH}) / 2) !important;
      }
      @media (max-width: 752px) {
        left: 0px !important;
        right: 0px !important;
        &.bindRightOnly {
          left: unset !important;
          right: 0px !important;
        }
      }
      @media (max-width: 382px) {
        left: 0px !important;
        right: 0px !important;
        max-width: 100%;
      }
      .emptySpan {
        width: 72px;
      }
      &.showLeftSidebar {
        // left: calc(165px + (100vw - #{$MAXSLIDEWIDTH}) / 2) !important;
        right: calc(((100vw - #{$MAXSLIDEWIDTH}) / 2) - 165px) !important;
        @media (max-width: 1081px) {
          left: 330px !important;
          max-width: calc(100% - 330px);
        }
        &.bindRightOnly {
          left: unset !important;
          max-width: 382px !important;
          right: calc((100vw - (#{$MAXSLIDEWIDTH} + 330px)) / 2) !important;
          @media (max-width: 1081px) {
            right: 0px !important;
          }
          @media (max-width: 703px) {
            left: 330px !important;
            max-width: calc(100vw - 330px) !important;
          }
        }
        @media (max-width: 520px) {
          visibility: hidden;
        }
      }
      &.showRightSidebar {
        left: unset !important;
        right: calc(300px + ((100vw - #{$MAXSLIDEWIDTH}) / 2)) !important;
        @media (max-width: 1377px) {
          right: 600px !important;
          left: 0px !important;
          max-width: calc(100% - 600px);
        }
        &.bindRightOnly {
          @media (max-width: 1377px) {
            left: unset !important;
            // right: calc(300px + ((100vw - #{$MAXSLIDEWIDTH}) / 2)) !important;
            max-width: 382px;
          }
          @media (max-width: 975px) {
            left: 0px !important;
            max-width: calc(100% - 600px) !important;
          }
        }
        @media (max-width: 920px) {
          visibility: hidden;
        }
      }
    }
    &.free {
      left : auto !important;
      right: auto !important;
    }
  }

  &.ui.modal > .content > .description {
    display: flex !important;
    flex: 1;
    height: 58px !important;
  }

  &.ui.modal > .actions {
    padding: 0px !important;
  }

}

// .ui.inverted.dimmer {
//   background-color: rgba(0, 0, 0, 0.6) !important;
// }

@media only screen and (max-width: 767px) {
  .resizeableModal.ui.modal > .content {
    padding: 0px !important;
    display: flex !important;
  }

  .resizeableModal.ui.modal > .content > .description {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 520px) {
  .resizeableModal {
    &.ui.fullscreen.modal {
      top: var(--modalMobileTop);
      bottom: var(--modalMobileBottom);
      height: calc(100% - (var(--modalMobileTop) + var(--modalMobileBottom)));
    }
  }
}


.modals.dimmer .resizeableModal.ui.scrolling.modal {
  margin: 0px !important;
}
