.homeContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: var(--WHITE-BACKGROUND-01);
  > div {
    width: 100%;
  }
  .carousel {
    display: flex;
    position: relative;
    height: 264px;
    @media (max-width: 767px) {
      height: 294px;
    }
  }
  .gideSearchResultHeader {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    background-color: var(--WHITES-NORMAL-1000);
    margin-top: 2px;    
     @media (max-width: 520px) {
      .cancelSearchButton {
        display: none;
      }
    }
  }
}
.homeConnectContainer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
