@use '../../../typography';

.previewAvatarContainer {
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    .authorUsername {
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    }
  }
  .authorDetails {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .authorImage {
    width: 18.3px;
    height: 18.3px;
    box-shadow: 0 1px 2px 0 var(--GREYS-NORMAL-150);
    border: solid 0.5px var(--WHITES-NORMAL-1000);
    border-radius: 50%;
  }
  .authorUsername {
    display: flex;
    margin-left: 3px;
  }
}
