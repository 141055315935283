.slideItemTouchSettingsContainer {
  background: transparent;
  border-radius: 2px;
  position: relative;
  background: white;
  .slideItemTouchSettings {
    padding: 2px;
    background: white;
    border-radius: 2px;
    .touchActions {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
    }
    .title {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      padding: 10px;
    }
    .row1, .row2, .linkOptions, .openUrlLocation {
      align-items: center;
      display: flex;
      justify-content: space-around;
    }
    .openUrlLocation {
      .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
        background-color: rgb(255, 178, 19) !important;
      }
    }
    .finishLinkActions {
      display: flex;
      justify-content: flex-end;
    }
    .row2, .link, .finishLinkActions, .linkActions, .openUrlLocation {
      margin-top: 15px;
    }
    .row2 {
      margin-bottom: 15px;
    }
    .linkOptions {
      .e-radio:checked + label::after {
        background: rgb(255, 178, 19) !important;
        color: yellow !important;
      }
      .e-radio:checked + label::before {
        border-color:rgb(255, 178, 19) !important;
      }
    }
    .actionButton {
      @extend .TEXTSUBTITLEwhitehigh-emphasiscenter;
    }
    .action {	
      display: flex;
      width: 106px;
      height: 30px;
      border-radius: 25px;
      padding-left: 8px;
      padding-right: 8px;
      justify-content: space-between;
      align-items: center;
      margin: 5px;
      background: red;
      color: #cccccc;
    }
  }
}
