.slideEditPanelContainer {
  display: flex;
  width: 100%;
  outline: none !important;
  .slideEditPanelActions {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: var(--WHITES-NORMAL-800);
    border-radius: 3px;
    &.expanded,
    &.multiEdit {
      z-index: 2000;
    }
    &.expanded {
      height: 92px;
      padding: 0 6px 6px 6px;
      .row2 {
        padding-top: 8px
      }
    }
    &.multiEdit {
      padding: 6px;
    }

    .slideEditPanelActionRow {
      display: flex;
      width: calc(100% - 4px);
      &.slideSelection {
        width: 100%;
      }

      justify-content: space-between;
      margin-left: 4px;
      &.setFlexEnd {
        justify-content: flex-end;
      }
      .slideEditPanelAction {
        @extend .TEXTSUBTITLE-2blackmedium-emphasiscenter;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        visibility: hidden;
        cursor: pointer;
        span {
          display: none;
        }
        &.advancedEdit,
        &.cleanEdit {
          visibility: visible;
        }
        :hover {
          cursor: pointer;
        }
        &.displayAllActions {
          padding: 2px;
          height: 40px;
          visibility: visible;
          border-radius: 3px;
          width: calc((100% - 70px) / 5);
          min-width: 55px;
          background-color: #fafafa;
          span {
            display: inherit;
          }
        }
        .multiIcon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .expandCollapse {
        max-height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;;
        flex-direction: column;
        cursor: pointer;
        .slideEditSlideNumber {
          @extend .TEXTSUBTITLE-2orangecenter;
          margin-bottom: -7px;
        }
        img.advancedMode {
          width: 18px;
          height: 18px;
        }
      }
      .slideAdditionsInfoContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .slideAdditionItemDetail {
          @extend .TEXTSUBTITLE-2blacklow-emphasiscenter;
          display: flex;
          align-items: center;
          margin-right: 27px;
        }
      }
    }
  }
}
