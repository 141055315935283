@use '../../../../typography';
@use '../../../../gides-colors';

.channelListItemPreviewContainer {
  display: flex;
  position: relative;
  width: 100%;
  .logo {
    margin-top: 15px;
    margin-left: 26px;
    position: absolute;
    z-index: 100;
    color: var(--WHITES-NORMAL-1000);
  }
  .logoBackground {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    margin-top: 14px;
    margin-left: 23px;
    background: var(--GREYS-NORMAL-100);
  }
  .channelSelection {
    width: 18px;
    height: 18px;
    margin: 12px 5px 0 5px;
    border-radius: 50%;
    background: white;
  }
}
.channelListItemImage {
  img {
    width: 76px;
    height: 54px;
    border-radius: 3px;
    object-fit: cover;
  }
}
.channelListItemPreviewDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 5px;
  margin-top: 10px;
}
.channelListItemTitle {
  height: 18px;
  max-width: calc(100% - 95px);
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  @extend .TEXTSUBTITLEblackhigh-emphasisleft;
}
.channelListItemGideDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 4px;
  margin-right: 12px;
  @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
}
.channelInfo {
  display: flex;
  align-items: center;
  @extend .TEXTSUBTITLE-2blackhigh-emphasisleft;
  color: var(--COLOR-BLUE-100);
}
.channelInfoDetail {
  margin-left: 5px;
}
