@use '../../typography';
.heading {
  @extend .TEXTTIblackboldhigh-emphasisleft;
  width: 100%;
  padding-left: 5px;
}
.subHeading {
  @extend .TEXTBODYblackregularmedium-emphasisleft;
  width: 100%;
  margin-top: 16px;
  padding-left: 5px;
}
