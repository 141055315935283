.gidesAppHeaderContainer {
  display: flex;
  height: 52px;
  align-items: center;
  .hamburgerMenu {
    display: flex;
    align-items: center;
    margin: 0px 12px 0 12px;
    cursor: pointer;
  }
  .loggedOutView {
    .signUp,
    .displayMobileSearch {
      @media (max-width: 520px) {
        display: none;
      }
    }
    display: flex;
  }
  // .menuSeparator {
  //   width: 1px;
  //   height: 52px;
  //   border: solid 0.5px #e4e4e4;
  //   @media (max-width: 520px) {
  //     // height: 39px;        
  //     display: none;
  //   }
  // }  
  .gidesLogoContainer {
    width: 285px;
    margin-top: 2px;
    @media (max-width: 700px) {
      width: inherit;
    }
    .logo {
      margin-left: 5px;
      @media (max-width: 520px) {
        margin-left: 5px;
      }
    }

  }
  .searchBar {
    display: flex;
    flex: 1;
    align-items: center;
    .searchBackNavigator {
      display: flex;
    }
    .widescreenSearch {
      display: flex;
      flex: 1;
      align-items: center;
      margin-right: 41px;
      @media (max-width: 520px) {
        display: none;
      }
    }
    .mobileSearch {
      display: flex;
      flex: 1;
      align-items: center;
      display: none;
      margin: 0px 9px 0px 12px;
      @media (max-width: 520px) {
        display: flex;
      }
    }
  }
  .mobileSearchButton {
    display: none;
    align-items: center;
    @media (max-width: 520px) {
      display: flex;
    }
  }
  .userSection {
    display: flex;
    align-items: center;
    margin-right: 12px;
    @media (max-width: 520px) {
      margin-right: 7px;
    }
    .avatarSeparator {
      margin: 0px 15px 0px 21px;
      @media (max-width: 520px) {
        margin: 0px 15px 0px 15px;
      }
      height: 52px;
      width: 1px;
      opacity: 0.08;
      border: solid 0.5px #484848;
    }
    .userAvatarMenuTrigger {
      cursor: pointer;
    }
  }
}
