@import '../../../../gides-colors';
.radioButtonListContainer {
  display: flex;
  align-items: center;
  background-color: var(--COLOR-SECONDARY-200);
  .separator {
    border: solid 1px var(--COLOR-SECONDARY-300);
    width: 1px;
    height: 20px;
    margin-left: -.5px;
    margin-right: -.5px;
  }  
}
