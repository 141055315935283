
@use '../../typography';
@use '../../gides-colors' as colors;
@use "sass:color";

$successBackground: color.scale(colors.$color-green-100, $lightness: 90%);
$errorBackground: color.scale(colors.$color-red-100, $lightness: 90%);
$warningBackground: color.scale(colors.$color-yellow-100, $lightness: 90%);
$infoBackground: color.scale(colors.$color-blue-100, $lightness: 90%);
$messageBackground: color.scale(colors.$color-blue-100, $lightness: 90%);

.container {
  position: relative;
  width: 100%;
  z-index: 1001;
}

.notification {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  min-height: 48px;  
  align-items: center;
  background-color: white;

  .title {
    @extend .TEXTSUBTITLEblackhigh-emphasisleft;
    padding-left: 20px;
    flex: 0 1 auto;
  }

  .message {    
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    padding-left: 6px;
    flex: 1 1 auto;
    word-break: break-all;
  }

  .closeButton {    
    flex: 0 0 auto;
    margin: 0px 15px;
    &:hover {
      cursor: pointer;
    }
  }

  &.success {
    background-color: $successBackground;
    border-left: 3px solid var(--COLOR-GREEN-100);
    .closeButton {
      color: var(--COLOR-GREEN-100);
    }
  }

  &.error {
    background-color: $errorBackground;
    border-left: 3px solid var(--COLOR-RED-100);
    .closeButton {
      color: var(--COLOR-RED-100);
    }
  }

  &.warning {
    background-color: $warningBackground;
    border-left: 3px solid var(--COLOR-YELLOW-100);
    .closeButton {
      color: var(--COLOR-YELLOW-100);
    }
  }

  &.info {
    background-color: $infoBackground;
    border-left: 3px solid var(--COLOR-BLUE-100);
    .closeButton {
      color: var(--COLOR-BLUE-100);
    }
  }

  &.message {
    background-color: $messageBackground;
    border-left: 3px solid var(--COLOR-BLUE-100);
    .closeButton {
      color: var(--COLOR-BLUE-100);
    }
  }
}
