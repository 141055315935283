@use '../../../typography';

.gideOwnerViewPanelContainer {
  // position: fixed;
  background-color: white;
  // -webkit-transition: opacity .3s, top 1s;
  transition: opacity .5s, height .5s, left 0.3s;
  // top: 1px;
  display: flex;
  // opacity: 0;
  z-index: 1;
  height: 52px;
  overflow: hidden;  
  &.leftSidebarOpen {
    // left: 0;
    padding-left: 0px;
  }
  &.doNotDisplayOwnerPanel {
    // top: 0px;
    height: 0px;
    opacity: 0;
  }
  &.displayOwnerPanel {
    opacity: 1;
    // height: 52px;    
    @media (max-width: 520px) {
      height: 48px;
      top: 48px;
    }
  }
  left: 0px;
  right: 0px;
  align-items: center;
  height: 52px;

  box-shadow: 0 4px 5px 0 
    rgba(0, 0, 0, 0.04), 0 3px 16px 3px 
    rgba(0, 0, 0, 0.02), 0 1px 2px 0 rgba(0, 0, 0, 0.01);
  .authorGideDetails {
      margin-left: 11px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      .gideAuthor {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        display: flex;
        align-items: center;
      }
      .gideDetails {
        @extend .TEXTSUBTITLE-2blacklow-emphasisleft;
        display: flex;
        align-items: center;
      }
  }
}
