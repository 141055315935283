.SCROLL {
  .authorizationInfo {
    visibility: hidden;
  }
}

.authorizeBlockSlide {
  .card {
    width: 100%;
  }

  .childArticleEditorSlide {
    border: solid 1px purple;
    background: url(/images/bg-small-retro.png) repeat;
  }

  .editButton {
    margin-right: 10px;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  .childArticleViewer {
    &.removeBorder {
      border-width: 0px;
      background: transparent;
      padding: 0px;
    }
  }

  .authorizationInfo {
    @extend %flexRowNormal;
    position: relative;

    .passcodeTypeIcon {
      position: absolute;
      left: 15px;
      top: 0px;

      img {
        width: 20px;
        height: 20px;
      }
    }
    .authorizationIcon {
      width: 20px;
      height: 20px;
    }
  }
}
