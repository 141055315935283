@use '../typography';
.avatarMenuContainer {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: var(--COLOR-SECONDARY-200);
  overflow-x: hidden;
  :global(.accordion.ui.styled) {
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 8px;
  }
  .avatarMenuHeader {
    display: flex;
    flex: 1;
    align-items: center;
    min-height: 72px;
    background-color: var(--WHITES-NORMAL-1000);
    .headerTitleSection {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 16px;
      &.readOnly {
        margin-left: 0px;
      }
      .avatarReadonlyBranding {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--WHITES-NORMAL-1000);
        position: relative;
        width: 100%;
        .brandingImage {
          width: 340px;
          height: 229px;
          @media screen and (max-height: 430px) {
            display: none;
          }
        }
        .brandingImageLabel {
          position: absolute;
          bottom: 108px;
          left: 15px;
          font-family: HILVCC+Boing;
          font-size: 35px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 0.29px;
          color: var(--WHITES-NORMAL-1000);
        }
      }
      .headerTitle {
        @extend .TEXTH4blackmedium-emphasisleft;
      }
      .profileLink {
        @extend .TEXTSUBTITLE-2blueleft;
        cursor: pointer;
      }
    }
  }
  .menuItemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--COLOR-SECONDARY-200);
    .userShortuct,
    .channelFavorite {
      background-color: var(--WHITES-NORMAL-1000);
      display: flex;
      flex: 1;
      padding: 0px 2px 1px 3px;
      cursor: pointer;
      margin-bottom: 1px;
      a {
        width: 100%;
      }
    }
    .hyperlinkButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      cursor: pointer;
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
      span {
        text-decoration: underline;
      }
    }
  }
}
