@import '../typography';
.footerContainer {
  display: flex;
  // flex-direction: column;  
  align-items: center;
  // min-width: 100%;
  // width: 100%;
  max-width: 100vw;
  background-color: var(--WHITES-NORMAL-1000);
  &.showOverModalBackdrop {
    z-index: 1001;
  };
  .viewbarOnly {
    height: 0px;
  }
  .footerInputBar {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    // padding: 0 10px 0 10px;  
  }
}

.exit {
  position: relative;
  margin-top: -20px;
  width: 375px;
  height: 48px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: var(--GREYS-NORMAL-700);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .exitButton {
    @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 98px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--COLOR-SECONDARY-600);
    .exitButtonText {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      color: var(--WHITES-NORMAL-1000, 0.95);
    }
  }
}

.exitImage {
  width: 24px;
  height: 24px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.exitButtonFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
