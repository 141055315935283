@use '../../typography';

.gideTitleSlideContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  &.placeHolderContainer {
    margin-top: -5px;
    margin-bottom: 5px;
    height: 104px;
    flex: none;
    border-radius: 7px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: var(--GREYS-NORMAL-50);
    display: flex;
    justify-content: center;
    align-items: center;
    .gideTitle {      
      @extend .TEXTH1blackmedium-emphasiscenter;
    }
  }
  .noTitleFormatContianer {
    display: flex;
    align-items: center;
    height: 48px;
    border-left: var(--COLOR-BLUE-100) solid;
    background-color: var(--COLOR-BLUE-20);
    .message {
      height: 20px;
      margin-left: 20px;
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      .bold {
        @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      }
    }
  }
  .gideTitle {
    display: flex;
    padding-top: 2px;
    margin-left: 8px;
    word-break: break-word;
    @extend .TEXTTIblackboldhigh-emphasisleft;
    &.missing {
      @extend .TEXTTIblackboldlow-emphasisleft;
    }
  }
  .gideTitleImage {
    width: 100%;
    --aspect-ratio-w: 3;
    --aspect-ratio-h: 2;
    img {
      object-fit: cover;
    }
  }
}
