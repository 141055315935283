@use '../../_typography';
@use '../../flex';

.articleSlideReferenceHeaderContainer {
  @extend %flexRowNormal;
  background-color: white;
  height: 37px;
  .articleHeaderSlideReferenceAuthorImage {
    width: 28px;
    height: 28px;
    border: solid 1px white;
    border-radius: 50%;
    margin-right: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 2px #ffffff;
  }
  .articleHeaderSlideReferenceGideImage {
    object-fit: cover;
    margin-left: 3px;
    width: 42px;
    height: 30px;
    border-radius: 3px;
  }
  .articleHeaderSlideReferenceDetails {
    @extend %flexColumnBase;
  }
  .articleSlideReferenceHeaderGideDetails {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
    .articleSlideReferenceHeaderGideTitleDetails {
      display: flex;
      flex: 1;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @extend .TEXTSUBTITLEblackhigh-emphasisleft;
    }
    .articleSlideReferenceHeaderGideDetail {
      display: flex;
      justify-content: space-between;
      width: 125px;
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    }
  }
}
