.imageGallery {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .imageGalleryImage {    
    object-fit: cover;
    &.clickable {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .imageGalleryImageOverlay {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 18px;
    width: 18px;
  }
}

// @media only screen and (max-width: 768px) {
//   .imageGallery {
//     .imageGalleryImage {
//      
//     }
//   }
// }

// @media only screen and (max-width: 768px) {
//   .imageGallery {
//     .imageGalleryImage {
//      
//     }
//   }
// }