.pickerModalContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px var(--COLOR-SECONDARY-200);
  background-color: white;
  max-width: 360px;
  width: 100%;
  .header {
    display: flex;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .horizontalLine {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: var(--COLOR-SECONDARY-200);
  }
  .actions {    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 11px;
    margin-bottom: 8px;
  }
}