@use '../../typography';

.roundedCornerButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 3px;
    min-width: 86px;
    height: 30px;
    &.columnMode {
        flex-direction: column;
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &.disabled {
        opacity: .5;
        &:hover {
            cursor:crosshair;
        }
    }
    .roundedCornerButtonLabel {
        @extend .TEXTSUBTITLEwhitehigh-emphasiscenter;
        // margin-left: 5px;
    }
    .roundedCornerButtonIcon {
        width: 24px;
        height: 24px;
        margin-left: 5px;
    }
}
