.navigideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;  
  // max-height: calc(100vh - 290px);
  padding: 0 5px 0 5px;
  @extend %inputRange;
  max-height: calc(100vh - 221px);
  &.author {
    max-height: calc(100vh - 245px);
  }
}
.navigideMoveTo {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 91.6px;
  width: 100%;  
  max-width: 100vw;
  background-color: var(--WHITES-NORMAL-1000);
  .moveToDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    .slideNumberDetails {
      &.hideSlideNumbers {
        display: none;
      }
      .numberOfSlide {
        margin-right: 2px;
      }
      display: flex;
      .number {
        color: var(--COLOR-PRIMARY-600);
      }
    }
  }
}
.navigideSliderContainer {
  width: 100%;
  margin-top: 12px;
  border-bottom: solid 1px var(--COLOR-SECONDARY-200);
}
.sliderTicks {
  height: 3px;
  position: absolute;
  margin-top: 11px;
  background: repeating-linear-gradient(to right, transparent, transparent 17px, #dedede 10px, #dedede 20px);
}
.navigideTOC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.navigideHeader {
  display: flex;
  align-items: center;
  width: 351px;
  height: 36px;
  border-radius: 3px;
  margin-left: 5px;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
  }
  &.tocLevel0 {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &.tocLevel1 {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &.tocLevel2 {
    padding-left: 5px;
  }

  &.tocLevel3 {
    padding-left: 10px;
  }

  &.tocLevel4 {
    padding-left: 15px;
  }

  &.tocLevel5 {
    padding-left: 20px;
  }
}
.navigideHeaderText {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 5px;
  margin-left: 5px;
  .slideRange {
    @extend .TEXTSUBTITLEblackmedium-emphasisright;
  }
  .hideSlideNumbers {
    display: none;
  }
  &.tocLevel0,
  &.tocLevel1 {
    @extend .TEXTH4blackmedium-emphasisleft;
    .slideRange {
    @extend .TEXTSUBTITLEblackhigh-emphasisright;
    }
  }
}
.navigideHeaderTitle {
  width: 250px;
  &.hideSlideNumbers {
    display: block;
    width: 100%;
    margin-right: 10px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
