@use '../_typography';

.sidebarLeft {
    background-color: var(--COLOR-SECONDARY-200);
    max-width: 340px;
    height: 100%;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
}

.sidebarLeftHeader {
    flex: 0 0 55px;
    display: flex;
    align-items: center;
    padding: 8px 12px 9px;
    margin-bottom: 6px;
    background-color: white;
    height: 55px;
    &.readOnly {
      margin-bottom: 0px;
    }
    .hamburger {
        cursor: pointer;
    }
    .logo {
        margin-left: 18px;
        cursor: pointer;
    }
    @media (min-width: 1075px) {
        &.hideHeaderOnLargerScreen {
            display: none;
        }
    }
}
.loadingSkeleton {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-top: 14px;
}

.sidebarLeftAvatarMenu {
    flex: 0 0 0;
    background-color: white;
    max-height: calc(100% - 61px);
    @media (min-width: 1075px) {
        max-height: 65%;
        &.hideHeaderOnLargerScreen {
            margin-top: 6px;
        }
    }
}

.sidebarLeftTabs {
    flex: 0 0 0;
    background-color: white;
    margin-top: 5px;
}

.sidebarLeftNotifications {
    @extend .TEXTSUBTITLEblackmedium-emphasiscenter;
    flex: 1 1 0;
    margin-top: 4px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
}

.sidebarLeftHistory {
    flex: 1 1 0;
    margin-top: 4px;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .item {
        border-bottom: 1px solid var(--COLOR-SECONDARY-200);
        background-color: white;
        padding: 3px;
    }
}
