.viewBarToggleIcon {
  width: 32px;
  height: 32px;
}

.SWIPE .viewBarToggleIcon,
.swipeOveralyActionIcon {
    filter: brightness(0) invert(1) opacity(.65);
    &:hover {
        filter: brightness(0) invert(1) opacity(1);
    }
}

.viewBarExpander,
.viewBarExpanderCorner {
    align-content: center;
    align-items: center;
    display: flex;
    min-height: 0px;
    flex-wrap: no-wrap;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 25px;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    -webkit-transition: box-shadow 500ms, width 500ms, background-color 500ms;
    /* For Safari 3.1 to 6.0 */
    transition: box-shadow 500ms, width 500ms, background-color 500ms;
    .viewBarExpandedContent {
      @extend %flexRowNormal;
      width: 100%;
      justify-content: space-between;
    }
}

.viewBarToggleInnerIcon,
.viewBarToggleInnerMenuIcon {
    opacity: 1;
    -webkit-transition: opacity 500ms, visibility 500ms;
    /* For Safari 3.1 to 6.0 */
    transition: opacity 500ms, visibility 500ms;
}

.viewBarToggleInnerIcon {
    width: 24px;
    height: 24px;
    margin-left: 18px;
}

.viewBarToggleInnerMenuIcon {
    width: 32px;
    height: 32px;
    margin-left: 0px;
}

.SWIPE .viewBarToggleInnerIcon,
.SWIPE .viewBarToggleInnerMenuIcon {
    filter: brightness(0) invert(1) opacity(.65);
    &:hover {
        filter: brightness(0) invert(1) opacity(1);
    }
}

.viewBarExpander {
    margin-left: 30px;
}

.viewBarExpanderCorner {
    margin-left: 13px;
}

.viewBarRightContent {
    justify-content: flex-end;
}

.viewBar {
  background-color: white;
  height: 52px;
  border-top-style: solid;
  border-top-color: $headerBorder;
  border-top-width: 1px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, .08), 0px 2px 1px -1px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(170, 190, 190, 0.1);
}

.SWIPE {
  .viewBar {
      background-color: rgba(0, 0, 0, .5); // position:fixed;
      bottom: 0px;
      width: 100%;
  }
}

.viewBarOverlay {
  position: fixed;
  bottom: 0px;
  height: 52px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient( rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.7));
}

.captionPanelWithViewBar {
  bottom: 52px;
}

.captionPanelWithoutViewBar {
  bottom: 0px;
}

.viewBarToggleIcon {
  width: 24px;
  height: 24px;
}
.viewBarExpander,
.viewBarExpanderCorner {
  width: 24px;
}
.viewBarExpander {
  margin-left: 24px;
}
.viewBarExpanderCorner {
  margin-left: 7px;
}
// // This is to handle the issue that the search input bar won't
// // shrink when the container shrinks beyound a certain size
@media (max-width: 350px) {
  .viewBarExpander {
      margin-left: 16px;
  }
  .viewBarExpanderCorner {
      margin-left: 3px;
  }
}

@media (max-width: 325px) {
  .viewBarExpander {
      margin-left: 12px;
  }
  .viewBarExpanderCorner {
      margin-left: 2px;
  }
}