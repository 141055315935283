
.articlePreviewTileContainer {
  display: flex;
  flex-direction: column;
  width: 182px;
  background: var(--WHITES-NORMAL-1000);
  position: relative;
  &.linkable:hover {
    cursor: pointer;
  }
  .image {
    img {
      border-radius: 5px 5px 0px 0px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 16px;
    .userInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .imageIndicators {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .description {
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    height: 60px;
    overflow: hidden;
    max-height: 60px;
    max-width: 176px;
    margin: 3px;
  }
  .footerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 5px;
    top: 5px;
  }
}
.articlePreviewBlogImageTopContainer {
  width: 375px;
  max-width: 375px;
  &.linkable:hover {
    cursor: pointer;
  }
  .image {
    img {
      width: 375px;
      max-width: 375px;
      height: 257px;
      max-height: 257px;
      border-radius: 3px;
      object-fit: cover;
    }
  }
  .details {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      @extend .TEXTH1blackhigh-emphasisleft;
      max-height: 79px;
      overflow: hidden;
      max-width: 359px;
    }
    .description {
      max-height: 72px;
      overflow: hidden;
      @extend .TEXTBODYblackregularmedium-emphasisleft;
      max-width: 375px;
      margin-bottom: 3px;
      margin-top: 8px;
    }
    .footerInfo {
      display: flex;
      align-items: center;
    }
  }  
}
@media (max-width: 500px) {
  .gideBlogImageLeftPreviewContainer {
    height: inherit;
    .image {
      img {
        width: 158px;
        max-width: 158px;
        height: 120px;
        max-height: 120px;
      }
    }    
    .details {
      .title {
        max-height: 40px;
      }
      .description {
        max-height: 46px;
      }
    }
  }
}
@media (max-width: 400px) {
  .gideBlogImageLeftPreviewContainer {
    height: inherit;
    .image {
      img {
        width: 128px;
        max-width: 128px;
        height: 100px;
        max-height: 100px;
      }
    }
    .details {
      .description {
        max-height: 46px;
      }
    }
  }
}
