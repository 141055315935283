.slideSettings {
  .e-radio:checked + label::after {
    background: rgb(255, 178, 19) !important;
    color: yellow !important;
  }
  .e-radio:checked + label::before {
    border-color: rgb(255, 178, 19) !important;
  }
  .expireSlideSettings {
    display: flex;
    flex-direction: column;
    border: solid 1px #80808030;
    border-top: 0px;
    padding: 5px;
    margin-top: 10px;
    .expireSlideOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      .expireSlideByDuration {
        margin-left: 20px;
      }
    }
    .expireSlideOptionSettings {
      display: flex;
      padding: 20px;
      flex-direction: column;
      .expireSlideTimeSettings {
        display: flex;
        flex-direction: column;
        .expireSlideTime {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          .expireSlideTimeSettingsDate,
          .expireSlideTimeSettingsTime {
            width: 250px;
            padding: 10px;
          }
        }
      }
      .expirationActionOptions {
        display: flex;
        justify-content: space-evenly;
        width: 300px;
        margin-top: 10px;
      }
    }
  }
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: gold !important;
}
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: gold !important;
}
