.audioViewContainer {
  display: flex;
  flex-direction: column;
  // flex: 1;
  &.hidden {
    display: none !important;
  }
  &.hasEnsuingItem {
    margin-bottom: 28px;
  }
  .embedCaption {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 7px;
    span {
      margin-left: 5px;
    }
    &.hidden {
      display: none;
    }
  }
}