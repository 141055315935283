@use '../../../typography';

.pickerContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--WHITES-NORMAL-1000);

  .title {
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    margin-left: 10px;
  }
  .pickerItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pickerItemAligner {    
    height: 0px;
    width: 117px;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;;
  }
}