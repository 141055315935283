.slideChooserContainer {
  @extend %flexColumnBase;
  width: 100%;
  flex: 1;
  // background-color: var(--GREYS-NORMAL-600);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding-top: 5px;
  .slideChooserSlides {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    .slideChooserSlide {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3px;
      .slideChooserSlideOverlay {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        max-width: $MAXSLIDEWIDTH;
        background: #ffffff;
      }
      .slideView {
        flex: 1; // To fix issue when slides are set to EDGE
        width: 100%;
        // margin-bottom: 3px;
        .slideFooter, .captionAreaAttachments {
          display: none;
        }
        .card {
          margin-bottom: 0px;
        }
      }
      .slideSelectionFooter {        
        @extend .TEXTSUBTITLEblackmedium-emphasisright;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        max-width: $MAXSLIDEWIDTH;
        background-color: var(--WHITES-NORMAL-1000);
        .slideSelectorSlideNumber {
          @extend .TEXTSUBTITLE-2orangecenter;
          width: 32px;
          margin-left: 10px;
        }
        .selector {
          display: flex;
          align-items: center;
        }
        &.locationLeft {
          justify-content: space-between;
        }
      }
    }    
  }
}

@media only screen and (min-width: #{$MAXSLIDEWIDTH}) {
  .slideChooserContainer {
    .slideChooserSlides {
      .slideChooserSlide {
        .slideChooserSlideOverlay {
          margin-left: calc((100% - #{$MAXSLIDEWIDTH}) / 2);
          margin-right: calc((100% - #{$MAXSLIDEWIDTH}) / 2);
        }
      }
    }
  }
}
