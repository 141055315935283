.searchBarWithResultsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: calc(100% - 36px);
  border: solid var(--WHITE-BACKGROUND-01) 8px;
  :global(.searchBar) {
    background: white !important;
  }
  :global(.searchBar input) {
    background: white !important;
  }
  .searchContainer {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .searchResuls {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 3px;
    border-top: solid 1px var(--COLOR-SECONDARY-200);
  }
  @media (max-width: 520px) {
    max-height: 100%;
  }
}
