@use '../../typography';
.avatarMenuSectionContainer {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  padding-left: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  .activeIndicator {
      position: absolute;
      left: -7px;
      top: 8px;
      width: 3px;
      height: 20px;
      background-image: linear-gradient(353deg, #faa114, #fab317);
  }
  .headerMenuImage {
      opacity: .5;
      &.account {
          opacity: 1;
          border: 1px solid white;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
          margin-left: -3px
      }
  }
  .titleContainer {      
      display: flex;
      align-items: center;
      flex: 1;      
      .title {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;      
        margin-left: 17px;
      }
  }
}