@use '../../../../typography';
.keywordSelectorContainer {
  max-height: 544px;
  flex-direction: column;
  :global(.searchBar) {
    background: white !important;
  }
  :global(.searchBar input) {
    background: white !important;
    // width: 100%;
  }
  @media (max-width: 520px) {
    max-height: 370px;
  }
  .keywordTitle {
    @extend .TEXTH5blackhigh-emphasisleft;
    margin-bottom: 5px;
    margin-top: 11px;
  }
  .keywordSubtitle {
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
  }
  .keywordsContainer {
    padding: 24px 12px 0px 12px;
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;    
    max-height: calc(100vh - 285px);
    height: calc(544px - 163px);
    @media (max-width: 520px) {
      height: calc(377px - 163px);
    }
    .keywords {
      display: flex;
      flex-wrap: wrap;
      // max-height: fit-content;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}