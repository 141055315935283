.hyperlinkContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  width: 100%;
  // background-color: var(--COLOR-BLUE-100);
  padding-bottom: 3px;
  max-width: 100%;
  .hyperlink {
    @extend .TEXTSUBTITLEblueleft;
    // @extend .TEXTH4whitehigh-emphasisleft;
    margin-top: 3px;
    margin-left: 9px;
    word-break: break-all;
    max-width: calc(100% - 40px);
    word-wrap: break-word;
  }
}

// TODO: MOve this 
.linkSlideContainer {
  @extend %flexColumnFull;
  .linkSlideItems {
    align-items: center;
    @extend %flexColumnFull;
  }
}
