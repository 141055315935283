.gideSearchPreview {
  background-color: white;
  display: flex;
  cursor: pointer;
  margin-bottom: 11px;
  // box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.1);
  min-height: 49px;
  max-height: 49px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--COLOR-SECONDARY-200);
  border-top: solid 1px var(--COLOR-SECONDARY-200);
  &.selected {
    border-bottom: solid 1px var(--COLOR-PRIMARY-600);
    border-top: solid 1px var(--COLOR-PRIMARY-600);
  }
  @media (max-width: 520px) {
    min-height: 49px;
    max-height: 49px;
  }
  .gideSearchPreviewImageContainer {
    position: relative;
    height: 40px;
    .gideSearchPreviewImage {
      width: 57px;
      height: 40px;
      object-fit: cover;
      border-radius: 3px;
    }
    .selectionImage {
        position: absolute;
        left: 16px;
        top: 9px;        
    }
    .imageWhiteBackground {
        position: absolute;
        left: 19px;
        top: 12px;  
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: white;
    }
    .gideAuthorImage {
      bottom: 1px;
      right: -12px;
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: solid 1px white;
    }
    .selectedGideImage {
      position: absolute;
    }
  }
  .gideSearchPreviewDetail {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 22px;
    justify-content: space-evenly;
    margin-top: 8px;
    // margin-bottom: -10px;
    .gideName {
      @extend .TEXTH4blackhigh-emphasisleft;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-height: 24px;
      overflow: hidden;
      flex-wrap: wrap;
    }
    .channelAuthorImage {
      width: 24px;
      height: 24px;
      border: solid 1px white;
      border-radius: 50%;
      margin-right: 4px;
    }
    .channelAuthorInfo {
      display: flex;
      align-items: center;
      font-family: Aileron;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: 0.3px;
      color: rgba(156, 160, 165, 0.9);
    }
    // .articleType {
    //     display: flex;
    //     justify-content: flex-start;
    //     font-family: Aileron;
    //     font-size: 14px;
    //     font-weight: bold;
    //     font-style: normal;
    //     font-stretch: normal;
    //     line-height: 1.43;
    //     letter-spacing: 0.3px;
    //     color: rgba(0, 109, 173, 0.9);
    // }
    .gideAuthorName {
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    }
    .gideSearchlPreviewFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .likesAndViews {
        @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .followerInfo {
          margin-left: 4px;
        }
        .totalGides {
        }
      }
    }
  }
}
