@use '../../../typography';

.gideTitleDropdownPanelContainer {
  display: none;
  align-items: center;
  visibility: collapse;
  width: 100%;
  height: 48px;
  max-width: 520px;
  @media (max-width: 520px) {
    visibility: visible;
    display: flex;
  }
  &.collapsed {
    display: none;
  }
  .dropdownTitle {
    margin-left: 30px;
  }
}