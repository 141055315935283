@use '../../../../typography';

.gideBlogImageLeftPreviewContainer {
  position: relative;
    display: flex;
    max-width: 752px;
    width: 100%;
    height: 180px;
    border-radius: 5px;
    background-color: var(--WHITES-NORMAL-1000);
    &.linkable:hover {
      cursor: pointer;
    }
    .image {      
      img {
        width: 258px;
        max-width: 258px;
        height: 180px;
        max-height: 180px;
        border-radius: 3px;
        object-fit: cover;
      }
    }
    .gidesLogoContainer {
      position: absolute;
      top: 2px;
      left: 2px;   
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      background-color: var(--GREYS-NORMAL-300);
    }

    .userLanguageFlag {
      position: absolute;
      top: 4px;
      left: 240px;
      border-radius: 50%; 
      width: 14px;
      height: 14px;
      
    }
    .details {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        @extend .TEXTH1blackhigh-emphasisleft;
        max-height: 80px;
        overflow: hidden;
      }
      .description {
        max-height: 72px;
        overflow: hidden;
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      }
      .footerInfo {
        display: flex;
        align-items: center;
      }
    }
  }
