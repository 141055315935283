.inquiryResonseHeaderContainer {
  @extend %flexRowFull;
  justify-content: space-between;
  height: 48px;
  border-radius: 5px;
  background-color: var(--WHITES-NORMAL-1000);
  &.inlineView {
    width: 100%;
  }
  .hoverIcon {
    margin-left: 3px;
    margin-right: 3px;
  }
  .inquiryResponseHeader {
    display: flex;
    align-items: center;
    .authorHeaderImage {
      width: 32px;
      // height: 32px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 4px;
      margin-left: 6px;
      box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-100);
      border: solid 1px var(--WHITES-NORMAL-1000);
    }
    .authorHeaderTitle {
      @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      margin-right: 4px;
    }
    .inquiryResponseHeaderText {
      @extend .TEXTSUBTITLE-2blacklow-emphasisleft;
    }
  }
  .inquiryResponseActions {
    display: flex;
    .action {
      cursor: pointer;
      &.cancel {
        margin-right: 28px;
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      }
      &.post {
        margin-right: 20px;
        @extend .TEXTSUBTITLEorangecenter;
      }
    }
  }
}