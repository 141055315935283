.avatarContainer {
  display: flex;
  align-items: center;
  border-radius: 50%;
  .avatarImage {
    box-shadow: 0 3px 5px 0 var(--GREYS-NORMAL-100);
    border: solid 2px var(--WHITES-NORMAL-1000);
    border-radius: 50%;
  }
}
