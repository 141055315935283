.sliderContainer {
  position: relative;
  width: 100%;
  margin-top: 12px;
  // border-bottom: solid 1px var(--COLOR-SECONDARY-200);
  .slider {
    background: transparent;
    height: 20px;
    cursor: pointer;
  }
  .sliderTicks {
    width: 100%;
    height: 3px;
    position: absolute;
    margin-top: 11px;
    background: repeating-linear-gradient(to right, transparent, transparent 17px, #dedede 10px, #dedede 20px);
  }
  .sliderTickLabel {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    @extend .TEXTSUBTITLE-2blacklow-emphasisright;
  }
}
