@use '../../typography';
.accountProfileModalContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  .loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.channelEllipsis {
  @extend .TEXTSUBTITLEblackmedium-emphasisright;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 98px);
}
