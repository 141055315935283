@use '../../typography';

.profileEditorContainer {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: var(--WHITE-BACKGROUND-02);
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  .leftContent {
    padding-left: 17px;
    padding-right: 8px;
  }
  .rightContent {
    padding-left: 8px;
    padding-right: 17px;
  }
  .leftContent,
  .rightContent {
    display: flex;
    flex-direction: column;
    max-width: 368px;
    width: calc(50% - 25px);
    @media (max-width: 784px) {
      max-width: 375px;
      width: 100%;
    }
    @media (max-width: 750px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  
  .card {
    width: 100%;
    margin-top: 9px;
    background-color: var(--WHITES-NORMAL-1000);
  }
  .actionHeader {
    display: flex;
    width: 100%;
    padding: 0px 17px 0px 16px;
    justify-content: space-between;
    justify-content: space-between;
    height: 38px;
    align-items: center;
    @extend .TEXTSUBTITLEblackhigh-emphasiscenter;
    .button {
      @extend .TEXTSUBTITLEblueright;
      cursor: pointer;
    }
  }
  .profilePicture {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 147px;
    .avatar {
      width: 102px;
      height: 102px;
      border-radius: 13px;
      border: solid 2px var(--WHITES-NORMAL-1000);
    }
  }
  .profileBranding {
    height: 185px;
    .brandingImage {
      object-fit: cover;
      width: 100%;
      height: 149px;
    }
  }
  .profileName {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 172px;
    .userName {
      display: flex;
      padding: 0px 15px;
    }
    .properName {
      display: flex;
      padding: 0px 15px;
    }
  }
  .businessAccount {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 15px;
    height: 42px;
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    .businessCheck {
      display: flex;
      align-items: center;
    }
    &.isBusiness {
      height: 116px;
    }
  }
  .profileDescription {
    height: 119px;
    padding: 0px 15px;
    input {
      height: 75px;
    }
  }
  .profileContact {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    width: 100%;
    height: 205px;
    padding: 0px 15px;
  }
  .profilePassword {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    width: 100%;
    height: 172px;
    padding: 0px 15px;
  }
  .profileButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 375px;
    width: 100%;
  }
}
.requiredTextboxWithLabel {
  display: flex;
  flex: 1;
  flex-direction: column;
  .label {
    display: flex;
    align-items: center;
    height: 25px;
    .isError {
      color: 'red';
      @extend .TEXTSUBTITLE-2redhigh-emphasisleft;
    }
    .labelText {
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
      padding-right: 2px;
    }
    .required {
      color: var(--COLOR-PRIMARY-700);
    }
  }
  .requiredTextArea {
    @extend .TEXTSUBTITLEblackhigh-emphasisleft;
    height: 75px;
    border-radius: 1px;
    border: none;
    border-bottom: solid 1px lightgrey;
    padding: 7px;
    background-color: var(--WHITE-BACKGROUND-01);
    .textAreaError {
      background-color: #fff6f6 !important;
      border-color: #e0b4b4 !important;
      color: #9f3a38 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
  .requiredTextboxInput {
    input {
      @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      background-color: #fafafa;
      border-radius: 1px;
      border: none;
      border-bottom: solid 1px lightgrey;
    }
    &:global(.error) {
      input {
        background-color: #fff6f6 !important;
        border-color: #e0b4b4 !important;
        color: #9f3a38 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }
  .requiredTextboxInput {
    &.name {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}
.inputError {
  margin-top: 5px;
  color: 'red';
  @extend .TEXTSUBTITLE-2redhigh-emphasisleft;
}
