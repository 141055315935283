#imageViewer {
  height: 100%;
  margin: 0 auto 0 auto;
  max-width: 100%;
  width: 100%; 
}
#imageViewer .zoomableImage {
  background-color: transparent; 
  height: 100%; 
  margin: auto; 
  overflow: hidden; width: 100%; 
}
#imageViewer .zoomableImage .media {
  bottom: 0; 
  display: block;
  left: 0; 
  margin: auto;
  max-height: 100%;
  max-width: 100%; 
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; 
}
.zoomableImage-transition{
  transition: -moz-transform ease 200ms;
  transition: -ms-transform ease 200ms;
  transition: -o-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms;
}