.slideList {
  width: 100%;
  &.inlineSlides {
    .inlineSlide {
      &.SETTINGS {
        border-right: 3px solid #ffb213;
        background-color: #fef8e2;
      }
      &.PRIVATENOTES {
        border-left: 3px solid #06b3dc;
        background-color: #eff8fa;
      }
      .card {
        width: 100%;
      }
      width: 100%;
      max-width: $MAXSLIDEWIDTH;
      // .slideView:first-child {
      //   margin-top: 3px;
      // }
      .slideView {        
        margin-top: 3px;
      }
      
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-bottom: -3px;
    .collapse {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      display: flex;
      width: 100%;
      max-width: $MAXSLIDEWIDTH;
      background-color: white;
      cursor: pointer;
      height: 27px;
      align-items: center;
      color: rgba(0,0,0,.55);
      // margin-bottom: 3px;
      &.SETTINGS {
        // border-right: 4px solid #ffb213;
        justify-content: flex-end;
      }
      &.PRIVATENOTES {
        // border-left: 4px solid #06b3dc;
        padding-left: 3px;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
  }
}
