.slideFilterContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  .slideFilter {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    .filterDescription {
      @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      margin: 5px;
    }
    .slideFilterSlideTypes {
      display: flex;
      flex-flow: column;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      min-width: 250px;
    }
    .contextFilters {
      display: flex;
      flex-flow: column;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      min-width: 250px;
      .contextOptionList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        width: 280px;
        margin-bottom: 20px;
      }
    }
  }
  .slideFilterActions {
    display: flex;
    justify-content: flex-end;
  }
  .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: #fab317 !important;
  }
}
