.videoSlideEditor {
  @extend %flexColumnBase;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  // The flex 1 is not spanning the height as it should.
  // height: 100%;
  justify-content: space-between;
  position: relative;
  display: block;

  &.showModalBackground {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.8);
  }

  .videoSlideEditorHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding-right: 10px;

    .cornerAction {
      margin-left: 5px;
    }

    .videoSlideEditorEditActions,
    .previewActions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .videoSlideViewerContainer {
    @extend %flexColumnBase;
    flex: 1;
    // overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 93px;
  }

  .videoDropZone {
    width: 100%;
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    &.hidden {
      display: none;
    }
    .uploadIndicator {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      background-color: var(--COLOR-BLUE-100);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 103px;
      height: 30px;
      border-radius: 5px;
    }

    .uploadLabel {      
      @extend .TEXTH4whitemedium-emphasiscenter;
      margin-top: 5px;
    }
  }

  .webcamDisplay {
    // width: 500px;
    // height: 500px;
    // object-fit: cover;
    // width: 100%;
    // max-height: calc(100vh - 290px);
    object-fit: contain;

    &.inactive {
      display: none;
    }
  }

  .videoEditor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    height: 100%;
    // overflow-y: auto;    
    .videoViewOuterContainer {
      max-width: 752px;
      overflow-y: auto; // This is so that the video when resized will scroll instead of overtaking the url entry section for a video.
      margin-bottom: 93px;
    }
    .videoEditorSelectedVideoOuterContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height:100%;
      .videoEditorSelectedVideoContainer {
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.EDGE, &.HALF {
          width: 100%;
        }

        .videoEditorSelectedVideo {
          // object-fit: scale-down;
          // max-height: 500px;
          // max-width: 93%;
          width: 100%;
          max-width: 750px;

          &.EDGE, &.HALF {
            max-width: 100%;
          }
        }

        .videoCaptionContainer {
          // height: 25px;
          border-radius: 2px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
          background-color: #ffffff;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          overflow-wrap: break-word;
          word-break: break-all;
        }
      }
    }

    &.inactive {
      display: none;
    }

    .videoEditorCropper {
      width: 800px;
      min-height: 800px;
    }

    .videoLinkContainer {
      @extend %flexColumnBase;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 752px;
      // flex: 1;
      margin-top: 24px;
      margin-bottom: 24px;
      padding: 0 5px 0 5px;
      .videoLinkTextBox {
        margin-top: 15px;
        border: none;
        outline: none;
        -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
        resize: none;
        border-radius: 21px;
        font-family: Aileron;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.81);
        width: 100%;
        max-width: 752px;
        min-height: 140px;
        padding: 10px;
      }

      .linkVideo {
        width: 93%;
        max-height: 75%;
        margin: 15px 5px 5px 5px;
        object-fit: scale-down;
      }
    }
  }

  .videoSlideEditorActionContainer {
    height: 86px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: top;
    border-radius: 3px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    box-shadow: 0 -2px 10px 7px rgba(0, 0, 0, 0.02);
    background-color: rgba(255, 255, 255, 0.9);
    position: relative;

    .addVideoToList {
      position: absolute;
      right: 5px;
      top: 15px;
    }

    .videoList,
    .createActions {
      margin-top: 10px;
      display: flex;

      .slideVideoThumbnail {
        object-fit: cover;
        width: 38px;
        height: 38px;
        border-radius: 3px;
        margin: 2px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .videoList {
      justify-content: flex-start;
     
    }

    .createActions {
      justify-content: flex-end;
    }
  }
}


@media only screen and (max-width: 850px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 800px;
        min-height: 800px;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 780px;
        min-height: 780px;
      }
    }
  }
}
@media only screen and (max-width: 790px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 750px;
        min-height: 750px;
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 730px;
        min-height: 730px;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 710px;
        min-height: 710px;
      }
    }
  }
}
@media only screen and (max-width: 730px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 690px;
        min-height: 690px;
      }
    }
  }
}
@media only screen and (max-width: 710px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 670px;
        min-height: 670px;
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 650px;
        min-height: 650px;
      }
    }
  }
}
@media only screen and (max-width: 670px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 630px;
        min-height: 630px;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 610px;
        min-height: 610px;
      }
    }
  }
}
@media only screen and (max-width: 630px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 590px;
        min-height: 590px;
      }
    }
  }
}
@media only screen and (max-width: 610px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 570px;
        min-height: 570px;
      }
    }
  }
}
@media only screen and (max-width: 590px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 550px;
        min-height: 550px;
      }
    }
  }
}
@media only screen and (max-width: 570px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 530px;
        min-height: 530px;
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 510px;
        min-height: 510px;
      }
    }
  }
}
@media only screen and (max-width: 530px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 500px;
        min-height: 500px;
      }
    }
  }
}
@media only screen and (max-width: 520px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper {
      // .webcamDisplay {
        width: 450px;
        min-height: 450px;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper,
      .webcamDisplay {
        width: 400px;
        min-height: 400px;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper,
      .webcamDisplay {
        width: 370px;
        min-height: 370px;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorCropper,
      .webcamDisplay {
        width: 350px;
        min-height: 350px;
      }
    }
  }
}

@media only screen and (min-width: 750px) {
  .videoSlideEditor {
    .videoEditor {
      .videoEditorSelectedVideoContainer {
        width: 750px;

        &.EDGE, &.HALF {
          width: 100%;
        }
      }
    }
  }
}
