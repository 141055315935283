.blogImageTopUrlLinkPreview {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0 1px 11px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    background: rgba(65, 105, 225, 0.068);
    cursor: pointer;
  }
  .linkPreviewImage {
    position: relative;
    .primaryImage {
      object-fit: cover;
      height: 616px;
      max-height: 616px;
      border-radius: 5px 5px 0 0;
      max-width: 100%;
      min-width: 100%;
    }
    .circleBackground {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--GREYS-NORMAL-300);
    }
  }
  .linkPreviewDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 10px 2px 10px;
    .linkPreviewTitleContainer {
      display: flex;
      flex-direction: column;
      .linkPreviewTitle {
        @extend .TEXTH1blackhigh-emphasisleft;
        max-height: 72px;
        margin-top: 12px;
      }
      .linkPreviewDescription {
        margin-top: 12px;
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        height: 102px !important;
        max-height: 102px !important;
        overflow: hidden !important;
      }
    }
    .linkPreviewUrl {
      @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      a {
        @extend .TEXTSUBTITLEblueleft;
        margin-left: 2px;
      }
    }
  }
}
.blogImageLeftUrlLinkPreview {
  display: flex;
  width: 100%;
  box-shadow: 0 1px 11px 2px rgba(0, 0, 0, 0.1);
  height: 202px;
  align-items: center;
  &:hover {
    background: rgba(65, 105, 225, 0.068);
    cursor: pointer;
  }
  .linkPreviewImage {
    min-width: 258px;
    max-width: 258px;
    height: 180px;
    position: relative;
    .primaryImage {
      object-fit: cover;
      height: 180px;
      max-height: 180px;
      border-radius: 3px;
      max-width: 100%;
      min-width: 100%;
    }
    .circleBackground {
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--GREYS-NORMAL-300);
    }
  }
  .linkPreviewDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 10px 2px 10px;
    height: calc(100% - 22px);
    .linkPreviewTitleContainer {
      display: flex;
      flex-direction: column;
      .linkPreviewTitle {
        @extend .TEXTH1blackhigh-emphasisleft;
        max-height: 72px;
        overflow: hidden;
      }
      .linkPreviewDescription {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        margin-top: 12px;
        max-height: 72px !important;
        overflow: hidden !important;
      }
    }
    .linkPreviewUrl {
      a {
        @extend .TEXTSUBTITLEblueleft;
      }
    }
  }
}

@media (max-width: 500px) {
  .blogImageTopUrlLinkPreview {
    .linkPreviewImage {
      .primaryImage {
        height: 225px;
        max-height: 225px;
      }
    }
    .linkPreviewDescription {
      max-height: 48px !important;
    }
  }
  .blogImageLeftUrlLinkPreview {
    height: 100px;
    .linkPreviewImage {
      height: 94px;
      max-width: 134px;
      min-width: 134px;
      .primaryImage {
        height: 94px;
        max-width: 134px;
        max-height: 94px;
      }
      .circleBackground {       
        top: 0px;
        left: 0px;
      }
    }
    .linkPreviewDetails {
      height: calc(100% - 6px);
      .linkPreviewTitleContainer {
        .linkPreviewTitle {
          font-family: Aileron;
          font-size: 16px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: 0.3px;
          color: rgba(0, 0, 0, 0.55);
        }
      }
      .linkPreviewDescription {
        // max-height: 48px !important;
        display: none;
      }
    }
  }
}
