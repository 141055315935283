@use '../../typography';
@use '../../flex';
@use '../../gides-colors';

%pointerTriangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  position: absolute;
}

$modalNormalScreenBreakpoint: 826px;

.slideEditorModal {
  display: flex !important;
  flex: 1;
  position: relative;
  bottom: -14px;
  z-index: 2; // Needed for Cordova
  .ui.modal > .content {
    display: flex !important;
  }
  .Footer {
    textarea {
      @media (max-width: 520px) {
        max-width: calc(100vw - 150px);
      }
    }
    .footerMenu.slideZero {
      textarea {
        @media (max-width: 520px) {
          max-width: calc(100vw - 200px);
        }
      } 
    }
  }
  .slideEditorModalContent {
    @extend %flexColumnBase;
    flex: 1;
    position: relative;
    // margin: 0 7px 0 7px;
    // .slideEditorCommandBar .cornerAction svg * {
    //   fill: var(--COLOR-SECONDARY-600);;
    // }
    background: var(--WHITE-BACKGROUND-01);
    &.dark {
      background-color: var(--GREYS-NORMAL-1000);
    }
    &.content {
      display: flex !important;
      width: auto !important;
    }
    .settingsPanel {
      height: calc(100% - 58px); // Remove height of description container
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    &.EDGE,
    &.HALF {
      .slideView .card {
        max-width: 100%;
        width: 100% !important;
      }
    }
    .slideEditorCommandBar .commandBarContent .headerActionsFlexEnd {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .slideEditorModalFooterActionContainer {
      height: 93px;
      display: flex;
      justify-content: space-between;
      align-items: top;
      border-radius: 3px;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      box-shadow: 0 -2px 10px 7px rgba(0, 0, 0, 0.02);
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      &.dark {
        background-color: var(--GREYS-NORMAL-300);
      }

      .footerOverlay {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.4);
      }
      .addSlideItemToList {
        position: absolute;
        right: 8px;
        top: 24px;
      }

      .slideFileItemSelectionOptions,
      .createActions {
        display: flex;

        .slideImage {
          object-fit: cover;
          width: 38px;
          height: 38px;
          border-radius: 3px;

          &:hover {
            cursor: pointer;
          }
        }
      }
      .createActions {
        justify-content: flex-end;
        position: absolute;
        right: 8px;
        top: 24px;
      }
      .slideFileItemSelectionOptions {
        justify-content: flex-start;
        padding-left: 12px;
        div[data-react-beautiful-dnd-droppable],
        div[data-react-beautiful-dnd-draggable] {
          background: transparent !important;
        }
        .slideFileSelectionContainer,
        .previewIconContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
          .imagePreview,
          .videoPreview,
          .audioPreview,
          .linkPreview,
          .galleryPreview,
          .previewIconBackground {
            width: 38px;
            height: 38px;
            position: relative;
            border-radius: 5px;
          }

          .imagePreview {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--COLOR-SECONDARY-200);
            &.active {
              background: var(--COLOR-BLUE-100);
            }
            .imagePreviewOverlayIcon {
              position: absolute;
              top: 7px;
              left: 7px;
              width: 24px;
              height: 24px;
              opacity: 0.8;
            }
          }
          .videoPreview {
            background-color: #cccccc;
            video,
            img {
              &.slideVideoThumbnail {
                height: 38px;
                width: 38px;
                object-fit: cover;
              }
            }
            .videoPreviewOverlayIcon {
              position: absolute;
              top: 7px;
              left: 7px;
              width: 24px;
              height: 24px;
              opacity: 0.8;
            }
            &:hover {
              cursor: pointer;
            }
          }
          .audioPreview {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--COLOR-SECONDARY-200);
            &.active {
              background: var(--COLOR-BLUE-100);
            }
            .audioPreviewOverlayIcon {
              position: absolute;
              top: 7px;
              left: 7px;
              width: 24px;
              height: 24px;
              opacity: 0.8;
            }
            &:hover {
              cursor: pointer;
            }
          }
          .linkPreview {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--COLOR-SECONDARY-200);
            &.active {
              background: var(--COLOR-BLUE-100);
            }
            .linkPreviewOverlayIcon {
              position: absolute;
              top: 7px;
              left: 7px;
              width: 24px;
              height: 24px;
              opacity: 0.8;
            }
          }
          .galleryPreview {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--COLOR-SECONDARY-200);
            &.active {
              background: var(--COLOR-BLUE-100);
            }
            .galleryPreviewOverlayIcon {
              position: absolute;
              top: 7px;
              left: 7px;
              width: 24px;
              height: 24px;
              opacity: 0.8;
            }
          }
          .previewIconBackground {
            width: 38px;
            height: 38px;
          }

          .previewLabel,
          .itemPreviewLabel {
            @extend .TEXTSUBTITLE-2blacklow-emphasiscenter;
            width: 43px;
            height: 14px;
            background-color: #fafafa;
          }

          .previewLabel {
            width: 45px;
            height: 13px;
            border-radius: 6.5px;
            margin-top: 4px;
            &.dark {
              color: var(--WHITES-NORMAL-900);
              background-color: transparent;
            }
            &.active {
              @extend .TEXTSUBTITLE-2whitehigh-emphasiscenter;
              background-color: var(--COLOR-PRIMARY-600);
              color: rgba(255, 255, 255, 0.95);
            }
          }

          .itemPreviewLabel {
            padding-top: 1px;
            margin-top: 2px;
            width: 14px;
            border-radius: 50%;
            &.active {
              background-color: var(--COLOR-BLUE-100);
              color: rgba(255, 255, 255, 0.95);
            }
          }
        }
        .slideFileSelectionContainer {
          height: 100%;
        }
        .previewIconContainer {
          margin-top: 23px;
          padding-top: 2px;
          height: 60px;
        }
      }
    }
    .webcamDisplay {
      width: 100%;
      max-height: calc(100vh - 290px);
    }
    .slideEditorLinkContainer {
      // This is for LinkIputs within the editor modal
      @extend %flexColumnBase;
      margin-top: 25px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 752px;
      margin-bottom: 24px;
    }
    .slideModalEditorPreviewContainer {
      width: 100%;
      max-width: 752px;
    }
    .channelChooserContainer,
    .articleChooserContainer {
      max-height: calc(100vh - 70px);
    }
    .slideFileChooser {
      max-height: 100%;
      .articleChooserContainer {
        max-height: inherit;
      }
    }
  }

  &.ui.fullscreen.modal {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    position: fixed;
    top: -14px !important;
    bottom: -12px !important;
    width: 100vw !important;
    height: auto !important;
    left: initial !important;
  }

  &.ui.modal > .content > .description {
    display: flex !important;
    flex: initial;
    height: 48px !important;
  }

  &.ui.modal > .actions {
    padding: 0px !important;
  }

  .slideQuickSettings {
    display: flex;
    margin: 3px;
    align-items: center;
  }

  .slideEditorActionIcon,
  .allSettingsIcon {
    opacity: 0.3;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .allSettingsIcon {
    margin-left: 24px;
  }

  .slideEditorActionIcon {
    opacity: 0.3;
    width: 24px;
    height: 24px;
    margin-left: 30px;
  }
  .messageDialogContainer {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 56px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.4);

    .topLeftModalPointerTriangle {
      @extend %pointerTriangle;
      left: 17px;
    }

    .topRightModalPointerTriangle {
      @extend %pointerTriangle;
      right: 17px;
    }

    .bottomLeftModalPointerTriangle {
      @extend %pointerTriangle;
      margin-right: 50px;
    }

    .bottomRightModalPointerTriangle {
      @extend %pointerTriangle;
      margin-right: 50px;
    }

    .centerModalPointerTriangle {
      @extend %pointerTriangle;
      margin-right: 50px;
    }

    .messageDialog {
      background: white;
      border-radius: 2px;
      background-color: #fafafa;
      position: absolute;
      margin-right: 5px;

      &.topLeft {
        left: 5px;
        top: 7px;
      }

      &.topRight {
        right: 5px;
        top: 7px;
      }

      &.bottomLeft {
        bottom: 10px;
        left: 5px;
      }

      &.bottomRight {
        bottom: 10px;
        left: 5px;
      }

      &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .messageDialogTitle {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 15px;
      }

      .messageDialogActions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .messageDialogAction {
          @extend .TEXTSUBTITLEblacklow-emphasisleft;
          width: 110px;
          height: 30px;
          margin: 5px 0px 5px 0px;
          display: flex;
          justify-content: center;
          align-items: center;


          &.actionButton {
            padding-left: 8px;
            padding-right: 8px;
            justify-content: space-between;
            background: rgba(255, 255, 255, 0.95);
          }
          &.cancelButton {
            @extend .TEXTSUBTITLEorangeleft;
            color: #fab317 !important;
          }
          &.primary {
            padding-left: 8px;
            padding-right: 8px;
            justify-content: space-between;
            background-image: linear-gradient(284deg, #ffb213, #fab317);
            color: white;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .slideWidthSettingsContainer {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 55px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .slideWidthPointerTriangle {
      @extend %pointerTriangle;
      right: 50px;
    }

    .slideWidthSettingsContent {
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      background: white;
      width: 360px;
      height: 117px;
      position: absolute;
      right: 31px;
      top: 7px;
      padding: 10px 20px 10px 20px;

      &.displayHeight {
        height: 185px;
      }
      .slideWidthSettings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .slideWidth,
        .contentWidth,
        .contentHeight {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 50%;
          font-family: Aileron;
          font-size: 10px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.3;
          letter-spacing: 0.2px;
          color: rgba(0, 0, 0, 0.55);

          .slideWidthSettingDropdown {
            margin-top: 8px;
            width: 155px;
            height: 40px;
            border: none;
            font-family: Aileron;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: 0.3px;
            color: rgba(0, 0, 0, 0.75);
            background: #fafafa;
            input {
              width: 120px;
              border: none;
              background: #fafafa;
            }
            .label {
              border: none;
              background: #fafafa;
            }
          }
        }

        .slideAttribution {
          padding: 12px 5px 5px 5px;
          display: flex;
          flex-direction: column;
          width: 100%;
          font-family: Aileron;
          font-size: 10px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.3;
          letter-spacing: 0.2px;
          color: rgba(0, 0, 0, 0.55);

          .slideAttributionAsterick {
            font-family: Aileron;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: 0.3px;
            text-align: center;
            color: #fab317;
          }
        }
      }

      .slideSettingsActions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px 0px 5px 0px;
        .messageDialogAction {
          height: 32px;

          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Aileron;
          border-radius: 3px;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: 0.3px;
          text-align: center;
          color: #fab317;

          &.actionButton {
            padding-left: 8px;
            padding-right: 8px;
            justify-content: space-between;
            background: rgba(255, 255, 255, 0.95);
            color: #fab317;
            width: 88px;
          }

          &.primary {
            padding-left: 8px;
            padding-right: 8px;
            justify-content: space-between;
            background-image: linear-gradient(284deg, #ffb213, #fab317);
            color: white;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .overlayAll {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.4);
  }

  .loadingOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -114px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.7);

    .loadingMessage {
      color: white;
    }

    .progressIndicator {
      width: 350px;
    }
  }
  .Footer {
    position: relative;
    z-index: inherit;
    .footerOverlay {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.4);
    }
    &.dark {
      img {
        &.leftInnerInputBarIcon,
        &.innerInputBarIcon,
        &.inputBarMenuButton {
          @extend %whites-normal-800-svg;
          &.color-blue-100-svg {
            @extend %color-blue-100-svg;
          }
        }
      }
      textarea {
        color: white;
        &::placeholder {
          color: #cccccc;
        }
      }
    }
  }
  .slideEditorModalBottomSpacer {
    min-height: 70px;
    background: transparent;
  }

  &.NORMAL {
    @media screen and (min-width: $modalNormalScreenBreakpoint) {
      max-width: $modalNormalScreenBreakpoint !important;
    }
    .Footer {
      @media screen and (min-width: $modalNormalScreenBreakpoint) {
      }
    }
  }
}
.ui.inverted.dimmer {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.slideEditorModal .EDGE.slideEditorModalContent
  .slideModalEditorPreviewContainer {
  width: 100%;
  max-width: 100%;
  .audioViewContainer,
  .LinkInput {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slideEditorModal.ui.modal > .content {
    padding: 0px !important;
    display: flex !important;
  }

  .slideEditorModal.ui.modal > .content > .description {
    padding: 7px !important;
  }
}

@media only screen and (max-width: 520px) {
  .slideEditorModal {
    &.ui.fullscreen.modal {
      top: -14px;
    }
  }
}

@media only screen and (max-width: 410px) {
  .slideWidthSettingsContainer {
    .slideWidthSettingsContent {
      right: 10px;
    }
  }
}
