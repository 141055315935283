@use '../../typography';

.newSlideTypeChooser {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 19px;
  .slideTypeItem {
    margin: 12px;
    cursor: pointer;
  }
}
.slideTypeChooserModal {
  top: 50%;
}