@use '../typography';
.channelViewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .channelViewHeader {
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 16px 16px;
    @media (max-width: 765px) {
      padding: 0 0 16px 0;
    }
    .channelImage {
      object-fit: cover;
      max-width: 368px;
      width: 100%;
      height: 247px;
      border-radius: 6px;
      @media (max-width: 765px) {
        padding: 0px 16px 24px 16px;
        max-width: 100%;
        height: 231px;
      }
    }
    .editChannelButton {
      @media (max-width: 765px) {
        position: absolute;
        right: 16px;
        top: 175px;
      }
    }
    .channelDetails {
      display: flex;
      flex-direction: column;
      height: 100%;
      .channelDescriptionContainer {
        max-width: 375px;
        padding-left: 17px;
        padding-bottom: 9px;
        .channelType {
          padding-right: 3px;
          @extend .TEXTSUBTITLEblueleft;
        }
        .channelUsage {
          @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        }
        .title {
          @extend .TEXTH4blackhigh-emphasisleft;
        }
        .description {
          @extend .TEXTSUBTITLEblackmedium-emphasisleft;
          padding-top: 9px;
        }
        .details {
          @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        }
        .type {
          @extend .TEXTSUBTITLEblueleft;
        }
        @media (max-width: 765px) {
          max-width: 100%;
        }
      }
    }
  }
  .channelGidesContainer {
    display: flex;
    flex: 1;
    background-color: var(--WHITE-BACKGROUND-01);
    padding-top: 16px;
  }
}
