/*
 *
 * Global Variables
 *
 */

/*
 * Colors
 */

$grayFromDesign: rgb(218, 219, 213);
$grayLightened: #edefe6;
$gray: rgb(200, 200, 200);
$gold: #e9a634;
$lightGold: rgb(227, 192, 127);
$goldBright: rgb(243, 173, 49);
$lightGray: rgb(250, 250, 250);
$grayMedium: rgb(125, 125, 125);
$lightGray2: #eaeaea;
$darkGray: rgb(218, 219, 213);
$darkGray2: #3a3a3a;
$darkGray3: #585858;
$green: rgb(23, 121, 128);
$mint: rgb(74, 171, 160);
$plum: rgb(180, 172, 184);
$orange: rgb(217, 126, 56);
$purple: rgb(125, 95, 156);
$headerBorder: rgba(134, 140, 146, 0.3);
$verticalLine: rgba(192, 197, 202, 0.609);
$toolbarBackground : rgb(250, 250, 250);
$overlayBackground: white;
$overlayBorder: #ECECEC;
$containerMainScrollBackground: #F8F8F9;
$navbarBackground: white;
$sidebarBackground: white;
$searchBarBackground: var(--COLOR-SECONDARY-200);
/*
 * Sizes
 */

$overlap: 50px;
%overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

$MAXSLIDEWIDTH : 752px;