@use '../typography';
.profileContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  .profileHeader {
    position: relative;
    .profileBrandingImage {
      object-fit: cover;
      height: 250px;
      width: 100%;
      @media (max-width: 520px) {
        height: 149px;
      }
    }
    .avatar {
      position: absolute;
      top: 177px;
      left: 17px;
      width: 102px;
      height: 102px;
      border-radius: 13px;
      border: solid 2px var(--WHITES-NORMAL-1000);
      @media (max-width: 520px) {
        top: 71px;
        left: 15px;
      }
    }
  }
  .profileDescription {
    margin: 41px 17px 12px 17px;
    .title {
      @extend .TEXTH4blackhigh-emphasisleft;
    }
    .accountType {
      @extend .TEXTSUBTITLEorangeleft;
      margin-left: 2px;
    }
    .descriptionContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 8px;
      .description {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        width: 50%;
        &.isUser {
          @media (max-width: 790px) {
            width: 100%;
          }
        }
        &.isViewer {
          @media (max-width: 552px) {
            width: 100%;
          }
        } 
      }
      .actions {
        margin-left: 0px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        &.isUser {
          @media (max-width: 790px) {
            justify-content: flex-start;
            margin-left: 0px;
            margin-top: 24px;
          }
        }
        &.isViewer {
          @media (max-width: 552px) {
            justify-content: flex-start;
            margin-left: 0px;
            margin-top: 24px;
          }
        }      

        .settingsButton {
          @media (max-width: 520px) {
            position: absolute;
            right: 32px;
            top: 131px;
          }
        }
      }
    }    
  }
  .activityAndChannels {
    display: flex;
    flex: 1;
    margin-top: 5px;
    flex-direction: column;
    .listItems {
      display: flex;
      flex: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      margin-top: 2px;
      padding-top: 12px;
      background-color: var(--WHITE-BACKGROUND-02);      
    }
  }
}


