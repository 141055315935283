.imageGallerySlideEditor {
  @extend %flexColumnBase;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  // The flex 1 is not spanning the height as it should.
  // height: 100%;
  justify-content: space-between;
  position: relative;
  .imageGallerySlideEditorNewMenu {    
    display: flex;    
    justify-content: center;
    align-items: center;
    .or {
      margin: 13px;
      font-family: Aileron;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: 0.25px;
      text-align: center;
      // color: var(--WHITES-NORMAL-700);
    }
  }
  .imageDropZone {
    width: 100%;
    height: 100%; // calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    &.hidden {
      display: none;
    }
    .uploadIndicator {
      background-color: #1e88e5;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 32px;
      border-radius: 20px;
      font-family: Aileron;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: 0.3px;
      text-align: center;
      color: white;
    }

    .uploadLabel {
      margin-top: 5px;
      font-family: Aileron;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.55);
    }
  }
  &.showModalBackground {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.8);
  }
  .e-radio:checked + label::after {
    background: rgb(255, 178, 19) !important;
    color: yellow !important;
  }
  .e-radio:checked + label::before {
    border-color: rgb(255, 178, 19) !important;
  }
  .imageGalleryEditor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    .imageGalleryEditorSelectedImageGalleryOuterContainer {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 752px;
      padding-bottom: 93px;
      .imageGalleryFileCaption {
        @extend .TEXTBODYwhiteregularmedium-emphasisleft;
        width: 100%;
        padding-left: 5px;
      }
      .imageGalleryAddImageActions {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 155px; 
      }      
      .imageGalleryTitleContainer {
        @extend %flexColumnBase;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        // flex: 1;
        margin-bottom: 5px;
        padding: 0 5px 0 5px;        
        .imageGalleryTitleTextBox {
          @extend .TEXTH4blacklow-emphasisleft;
          // margin-top: 15px;
          border: none;
          outline: none;
          // -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
          // -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
          // box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
          resize: none;
          border-radius: 3px;
          width: 100%;
          max-width: 752px;
          min-height: 24px;
          height: 24px;
          overflow: hidden;
          background-color: var(--WHITES-NORMAL-50);
        }
      }
      .swipeableContainer {
        width:100%;
      }
      .selectedImageGalleryImage {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        padding: 50px;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
    &.inactive {
      display: none;
    }

    .imageEditorCropper {
      width: 840px;
      min-height: 840px;       
    }    
  }
  .imageSlideViewerContainer {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 420px) {
  .imageGallerySlideEditor {
    .imageGalleryEditor {
      .imageGalleryEditorSelectedImageGalleryOuterContainer {
        .selectedGalleryImage {
          padding: 5px;
        }
      }
    }
  }
}

// @media only screen and (max-width: 880px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
//         width: 840px;
//         min-height: 840px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 850px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
//         width: 810px;
//         min-height: 810px !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 820px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 780px;
//         min-height: 780px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 790px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 750px;
//         min-height: 750px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 770px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 730px;
//         min-height: 730px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 750px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 710px;
//         min-height: 710px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 730px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 690px;
//         min-height: 690px  !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 710px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 670px;
//         min-height: 670px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 690px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 650px;
//         min-height: 650px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 670px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 630px;
//         min-height: 630px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 650px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 610px;
//         min-height: 610px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 630px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 590px;
//         min-height: 590px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 610px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 570px;
//         min-height: 570px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 590px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 550px;
//         min-height: 550px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 570px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 530px;
//         min-height: 530px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 550px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 510px;
//         min-height: 510px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 530px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 500px;
//         min-height: 500px !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 520px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 450px;
//         min-height: 450px !important;
//       }
//       // .modalBackground {
//       //   top: 48px;
//       // }
//     }
//   }
// }

// @media only screen and (max-width: 440px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {
      
//         width: 400px;
//         min-height: 400px !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 420px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {      
//         width: 370px;
//         min-height: 370px !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 370px) {
//   .imageGallerySlideEditor {
//     .imageGalleryEditor {
//       .imageEditorCropper {      
//         width: 350px;
//         min-height: 350px !important;
//       }
//     }
//   }
// }
