.slideFileTypeSelectorContainer {
    display: flex;
    flex-direction: column;

    .slideFileTypeSelectorCard {
        &:hover {
            cursor: pointer;
        }
        width: 267px;
        height: 72px;
        display: flex;
        margin:12px;
        border-radius: 3px;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 5px 16px 3px rgba(0, 0, 0, 0.02), 0 1px 2px 0 rgba(0, 0, 0, 0.01);
        background-color: var(--WHITES-NORMAL-150);
    }

    .slideFileTypeSelectorCardIcon {
        width: 38px;
        height: 38px;
    }

    .slideFileTypeSelectorCardContent {
        margin-top: 21px;
        margin-left: 28px;
    }

    .slideFileTypeSelectorCardContentTitle {   
        @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
        &.light {
            @extend .TEXTSUBTITLEblackhigh-emphasisleft;
        }
    }

    .slideFileTypeSelectorCardContentMessage {        
        @extend .TEXTSUBTITLE-2whitemedium-emphasisleft;
        margin-top: 1px;

        &.light {
            @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
        }
    }
}