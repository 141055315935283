@use '../../typography';

.image {
  width: 100%;
  --aspect-ratio-w: 3;
  --aspect-ratio-h: 2;
  img {
    object-fit: cover;
  }
}

.channelTitle {
  display: flex;
  padding-top: 2px;
  margin-left: 8px;
  word-break: break-word;
  cursor: pointer;
  @extend .TEXTTIblackboldhigh-emphasisleft;
  &.missing {
    @extend .TEXTTIblackboldlow-emphasisleft;
  }
}

.deleteModal {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100px;
  border-radius: 2px;
  background-color: var(--WHITES-NORMAL-1000);
  z-index: 1;
}

.deleteMessage {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  margin: 12px;
}
