.slideSelectorContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  min-height: 24px;
  .actions {
    &.deleteSlide {
      width: 100%;
    }
    .deleteSlideFooter {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .selector {
    @extend .TEXTSUBTITLEblackmedium-emphasisright;
    cursor: pointer;
    &.preventClick {
      cursor: default;
    }
    margin-right: 5px;
    .slideSelectorSlideNumber {
      @extend .TEXTSUBTITLE-2orangecenter;
      width: 32px;
      margin-left: 10px;
    }
  }
}