@use '../../../typography';
@use '../../../gides-colors';

.channelPublishPreviewContainer {
  display: flex;
  width: 360px;
  background-color: var(--WHITES-NORMAL-1000);
  .logo {
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
    z-index: 100;
    color: var(--WHITES-NORMAL-1000);
  }
  .logoBackground {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    margin-top: 4px;
    margin-left: 3px;
    background: var(--GREYS-NORMAL-100);
  }
  .channelImage {
    img {
      width: 146px;
      height: 103px;
      border-radius: 2px 2px 0 0;
      object-fit: cover;
    }
  }
  .channelPreviewDetails {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    margin-left: 7px;
    width: 100%;
    .channelTitle {
      height: 36px;
      @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      margin-bottom: 2px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .channelSummary {
      display: flex;
      margin-bottom: 3px;
      @extend .TEXTSUBTITLE-2blackmedium-emphasisright;
    }
    .channelInfo {
      display: flex;
      align-items: center;
      @extend .TEXTSUBTITLE-2blackhigh-emphasisleft;
      color: var(--COLOR-BLUE-100);
    }
    .channelFooter {
      display: flex;
      flex: 1;
      margin-bottom: 3px;
      justify-content: space-between;
      align-items: flex-end;
      margin-right: 9px;
      .channelAuthor {
        @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
      }
    }
  }
}
