.swipeViewModal {
  display: flex !important;
  flex: 1;
  max-height: 100vh;
  .ui.modal > .content {
    display: flex !important;
  }

  &.ui.fullscreen.modal {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    width: 100% !important;
    // height: 100% !important;
    position: fixed;
    top: -14px;
    bottom: -14px;
    left: 0px;
    right: 0px;
    height: auto !important;
    max-height: 100vh !important;
  }

  &.ui.modal > .content > .description {
    display: flex !important;
    flex: 1;
    height: 30px !important;
    max-height: 30px !important;
    padding: 0 !important;
  }

  &.ui.modal > .actions {
    padding: 0px !important;
  }
  .swipeViewModalContent {
    @extend %flexColumnBase;
    flex: 1;
    background: #585858 !important;
    .gideBarOverlayIcon {
      filter: brightness(0) invert(1) opacity(0.35);
      &:hover {
        filter: brightness(0) invert(1) opacity(1);
      }
    }
    .swipeableContainer {
      justify-content: center;
    }
    .gidesHeader {
      background: rgba(0, 0, 0, 0.7);
      .articleTitleBar .articleTitle {
        color: white;
      }
      .articleTitleBar {
        .articleActions {
          .viewModeIcon {
            display: none;
          }
        }
      }
    }
    &.content {
      display: flex !important;
      .hideCaptionPanel {
        display: none !important;
      }
    }
    div {
      max-height: 100%;
      img {
        max-height: 100%;
        object-fit: scale-down;
      }
    }
    // .settingsPanel {
    //   height: calc(100% - 58px); // Remove height of description container
    //   overflow-y: auto;
    // }
    // &.EDGE {
    //   .slideView .card {
    //     max-width: 100%;
    //     width: 100% !important;
    //   }
    // }
  }
}

@media only screen and (max-width: 767px) {
  .swipeViewModalContent {
    &.ui.modal > .content {
      padding: 0px !important;
    }
  }
}
@media only screen and (max-width: 520px) {
  .swipeViewModal .swipeViewModalContent .gideBarOverlayIcon {
    display: none;
  }
}
