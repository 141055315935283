.slideEditorCommandBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  min-height: 48px;
  padding-right: 12px;
  &.darkBackground {
    background: rgba(0, 0, 0, 0.8);
  }
  &.dark {
    background-color: var(--WHITES-NORMAL-50);
  }
  .cornerAction {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .commandBarContent {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end; // DEFAULT
    height: 100%;
  }
  .commandBarDefaultActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .slideSettingsSeparator {
    width: 1px;
    height: 24px;
    background-color: #d1d1d1;
    margin: 0px 3px 0px 14px;
  }
}
