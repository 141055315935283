@use '../../typography';
.signinModalContainer {
  height: 100%;
  max-height: 466px;
  max-width: 528px;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (max-width: 520px) {
    max-height: 100%;
    max-width: 100%;
    height: 100vh !important;
  }
  .signinModalOuterFormContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    .signinModalDetails {
      padding: 20px 16px 20px 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 700px;
      width: 100%;
      .heading {
        @extend .TEXTTIblackboldhigh-emphasisleft;
        width: 100%;
        padding-left: 5px;
      }
      .subHeading {
        @extend .TEXTBODYblackregularmedium-emphasisleft;
        width: 100%;
        padding-left: 5px;
      }
      .linkText {
        @extend .TEXTSUBTITLEblueleft;
        cursor: pointer;
      }
      .signup {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        margin-top: 16px;
        padding-left: 5px;
      }
      .usernamePassword {
        display: flex;
        margin-top: 27px;
        justify-content: space-between;
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        width: 100%;
        .userInput {
          width: 240px;
        }
        @media (max-width: 520px) {
          flex-direction: column;
          .userInput {
            width: 100%;
          }
        }
      }
      .signinButton {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        width: 100%;
      }
    }
  }
  .imageLabel {
    max-width: 339px;
    width: 100%;
    font-family: HILVCC+Boing;
    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.29px;
    color: var(--WHITES-NORMAL-1000);
  }
}
