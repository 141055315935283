.slideFileChooser {
  display: flex;
  flex: 1;
  flex-flow: column;
  width: 100%;
  align-items: center;
  position: relative;
  background: var(--WHITES-NORMAL-1000);
  .gideSummaryPreview {
    width: 100%;
    height: 100%;
    margin-left: 13px;
    border-left: solid lightgray 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .gideSummaryInfo {
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 17px;
      .gideImage {
        height: 32px;
        width: 46px;
        border-radius: 4px;
        object-fit: cover;
      }
      .gideTitle {
        margin-left: 18px;
        @extend .TEXTBODYblackregularhigh-emphasisleft;
      }
    }
    .gideSummaryActions {
      @extend %flexRowNormal;
      justify-content: flex-end;
    }
  }
  .slideFileChooserActionBar {
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid var(--GREYS-NORMAL-50) 2px;
    &.displayArticlePreview {
      -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
    }
  }
  .articleChooser {
    width: 100%;
    display: flex;
    flex: 1;
    border: none;
    max-height: calc(100% - 150px);
  }
  .slideFileChooserTitle {
    background-color: var(--WHITES-NORMAL-1000);
    .title {
      @extend .TEXTH3blackhigh-emphasisleft;
      margin-left: 16px;
      margin-top: 24px;
      .subtitle {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    width: 100%;
  }
  .articleChooserContainer {
    padding-top: 5px;
    max-height: inherit;
  }
  .slideFileChooserSearchContainer {
    display: flex;
    flex: 1;
    -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
    border-radius: 21px;
    font-family: Aileron;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.81);
    width: 100%;
    min-height: 42px;
    max-height: 42px;
    padding: 10px;    
    background-color: var(--WHITES-NORMAL-1000);

    textarea {
      width: 100%;
      border: none;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;
    }
  }
  .slideFileChooserFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    background-color: var(--WHITES-NORMAL-1000);
    width: 100%;
    .selectorAndLabel {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      display: flex;
      align-items: center;
    }
    .slideSelectorSlideNumber {
      @extend .TEXTSUBTITLE-2orangecenter;
      width: 32px;
      margin-left: 10px;
    }
  }
  .slideFileChooserSearchIcon {
    &:hover {
      cursor: pointer;
    }
  }
  .slideFileChooserSearchResults {
    max-height: calc(100% - 150px);
  }
  .slideFileChooserSearchResults,
  .slideFileChooserImageListSelection {
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
  .slideFileChooserImageListSelection {
    max-height: calc(100vh - 52px);
    align-items: center;
    // margin-top: 3px;
  }
  .slideFileChooserImageListContainer {
    position: relative;
    @extend %flexColumnFull;
    min-width: 100%;
    .slideFileChooserImageList {
      @extend %flexColumnBase;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding-top: 24px;
      background-color: var(--WHITE-BACKGROUND-03);
      .slideFileChooserSlideViewCard {
        display: flex;
        border-radius: 5px;
        background-color: var(--WHITES-NORMAL-1000);
        width: calc(100% - 16px);
        margin-bottom: 5px;
        margin-right: 8px;
        margin-left: 8px;
        .slideFileChooserFileContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-left: 6px;
          margin-right: 6px;
          border-radius: 5px;
          flex: 1;
          .slideView {
            width: 100%;
            background-color: var(--WHITES-NORMAL-1000) !important;
          }
          .slideFileChooserImage {
            padding: 5px;
            width: 100%;
            max-width: $MAXSLIDEWIDTH;
            object-fit: cover;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .slideFileChooserImageSelectionActions {
      position: absolute;
      right: 12px;
      bottom: 24px;
      display: flex;
      align-items: center;
      .saveImageSelectionButton,
      .cancelImageSelectionButton {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: Aileron;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 0.3px;
        color: rgba(255, 255, 255, 0.95);
        width: 77px;
        height: 27.9px;
        border-radius: 14px;
        bottom: 175px;
        &:hover {
          cursor: pointer;
        }
      }
      .saveImageSelectionButton {
        background-color: #ffb213;
      }
      .cancelImageSelectionButton {
        background-color: #373737;
        margin-right: 10px;
      }
    }
  }
}
@media only screen and (max-width: #{$MAXSLIDEWIDTH}) {
  .slideFileChooser {
    .slideFileChooserImageListContainer {
      .slideFileChooserImageList {
        .slideFileChooserSlideViewCard {
          .slideFileChooserFileContainer {
            .unselectedImageBox,
            .selectedImageBox {
              right: 5px;
            }
          }
        }
      }
      .slideFileChooserImageSelectionActions {
        right: 25px;
      }
    }
  }
}

@media (max-width: 520px) {
    .slideFileChooser .slideFileChooserImageListContainer .slideFileChooserImageList .slideFileChooserSlideViewCard {
    width: calc(100% - 4px);
    margin-right: 2px;
    margin-left: 2px;
  }
}
