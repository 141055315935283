.gideActionsContainer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  svg {
    cursor: pointer;
  }
  .headerBarIcon {
    margin-left: 24px;
  }
  @media (max-width: 375px) {
    .headerBarIcon {
      margin-left: 12px;;
    }
  }
}
