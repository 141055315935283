@use '../../typography';
.createChannelComponent {
  .createChannelImage {
    display: flex;
    width: 76px;
    height: 54px;
    border-radius: 3px;
    margin-left: '22px';
    background-image: linear-gradient(336deg, var(--COLOR-PRIMARY-400) 79%, #fab317 27%);
    color: var(--WHITES-NORMAL-1000);
    justify-content: center;
    align-items: center;   
    @media (max-width: 520px) {
    margin-left: 0px; 
    }
  }
  .createChannelTitle {
    @extend .TEXTSUBTITLEblackhigh-emphasisleft;
    margin-left: 13px;
    margin-top: 12px;
  }
}
