.slideshowEditor {
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    color: white;
    margin: 0, 2% !important;
    height: 24px;
  }
  h3 {
    color: white;
    width: 100%;
    margin-top: 25px;
  }
  .mediaPlayer {
    width: 100%;
  }
  .e-label,
  .slideshowLabel {
    font-size: 14px;
    color: white !important;
    line-height: 20px;
    letter-spacing: 0.15px;
    font-family: Aileron-Regular;
    a:hover {
      background-color: white;
    }
  }
  .slideshowInput {
    width: 80px;
    height: 24px;
    color: white;
    margin-left: 5px;
  }
  .e-checkbox-wrapper {
    margin-right: 15px;
  }
  .slideshowTitleLabel {
    font-size: 20px;
    color: white;
    line-height: 30px;
    letter-spacing: 0.15px;
    font-family: Aileron-Regular;
    width: 100%;
  }
  .slideList {
    height: 150px;
    max-height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    cursor: pointer;
  }
  .e-control-wrapper.e-slider-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    // width: 100%;
  }
}

.slideshowSlideSlide {
  padding: 2%;
  width: 100%;
  .slideshowSlideSlideHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .editButton {
      margin-right: 10px;
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .card {
    width: 100%;
  }

  .childArticleEditorSlide {
    border: solid 1px purple;
    // background: url(/images/bg-small.png) repeat;
  }

  .slideshowTitle {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 30px;
    letter-spacing: 0.15px;
    font-family: Aileron-Regular;
  }
  .slideshowSlideImage {
    width: 46px;
    height: 32px;
    border-radius: 4px;
    margin-left: 12px;
    cursor: pointer;
    .slideshowImage {
      border-radius: 4px;
      width: 46px;
      height: 32px;
      object-fit: cover;
    }
  }
  .slideshowViewer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    .slideShowPreview {
      position: relative;
      width: fit-content;
      border: solid 1px white;
      .slideshowTitle {
        color: white;
        text-align: center;
        background-color: black;
      }
    }
  }

  .slideOverlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    background-color: rgb(223, 222, 222);
  }

  .childArticleViewer {
    &.removeBorder {
      border-width: 0px;
      background: transparent;
      padding: 0px;
    }
  }
}

.slideshowSlide {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 5px;
  font-family: Aileron-SemiBold;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.75);
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  background: white;
  .slideshowTitle {
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .slideshowImage {
    height: 24px;
    border-radius: 3px;
    object-fit: cover;
    margin: 0 5px 0 5px;
  }
  &.selected {
    background-color: #fdeccd;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 5%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
}



.slider-content-wrapper {
  width: 40%;
  margin: 0 auto;
  min-width: 185px;
}

.slider-userselect {
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.labelText {
  text-align: left;
  font-weight: 500;
  padding-bottom: 10px;
}

// .slider_container {
//   margin-top: 40px;
// }

.e-bigger .content-wrapper {
  width: 80%;
}

#range_slider .e-handle {
  border-radius: 0;
  background-color: #f9920b;
  border: 0;
}

#circle_slider .e-handle {
  background-color: #f9920b;
  border-radius: 50%;
  border: 0;
}

#slide_slider .e-handle {
  height: 25px;
  width: 24px;
  background-size: 24px;
}

#slide_slider .e-handle {
  background-image: url('https://ej2.syncfusion.com/demos/src/slider/images/thumb.png');
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
}

.e-tooltip-slideshow-slide {
  &.e-slider-tooltip.e-tooltip-wrap.e-popup
    .e-tip-content.e-material-tooltip-show {
    z-index: 1010;
    color: white;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup {
    z-index: 1010 !important;
    background-color: #f9920b;
    border: 0;
    border-radius: 8px;
    cursor: grab;
    opacity: 1;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-inner {
    color: #f9920b;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-bottom {
    border-top-color: #f9920b;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-top {
    border-bottom-color: #f9920b;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-right {
    border-left-color: #f9920b;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-left {
    border-right-color: #f9920b;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup
    .e-tip-content.e-material-tooltip-hide {
    color: transparent;
  }

  &.e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default {
    background-color: #f9920b;
    border: 0;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    cursor: grab;
    opacity: 1;
  }
}

#range_slider .e-tab-handle::after,
#circle_slider .e-tab-handle::after {
  background-color: #f9920b;
}

#slide_slider .e-tab-handle::after {
  background-color: transparent;
}

#oval_slider .e-handle {
  height: 25px;
  width: 8px;
  top: 3px;
  border-radius: 15px;
  background-color: #f9920b;
}

#slide_slider .e-slider-track::after {
  height: 8px;
  background-color: transparent;
}

#slide_slider .e-slider-container.e-horizontal .e-limits {
  background-color: transparent;
}

#range_slider .e-slider-container.e-horizontal .e-limits {
  background-color: white;
}

#slide_slider .e-slider-track::after {
  height: 8px;
  background-color: blue;
}
