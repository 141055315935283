.carouselImageSettings {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 19px 0px;
  // position: absolute;
  background-color: white;
  // width: 300px;
  // top: 7px;
  // right: 7px;
  // min-height: 50px;
  .viewOptions {
    margin-top: 12px;
    display: flex;
    justify-content: space-evenly;
    .labelActive {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      margin-left: 9px;
    }
    .labelInactive {
      @extend .TEXTSUBTITLEblacklow-emphasisleft;
      margin-left: 9px;
    }
  }
  .advancedCarouselOptionsButton {
    @extend .TEXTSUBTITLE-2blackmedium-emphasiscenter;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  .advancedCarouselOptions {
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    &.hidden {
      display: none;
    }
    .imageAdvanceMode {
      display: flex;
      justify-content: space-evenly;
      margin-top: 10px;

    }
    .imageAdvanceSpeed,
    .imageMaxHeight,
    .imageObjectFit {
      display: flex;
      flex-direction: column;
      .imageObjectFitOptions {
        margin-top: 12px;
        display: flex;
        justify-content: space-evenly;
        
      }
      .ui.input {
        input {
          @extend .TEXTSUBTITLEblacklow-emphasisleft;
          margin-top: 10px;
          background: #fafafa;
          border: none;
        }
      }
    }
    .imageAdvanceSpeed {
      margin-top: 23px;
    }
    .imageMaxHeight {
      margin-top: 12px;
    }
    .imageObjectFit {
      margin-top: 18px;
    }
  }
}
