@use '../../../typography';
.mediaChooserContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;  
  .selectionContainer {display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;   
    width: 100%;
    .dropContainer {
      cursor: pointer;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 497px;
      height: 334px;
      width: 100%; 
      border-radius: 6px;
      border: dashed 2px rgba(255, 255, 255, 0.13);    
      margin: 35px 7px 0 7px;
      &.inactive {
        display: none;
      }
      &.darkMode {
        background-color: var(--WHITES-NORMAL-50);
      }
      @media (max-width: 520px) {
        margin-top: 24px;
        height: 242px;
        width: calc(100% - 30px);
      }
      .title {
        @extend .TEXTH3whitehigh-emphasiscenter;
        margin: 14px 0px 8px 0px;
      }
      .subTitle {
        @extend .TEXTSUBTITLEwhitemedium-emphasiscenter;
      }
    }
    .webcamDisplay {
      margin-top: 25px;
      margin-right: 10px;
      margin-left: 10px;
      width: 100%;
      max-width: 750px;;
      max-height: calc(100vh - 250px);
      object-fit: contain;
      &.inactive {
        display: none;
      }
    }
  }
  .actionContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .primaryButtonContainer {
      width: 96px;
      height: 96px;
      background-color: var(--WHITES-NORMAL-50);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;      
      cursor: pointer;
      &.cameraStarted {
        background-color: var(--COLOR-BLUE-100);
        &.video {
          background-color: var(--COLOR-RED-100);
        }
      }
    }
    .primaryButtonAndLabel {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .leftSpacer,
    .rightActionsContainer {
      width: calc((100% - 70px) / 2);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }    
    .rightActionsInnerContainer {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding-right: 22px;
    }
    .rightActions {
      display: flex;      
      justify-content: center;  
      justify-items: center;    
      margin-top: -24px;
      margin-bottom: 22px;
      .labeledButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .uploadLabel {
          @extend .TEXTSUBTITLE-2whitehigh-emphasiscenter;
          margin-top: 5px;
        }
      }      
    }
    .otherOptions {      
      @extend .TEXTSUBTITLEwhitehigh-emphasiscenter;
      // display: flex;      
      // justify-content: center;
      // padding-right: 22px;
    }
  }
  .startTitle {
    @extend .TEXTSUBTITLEwhitehigh-emphasiscenter;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 54px 0;    
    width: 150px;
  }
  @media (max-width: 520px) {
    .rightActionsInnerContainer {
      display: none !important;
    }
    .mobileActionContainer {
      display: block !important;
    }
    .otherOptions {
      margin-top: -14px;
      margin-bottom: 10px;
    }
  }
  .mobileActionContainer {
    display: none;
  }
}
