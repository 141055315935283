.viewBarSearchContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 42px;
  width: 100%;
  max-width: 366px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 12px;
  border-radius: 21px;
  box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-100), 0 6px 10px -2px var(--GREYS-NORMAL-50), 0 7px 18px 0 var(--GREYS-NORMAL-100);
  background-color: var(--WHITES-NORMAL-1000);
  pointer-events: all;
  .viewBarSearchNav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
    &.hide {
      display: none;
    }
    .arrowLeft {
      margin-right: 11px;
    }
  }
  .viewBarSearchExitIcon {
    width: 17px;
    height: 18px;
    min-height: 17px;
    .circleIconButton {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }
  }
  .viewBarRight {
    display: flex;
    margin-right: 14px;
    align-items: center;
  }
  .viewBarSearchInput {
    @extend .TEXTBODYblackregularhigh-emphasisleft;
    border: none;
    width: 100%;
    margin-left: 16px;
    margin-right: 5px;
    margin-top: 2px;
    height: 24px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    overflow: hidden;
  }
}
