.overlay {
  position: relative;
  .point {
    cursor: pointer;
    background: rgb(37, 133, 108);
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 14px;
    width: 27px;
    height: 27px;
  }
  .point.selected {
    background: #47b947;
  }
  .number {
    padding: 4px 6px;
    background: #efefef;
    color: black;
    border-radius: 9px;
    width: 18px;
    height: 18px;
  }
  .label {
    bottom: -45px;
    background: white;
    width: auto;
    height: 40px;
    padding: 15px;
    position: absolute;
    color: #333;
    border-radius: 20px;
  }
}
