@use '../../../typography';
.componentCarouselContainer {
  display: flex;
  flex: 1;
  position: relative;
  max-height: var(--height);
  height: var(--height);
  @media (max-width: 520px) {
    max-height: var(--mobileHeight);
    height: var(--mobileHeight);
  }
  .rightNavigation,
  .leftNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 50px;
    background-color: grey;
    opacity: .3;
  }
  .rightNavigation {
    right: 0;
  }
  .leftNavigation {
    left: 0;
  }
  .desktop {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
  }
  .tablet {
    display: none;
    @media (min-width: 521px) and (max-width: 767px) {
      display: flex;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 520px) {
      display: flex;
    }
  }
  .bulletNavigation {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    .bullet {
      width: 10px;
      height: 10px;
      background-color: rgba(255, 255, 255, 0.35);
      border-radius: 50%;
      margin-left: 4px;
      margin-right: 4px;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        background-color: #ffffff;
        width: 12px;
        height: 12px;
      }
    }
  }
}
.labeledPositionComponent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
    margin-left: 1px;
    .labeledComponentLabel {
      font-family: HILVCC+Boing;
      font-size: 55px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.73;
      letter-spacing: 0.46px;
      color: var(--WHITES-NORMAL-1000);
      @media(max-width: 767px) {
        font-family: HILVCC+Boing;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.25px;
        color: rgba(255, 255, 255, 0.95);
      }
    }
  }
  .viewInstructionsButton,
  .button {
    width: 168px;
    cursor: pointer;    
    margin-left: 8px;
    background-image: linear-gradient(283deg, var(--COLOR-PRIMARY-700) 101%, #fab317 29%);      
    &.addTopMargin {
      margin-top: 26px;
    }
    @media (max-width: 767px) {
      margin-top: 22px;        
      &.addTopMargin {
        margin-top: 30px;
      }
    }
  }
  .viewInstructionsButton {
    width: 188px;
  }
  @media (max-width: 767px) {
    left: 19px;
    bottom: 83px;
  }




  .desktopIcon {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }      
  }
  .mobileIcon {
    display: none;
    @media (max-width: 767px) {
      display: flex;
    }   
  }
}
