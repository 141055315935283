.audioCarouselContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 7px;

  .audioCarouselAudioPlayer {
    position: relative;
    width: 100%;
    display: flex;
    flex: 1;
    .audioViewContainer {
      width: 100%;
    }
  }
  .caption {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 0 10px 0 10px;
    max-height: 192px;
    overflow: hidden;
    @extend .TEXTBODYblackregularmedium-emphasisleft;
  }

  .thumbnailPanel {
    margin-top: 18px;
    margin-bottom: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    .thumbnailContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: var(--COLOR-BLUE-100);
      margin-left: 15px;
      margin-right: 15px;
      &.inactive {
        opacity: 0.2;
      }
      .thumbnailImage {
        margin: 3px;
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
