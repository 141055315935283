@use '../../../../typography';

.slideDimensionsPickerModalContainer {
  margin-top: 24px;
  .widthPercentageSection {
    display: flex;
    flex-direction: column;
    
    .sizeTitle {
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
      margin-left: 10px;
    }
    .percentageComponents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .slideWidthInput {
        // @extend .TEXTSUBTITLEblacklow-emphasisleft;
        width: 75px;
        height: 38px;
        margin: 4px 0px 0px 12px;
        // margin-right: 25px;
        // margin-left: 12px;
        :global(.ui.basic.label) {
          border-left: 0px;
        }
        :global(.ui.label) {
          @extend .TEXTSUBTITLEblacklow-emphasisleft;
          padding-right: 21px;
          padding-left: 3px;
          background-color:  #fafafa;
          min-width: 37px;
          max-width: 37px;
        }
        input {
          @extend .TEXTSUBTITLEblacklow-emphasisleft;
          padding-left: 10px;
          padding-right: 1px;
          text-align: right;
          background-color:  #fafafa;
          min-width: 38px;
          max-width: 38px;
        }
      }
      .slideDimensionsPickerModuleSlider {
        width: 252px;
        padding: 0 12px 0 0px;
      }
    }
  }
}