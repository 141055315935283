@use '../../../typography';

.userTileContainer {
  display: flex;
  flex: 1;
  height: 62px;
  align-items: center;
  margin-left: 7px;
  background-color: var(--WHITES-NORMAL-1000);
  .avatarContainer {
    display: flex;
    position: relative;
    .userLanguageFlag {
      position: absolute;
      bottom: 7px;
      right: 8px;
      border-radius: 50%; 
      width: 14px;
      height: 14px;
      border: solid 1px var(--WHITES-NORMAL-1000);
    }
  }
  .userDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 6px;
    .userName {
      @extend .TEXTH4blackhigh-emphasisleft;
    }
    .categoryAndCounts {
      display: flex;
      align-items: center;
      .category {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        margin-right: 3px;
      }
      .counts {
        @extend .TEXTSUBTITLE-2blacklow-emphasisleft;
        display: flex;
        align-items: center;
      }
    }
  }
}