.childArticleViewer {
    padding: 4px;
    border: solid .5px rgb(200, 200, 200);

    .childArticleViewerHeader {
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white; // margin-bottom: 28px;
        box-shadow: 0px 0px 5px 5 rgba(0, 0, 0, 0.3), 0px 1px 4px 1 rgba(0, 0, 0, 0.5);

        .childArticleViewerRight {
            display: flex;
            align-items: center;

            .childArticleSlideNumberContainer {
                position: relative;
                display: flex;
                width: 34px;
                align-items: center;
                margin: 0 11px 0 7px;
                justify-content: flex-end;

                .ownerSlideNumber {
                    position: absolute;
                    display: flex;
                    left: 13px;
                    top: 5px;
                    align-items: center;
                    font-family: Aileron;
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    letter-spacing: 0.3px;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.95);
                }
            }

            .slideNumberText {
                width: 39px;
                height: 12px;
                font-family: Aileron;
                font-size: 10px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.2;
                letter-spacing: 0.2px;
                color: #cccccc;
            }
        }

        .childArticleViewerLeft {
            margin-left: 14px;

            .slideAttatchmentText {
                margin-left: 14px;
                width: 167px;
                height: 30px;
                font-family: Aileron;
                font-size: 20px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: 0.1px;
                color: rgba(0, 0, 0, 0.75);
            }
        }
    }
}