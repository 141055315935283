@use '../../typography';

.formatIcon {
    width: 22px !important;
    height: 22px !important;
    margin: 1px;
}

.formatCircleIconButtonCssClass {
    width: 24px;
    height: 24px;
}

.deleteModal {
    position: absolute;
    top: 0;
    right: 10px;
    height: 100px;
    border-radius: 2px;
    background-color: var(--WHITES-NORMAL-1000);
    z-index: 1;
}

.deleteMessage {
    @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    margin: 12px;
}

.formatModal,
.dimensionsModal {
    position: absolute;
    top: 0;
    right: 10px;
    border-radius: 2px;
    background-color: var(--WHITES-NORMAL-1000);
    z-index: 1;
}
.slideZeroSlideView {
    padding-top: 11px;
    @media (max-width: 520px) {
        padding-top: 8px;
    }
}