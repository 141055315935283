.progressBarContainer {
    height: 5px;
    position: relative;
    width: 100%;
    background-color: #f5f5f5;

    .progressBar {
        background: #1e88e5;
        position: absolute;
        left: 0%;
        width: 0%;
        top: 0px;
        bottom: 0px;
    }
}