.channelSearchPreviewContainer {
  height: 62px;
  max-height: 62px;
  display: flex;
  flex: 1;
  max-width: 752px;
  align-items: center;
  .channelImageContainer {
    position: relative;
    .channelImage {
      width: 81px;
      min-height: 57px;
      height: 57px;
      border-radius: 3px;
      object-fit: cover;
    }
    .channelAuthorImage {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 5px;
    justify-content: space-between;
    .channelName {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    }
    .channelType {
      @extend .TEXTSUBTITLEblueleft;
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .channelUsage {
        @extend .TEXTSUBTITLE-2blacklow-emphasisleft;
      }
    }
  }
}
