.imageSlideEditor {
  @extend %flexColumnBase;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  // The flex 1 is not spanning the height as it should.
  // height: 100%;
  justify-content: space-between;
  position: relative;
  display: block;

  &.showModalBackground {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.8);
  }

  .imageSlideViewerContainer {
    @extend %flexColumnBase;
    flex: 1;
    // overflow-y: auto;
    padding-bottom: 93px;
    // padding-left: 10px;
    // padding-right: 10px;
  }

  .imageDropZone {
    width: 100%;
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    &.hidden {
      display: none;
    }
    .uploadIndicator {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      background-color: var(--COLOR-BLUE-100);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 103px;
      height: 30px;
      border-radius: 5px;
    }

    .uploadLabel {
      @extend .TEXTH4whitemedium-emphasiscenter;
      margin-top: 5px;
    }
  }

  .webcamDisplay {
    object-fit: contain;
    &.inactive {
      display: none;
    }
  }

  .imageEditor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    height: 100%;
    // overflow-y: auto;
    &.imageCropper {
      flex-direction: row;
    }
    .imageViewCaptionContainer {
      margin-bottom: 80px;
      overflow-wrap: break-word;
      word-break: break-all;
    }
    .imageEditorSelectedImageOuterContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      padding-bottom: 93px;

      &.linkImage {
        .imageViewOuterContainer {
          justify-content: flex-start;
        }
      }
    }

    &.inactive {
      display: none;
    }

    .imageLinkContainer {
      @extend %flexColumnBase;
      margin-top: 25px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 752px;
      // flex: 1;
      margin-bottom: 24px;
      padding: 0 5px 0 5px;
    }
  }


  .modalBackground {
    &.hidden {
      display: none;
    }
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    .carouselImageSettingsTriangle {
      @extend %pointerTriangle;
      right: 190px;
    }
    .carouselImageSettings {
      position: absolute;
      top: 0px;
      right: 7px;
    }

  }
}
