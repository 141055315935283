.channelPreview {
  width: 384px;
  height: 101px;
  padding: 5px;
  margin: 0 8px 20px 8px;
  background-color: white;
  display: flex;
  cursor: pointer;
  box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.1);
  .channelImage {
      width: 124px;
      height: 91px;
      object-fit: cover;
  }
  .channelPreviewDetail {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-left: 9px;
      justify-content: space-evenly;
      margin-bottom: -10px;
      .channelName {
          display: flex;
          align-items: center;
          @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      }
      .channelAuthorImage {
          width: 24px;
          height: 24px;
          border: solid 1px white;
          border-radius: 50%;
          margin-right: 4px;
      }
      .channelAuthorInfo {
          @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
          display: flex;
          align-items: center;          
      }
      .channelType {
          @extend .TEXTSUBTITLEblueleft;
          display: flex;
          justify-content: flex-start;         
      }
      .channelPreviewFooter {          
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 3px;          
          .channelAccessInfo {
            @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
              display: flex;
              align-items: center;             
          }
          .channelGideInfo {
            @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .followerInfo {
                  margin-left: 4px;                  
              }
              .totalGides {
                  margin-left: 4px;                  
              }
          }
      }
  }
}
@media (max-width: 400px) {
  .channelPreview {
    width: 100%;
  }
}