// NOTE:
// Be aware that there are cases where min-height: 0 can cause issues.
// e.g. if you open up LinkSlideEditor using the app. Then open the dev console
// and find the class linkSlideEditorContainer and add min-height: 0 to the element.style
// you will see that it no longer scrolls all the way to the bottom essentially ignoring the
// bottom spacer.
// ALSO: min-height: 0 fixes issues in all browsers using Flex. You will notice many
// issues if for instance you go to %flexColumnBase and comment out min-height: 0
// SO: There are cases when you need it and cases where you don't. I have not completely isolated
// the issue so I am checking on a case by case basis until I can identify the priciple causing
// the issue.

// Flex Column
%flexColumnBase {
  display: flex;
  flex-flow: column;
  min-height: 0;
}

%flexColumnFull {
  @extend %flexColumnBase;
  flex: 1;
}

%flexColumnFullStretch {
  @extend %flexColumnBase;
  flex: 1;
  width: 100%;
}

.flexColumnFlexNormal {
  @extend %flexColumnBase;
}

.flexColumnFlexStretch {
  @extend %flexColumnBase;
  width: 100%;
}

.flexColumnFull {
  @extend %flexColumnFull;
}

.flexColumnFlexCenter {
  @extend %flexColumnBase;
  justify-content: center;
  align-items: center;
}

// Flex Row
%flexRowNormal {
  display: flex;
  align-items: center;
  align-content: center;
}

%flexRowFlexEnd {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

%flexRowFlexStretch {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: stretch;
}

%flexRowFull {
  display: flex;
  flex: 1;
  align-content: center;
  align-items: center;
}

%flexRowCenter {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

%flexRowSpaceBetween {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.flexRowFlexStart {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.flexRowFlexEnd {
  @extend %flexRowFlexEnd;
}

.flexRowFullFlexTop {
  display: flex;
  flex: 1;
  align-content: center;
  align-items: flex-start;
}

.flexRowFlexNormal {
  @extend %flexRowNormal;
}

.flexRowFlexWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.flexRowFlexStretch {
  @extend %flexRowFlexStretch;
}

.flexRowFullBottom {
  display: flex;
  flex: 1;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
}

.flexRowFull {
  @extend %flexRowFull;
}

.flexRowFullTop {
  display: flex;
  flex: 1;
  align-content: center;
}

.flexRowCenter,
.flexRowFullCenter {
  @extend %flexRowCenter;
}

.flexRowFullCenter {
  flex: 1;
}

.flexRowFullFlexEnd {
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.flexRowSpaceBetween {
  @extend %flexRowSpaceBetween;
}
