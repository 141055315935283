@use '../../typography';
@use '../../flex';
@use '../../modals/SlideEditorModal/slide-editor-modal';

.formatIcon {
  width: 22px !important;
  height: 22px !important;
  margin: 1px;
}

.formatCircleIconButtonCssClass {
  width: 24px;
  height: 24px;
}

.deleteModal {
  position: fixed;
  top: 48px;
  right: 10px;
  height: 100px;
  border-radius: 2px;
  background-color: var(--WHITES-NORMAL-1000);
  z-index: 1;
}

.deleteMessage {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  margin: 12px;
}

.formatModal,
.dimensionsModal {
  position: fixed;
  top: 48px;
  right: 10px;
  border-radius: 2px;
  background-color: var(--WHITES-NORMAL-1000);
  z-index: 1;
}
.slideFileTouchActionPicker {
  position: absolute;
  right: 0px;
  top: 7px;
  // width: 256px;
  &.allowfullScreen {
    width: 100%;
    bottom: 0px;
  }
  border: none;
}

.slideView {
  padding-top: 11px;
  @media (max-width: 520px) {
      padding-top: 8px;
  }
}

.blur {
  filter: blur(10px);
  pointer-events: none;
}

.flipHorizontal {
  transform: scaleX(-1);
}
