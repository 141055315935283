.gidesOptionButton {
  @extend %flexRowFull;
  justify-content: space-between;
  font-family: Aileron;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.55);
  &:hover {
    cursor: pointer;
  }
  -webkit-transition: box-shadow 500ms, width 500ms, background-color 500ms;
  /* For Safari 3.1 to 6.0 */
  transition: box-shadow 500ms, width 500ms, background-color 500ms;
  .gidesOptionButtonImage {
    width: 24px; // TODO: Make this configurable
    height: 24px;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
  .gidesOptionButtonLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    margin-right: 8px;
  }
}
