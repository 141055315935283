.linkInputContainer {
  @extend %flexColumnBase;
  align-items: center;
  justify-content: center;
  width: 100%;
  // padding: 0 5px 0 5px;
  box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-100), 0 6px 10px -2px var(--GREYS-NORMAL-50), 0 7px 18px 0 var(--GREYS-NORMAL-100);
  border-radius: 21px;
  position: relative;
  min-height: 42px;
  max-height: 126px;
  &.dark {
    background: var(--WHITES-NORMAL-100);
    textarea {
      background-color: transparent;
      color: white;
      &::placeholder {
        color: #cccccc;
      }
    }
  }
  .linkInputTextBox {    
    @extend .TEXTBODYblackregularhigh-emphasisleft;
    min-height: 37px;
    margin-top: 5px;
    margin-left: 12px;
    // line-height: 1.5;
    line-height: 30px !important;
    border: none;
    outline: none;
    // box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-100), 0 6px 10px -2px var(--GREYS-NORMAL-50), 0 7px 18px 0 var(--GREYS-NORMAL-100);
    resize: none;
    // border-radius: 21px;
    width: calc(100% - 60px);

    // padding: 0 25px 0 25px;

    // max-width: 752px;
  }
  .linkInputIconContainer {
    position: absolute;
    top: 9px;
    &.iconLeft {
      left: 10px;      
    }
    &.iconRight {
      right: 10px;      
    }
    .linkInputIconImage {
      width: 24px;
      height: 24px;
    }
  }
}
