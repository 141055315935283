@import '../../typography';
.distributeSettingsEditorContainer {
  position: relative;
  display: flex;
  flex:1;
  align-items: center;
  flex-direction: column;
  background-color: var(--WHITE-BACKGROUND-01);
  justify-content: space-between;
  position: relative;
  max-width: 100vw;
  .scrollArea {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100% - 80px);
    &.selectChannel {
      max-height: 100%;
    }
    &.finishPublish {
      max-height: calc(100% - 140px);
    }
    .beginPublish {
      display: flex;
      flex-direction: column;
      // max-width: 375px;
      width: 100%;
      height: 100%;
      align-items: center;

      .distributeGideTileContainer {
        position: relative;
        margin-top: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        max-width: 375px;
        width: 100%;
        min-height: 247px;
        border-radius: 5px;
        background-color: var(--WHITES-NORMAL-1000);
        &.removeBackground {
          background-color: transparent;
        }
        &.congratulations {
          margin-top: 5px;
          margin-bottom: 70px;
        }
        .editTitleAndImage {
          @extend .TEXTSUBTITLEblackmedium-emphasisleft;
          display: flex;
          align-items: center;
          min-width: 100%;
          cursor: pointer;
        }
        .gideCopyToDrafts {
          position: absolute;
          top: 24px;
          right: 77px;
          z-index: 2;
        }
        .gideCopyToDraftsIcon {
          // opacity: .5;
          position: absolute;
          top: 58px;
          right: 140px;
          z-index: 3;
        }
      }
      .publishGideContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        background-color: var(--COLOR-BLUE-20);
        max-width: 375px;
        width: 100%;
        height: 225px;
        margin-top: 18px;
      }
      .selectedChannelContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        background-color: var(--WHITES-NORMAL-1000);
        max-width: 375px;
        width: 100%;
        height: 149px;
        margin-top: 11px;
        .editChannel {
          @extend .TEXTSUBTITLEblackmedium-emphasisleft;
          display: flex;
          align-items: center;
          min-width: 100%;
          cursor: pointer;
        }
      }
      .channelContratulationsContainer {
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        max-width: 375px;
        width: 100%;
        margin-top: 11px;
        .shareGideContainer {
          margin-top: 9px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: var(--COLOR-BLUE-10);
          width: 100%;
          max-width: 375px;
          height: 225px;
          justify-content: space-evenly;
        }
      }
      .gidePublishedActions {
        display: flex;
        position: fixed;
        bottom: 24px;
        right: 11px;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
      }
      .copyGideActions {
        margin-top: 50px;
        padding-right: 8px;
        display: flex;
        bottom: 24px;
        right: 11px;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
      }
    }
  }
  .deleteGideModal {
    position: absolute;
    top: 5px;
    right:10px;
    z-index: 2;
    .deleteGideMessage {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: center;
    }
  }

  .distributionInputBarContainer {
    display: flex;
    justify-content: center;
    align-items: center;;
    width: 100%;
    background-color: var(--WHITES-NORMAL-1000);
    .hashTag {
      margin-right: 20px;
      cursor: pointer;
      @media (max-width: 375px) {
        margin-right: 10px
      }
    }
  }
  .finishLabelContainer {
    position: absolute;
    bottom: 52px;
    right: 28px;
    .finishPointer {
      width: 274px;
      height: 26px;
      border-radius: 0 10px 0 0;
      border-top: solid 2px var(--COLOR-PRIMARY-600);
      border-right: solid 2px var(--COLOR-PRIMARY-600);
    }
    .finishPointerArrow {
      position: absolute;
      right: -4px;
      bottom: -5px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--COLOR-PRIMARY-600);
    }
    .finishLabel {
      @extend .TEXTTIblackboldhigh-emphasiscenter;
      position: absolute;
      bottom: 22px;
    }
  }
}
.distributionActionIcon {
  margin-right: 18px;
  cursor: pointer;
}
.distributionCornerActionIcon {
  margin-left: 18px;
  cursor: pointer;
}
.verticalLine {
  border: 3px;
  background-color: var(--COLOR-SECONDARY-300);
  height: 40px;
  width: 1px;
}
