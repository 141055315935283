.slideView {
  position: relative;
  .slideSelector {
    display: none;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: transparent;
    &.displaySelector {
      cursor: pointer;
      display: flex;
      flex: 1;
    }
  }
  &.inlineSlide {
    margin-bottom: 0px;
    &.settings {
      .captionAreaAttachments,
      .slideFooterActions {
        border-right: solid 3px var(--COLOR-PRIMARY-600);
      }
    }
    &.privatenotes {
      .captionAreaAttachments,
      .slideFooterActions {
        margin-left: 0px;
        border-left: solid 3px #06b3dc;
      }
      .slideEditor {
        margin-left: 0px;
      }
    }
  }
  .captionAreaAttachments {
    .inlineView {
      @extend %flexRowNormal;
      width: 100%;
      &.settings {
        justify-content: flex-end;
        .inlineViewDetails {
          justify-content: flex-end;
        }
      }
      &.privatenotes {
        justify-content: flex-start;
        .inlineViewDetails {
          justify-content: flex-start;
        }
      }
      .inlineViewDetails {
        @extend %flexRowNormal;
        width: 100%;
      }
    }
    @media (max-width: 520px) {
      .captionAreaVerticalLine {      
        width: 3px;
        height: 18px;
        background-color: var(--COLOR-SECONDARY-300);
      }
    }
  }
  .badge-block {
    height: 24px;
    width: 24px;
    &.privateNote {
      display: flex !important;
      align-items: center;
    }
  }
  .slideSelectionMode {
    width: 100%;
  }
  .button {
    @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
    justify-content: space-around;
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.55);
    margin-right: -2px;
    &.done {
      background-color: #fafafa;
      height: 32px;
      width: 82px;
      .gideTypeImage {
        height: 24px;
        width: 24px;
      }
    }
    &.commentOrQuestion {
      cursor: pointer;
      width: 225px;
      height: 24px;
    }
    &.collapse {
      background-color: none;
    }
  }
  .slideFooter {
    width: 100%;    
  }
}

.slideList.inlineSlides {
  .slideFooter.inquiryResponseViewer {
    visibility: hidden;
  }
}

.inquiryResponseInfo {
  height: 26px;
  width: 81px;
  display: flex;
  justify-content: space-around;
  .inquiryResponseAction {
    margin-right: 26px;
    cursor: pointer;
    &.hidden {
      visibility: hidden;
    }
  }
}

.commentOrQuestion {
  @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
  width: 225px;
  justify-content: space-around;
  display: flex;
  height: 24px;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  color: var(--GREYS-NORMAL-100, 0.55);
  margin-right: -2px;
}
.slideViewContainer {
  display: flex;
  position: relative;
  // &.dark {
  //   background-color: var(--GREYS-NORMAL-1000) !important;
  // }
  .slideEditor {
    display: flex;
    width: 100%;
    position: relative;
    height: 24px;
    .slideFooterActions {      
      position: absolute;
      top: 24px;
      left:0px;
      right: 0px; 
      &.expanded {
        top: 92px;
      }     
    }
  }
  .expiredSlideViewOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: white;
    width: 100%;
    &.hidden {
      max-width: 0px;
      right: auto;
      display: none; // This won't animate but leave for now.
    }
    .expired-slide {
      max-width: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      flex-direction: row;
      img,
      span {
        overflow: hidden;
      }

      &.animate {
        max-width: 100%;
        background: white;
        img {
          margin-left: 10px;
          max-width: 30px;
          object-fit: scale-down;
        }
        span {
          max-width: 100%;
        }
      }
    }
  }
  &.swipe-view {
    align-items: center;
  }
}
.swipeableContainer {
  .slideView {
    align-items: center;
  }
}
@keyframes expire-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
