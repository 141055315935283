.gideViewModal {
  display: flex !important;
  flex: 1;
  position: relative;
  margin-top: 12px;
  font-family: Arial;
  &.ui.fullscreen.modal > .header,
  &.ui.modal > .header {
    padding: 0px !important;
    display: flex !important;
  }
  .gideViewHeader {
    @extend %flexColumnBase;
    flex: none;
    align-items: center;
    position: relative;
    border-bottom: none;
    justify-content: center;
    .gideViewerContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      .inquiryResonseHeaderContainer {
        max-height: 52px;
        min-height: 52px;
        height: 52px;
        @media (max-width: 520px) {
          height: 48px;
          max-height: 48px;
          min-height: 48px;
        }
        width: 100%;
      }
    }
    .navigationBar {
      @extend %flexRowSpaceBetween;
      height: 52px;
      width: 100%;
      .completeAction {
        z-index: 1000;
        margin-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
    .ownerSlide {
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      justify-content: center;
      max-height: calc(100vh * 0.75);
      width: 100%;
      margin-bottom: 48px;
      .slideWrap {
        margin-bottom: 0px;
        max-width: 752px;
        width: 100%;
        .card {
          width: 100%;
        }
      }
    }
    .gideViewTab {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
      @media (max-width: 520px) {
        bottom: -1px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.55);
      cursor: pointer;
      z-index: 100;
      .tabText:last-child {
        @media (max-width: 472px) {
          margin-right: 85px;
        }
      }
      .tabText {
        @extend .TEXTSUBTITLEblackhigh-emphasiscenter;
        width: 108px;
        padding-bottom: 6px;
        &.selectedTab {
          color: rgba(0, 0, 0, 0.75);
          border-bottom-style: solid;
          border-bottom-width: 2px;
          border-bottom-color: #e9a634;
        }
      }
    }
  }

  .gideViewerManagerContent .articleApprovalContainer {
    margin-top: 6px;
    display: flex;
    .articleApprovalFilter {
      background-color: var(--COLOR-SECONDARY-100);
      &.selected {
        background-color: var(--WHITES-NORMAL-1000);
      }
    }
    .e-radio-wrapper {
      display: flex;
      align-items: center;
      height: 42px;
      width: 157px;
      border-radius: 5px;
      margin-left: 3px;
      .e-label {
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
        color: var(--greys-normal-100), 0.55;
      }
    }
  }

  .article-page {
    overflow-x: unset;
  }

  .content {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    background-color: var(--COLOR-SECONDARY-200) !important;
    align-items: center;
  }

  .gideViewerManagerContent.content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;
    &.hidden {
      display: none !important;
      // visibility: hidden;
      // overflow-y: hidden;
    }
  }

  .gideViewerManagerFooter.content {
    max-height: 52px;
  }

  .gideViewerManagerContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
  }

  .gideViewerManagerContent.withParent {
    .gideViewerDetails:first-child {
      margin-top: 0px;
    }
  }

  .gideViewerDetails {
    margin-top: 12px;
    max-width: 752px;
    width: 100%;
    &.view .slideList {
      background-color: var(--WHITES-NORMAL-1000);
      .slideWrap {
        margin-bottom: 0px;
      }
    }

    .inline {
      border-left: 2px solid var(--COLOR-PRIMARY-600);
      background-color: var(--WHITES-NORMAL-1000);
      .responseContainer {
        @extend %flexColumnFull;
        background-color: var(--WHITES-NORMAL-1000);
        .RESPONSE {
          border-left: 2px solid var(--COLOR-PRIMARY-600);
          margin-left: 6px;
          width: calc(100% - 6px) !important;
        }
      }
    }
    .slideViewContainer.card {
      width: 100% !important;
    }
  }

  .button {
    @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
    background-color: #fafafa;
    justify-content: space-around;
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45);
    margin-right: -2px;
    &.done {
      height: 32px;
      width: 82px;
      .gideTypeImage {
        height: 24px;
        width: 24px;
      }
    }
    &.commentOrQuestion {
      width: 225px;
      height: 24px;
    }
  }

  &.ui.fullscreen.modal {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    width: 100% !important;
    position: fixed;
    top: -15px;
    bottom: -12px;
    left: 0px;
    right: 0px;
    height: auto !important;
    &.collapsed {
      top: calc(100vh * 0.5);
      .gideViewerDetails {
        margin-top: 12px;
        min-height: calc(100vh - (100vh * 0.5 + 115px)) !important;
      }
    }
  }

  &.ui.modal .gideViewerDetails {
    display: flex;
    flex-direction: column;
    // max-height: calc(100vh - 100px) !important;
  }

  .slideTypeIndicator {
    @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
    color: rgba(0, 0, 0, 0.55);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 128px;
    height: 32px;
    padding-left: 7px;
    z-index: 1000;
    .slideTypeImage {
      height: 24px;
      width: 24px;
      &.sortFilter {
        cursor: pointer;
      }
    }
  }

  .separatorBar {
    width: 2px;
    height: 32px;
    border-left: 2px solid rgba(0, 0, 0, 0.55);
  }
}
