@import '../../../../typography';
.radioButtonContainer {
  height: 42px;
  border-radius: 4px;
  background-color: var(--COLOR-SECONDARY-200);
  :global(.e-radio-wrapper) {
    display: flex;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    margin-left: 6px;
    :global(.e-label) {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      margin-left: -3px;
    }
    :global(.e-btn) {
      padding: 4px;
    }
    :global(.e-radio:checked+label:after) {
      background: rgb(255, 178, 19) !important;
      color: yellow !important;
    }
    :global(.e-radio:checked+label:before) {
      border-color: rgb(255, 178, 19) !important;
    }
  }
  &.selected {
    background-color: var(--WHITES-NORMAL-1000);
  }
}
