@media (max-width: 5000px) {
    .truncate {
        width: 4530px;
    }
}

@media (max-width: 4990px) {
    .truncate {
        width: 4520px;
    }
}

@media (max-width: 4980px) {
    .truncate {
        width: 4510px;
    }
}

@media (max-width: 4970px) {
    .truncate {
        width: 4500px;
    }
}

@media (max-width: 4960px) {
    .truncate {
        width: 4490px;
    }
}

@media (max-width: 4950px) {
    .truncate {
        width: 4480px;
    }
}

@media (max-width: 4940px) {
    .truncate {
        width: 4470px;
    }
}

@media (max-width: 4930px) {
    .truncate {
        width: 4460px;
    }
}

@media (max-width: 4920px) {
    .truncate {
        width: 4450px;
    }
}

@media (max-width: 4910px) {
    .truncate {
        width: 4440px;
    }
}

@media (max-width: 4900px) {
    .truncate {
        width: 4430px;
    }
}

@media (max-width: 4890px) {
    .truncate {
        width: 4420px;
    }
}

@media (max-width: 4880px) {
    .truncate {
        width: 4410px;
    }
}

@media (max-width: 4870px) {
    .truncate {
        width: 4400px;
    }
}

@media (max-width: 4860px) {
    .truncate {
        width: 4390px;
    }
}

@media (max-width: 4850px) {
    .truncate {
        width: 4380px;
    }
}

@media (max-width: 4840px) {
    .truncate {
        width: 4370px;
    }
}

@media (max-width: 4830px) {
    .truncate {
        width: 4360px;
    }
}

@media (max-width: 4820px) {
    .truncate {
        width: 4350px;
    }
}

@media (max-width: 4810px) {
    .truncate {
        width: 4340px;
    }
}

@media (max-width: 4800px) {
    .truncate {
        width: 4330px;
    }
}

@media (max-width: 4790px) {
    .truncate {
        width: 4320px;
    }
}

@media (max-width: 4780px) {
    .truncate {
        width: 4310px;
    }
}

@media (max-width: 4770px) {
    .truncate {
        width: 4300px;
    }
}

@media (max-width: 4760px) {
    .truncate {
        width: 4290px;
    }
}

@media (max-width: 4750px) {
    .truncate {
        width: 4280px;
    }
}

@media (max-width: 4740px) {
    .truncate {
        width: 4270px;
    }
}

@media (max-width: 4730px) {
    .truncate {
        width: 4260px;
    }
}

@media (max-width: 4720px) {
    .truncate {
        width: 4250px;
    }
}

@media (max-width: 4710px) {
    .truncate {
        width: 4240px;
    }
}

@media (max-width: 4700px) {
    .truncate {
        width: 4230px;
    }
}

@media (max-width: 4690px) {
    .truncate {
        width: 4220px;
    }
}

@media (max-width: 4680px) {
    .truncate {
        width: 4210px;
    }
}

@media (max-width: 4670px) {
    .truncate {
        width: 4200px;
    }
}

@media (max-width: 4660px) {
    .truncate {
        width: 4190px;
    }
}

@media (max-width: 4650px) {
    .truncate {
        width: 4180px;
    }
}

@media (max-width: 4640px) {
    .truncate {
        width: 4170px;
    }
}

@media (max-width: 4630px) {
    .truncate {
        width: 4160px;
    }
}

@media (max-width: 4620px) {
    .truncate {
        width: 4150px;
    }
}

@media (max-width: 4610px) {
    .truncate {
        width: 4140px;
    }
}

@media (max-width: 4600px) {
    .truncate {
        width: 4130px;
    }
}

@media (max-width: 4590px) {
    .truncate {
        width: 4120px;
    }
}

@media (max-width: 4580px) {
    .truncate {
        width: 4110px;
    }
}

@media (max-width: 4570px) {
    .truncate {
        width: 4100px;
    }
}

@media (max-width: 4560px) {
    .truncate {
        width: 4090px;
    }
}

@media (max-width: 4550px) {
    .truncate {
        width: 4080px;
    }
}

@media (max-width: 4540px) {
    .truncate {
        width: 4070px;
    }
}

@media (max-width: 4530px) {
    .truncate {
        width: 4060px;
    }
}

@media (max-width: 4520px) {
    .truncate {
        width: 4050px;
    }
}

@media (max-width: 4510px) {
    .truncate {
        width: 4040px;
    }
}

@media (max-width: 4500px) {
    .truncate {
        width: 4030px;
    }
}

@media (max-width: 4490px) {
    .truncate {
        width: 4020px;
    }
}

@media (max-width: 4480px) {
    .truncate {
        width: 4010px;
    }
}

@media (max-width: 4470px) {
    .truncate {
        width: 4000px;
    }
}

@media (max-width: 4460px) {
    .truncate {
        width: 3990px;
    }
}

@media (max-width: 4450px) {
    .truncate {
        width: 3980px;
    }
}

@media (max-width: 4440px) {
    .truncate {
        width: 3970px;
    }
}

@media (max-width: 4430px) {
    .truncate {
        width: 3960px;
    }
}

@media (max-width: 4420px) {
    .truncate {
        width: 3950px;
    }
}

@media (max-width: 4410px) {
    .truncate {
        width: 3940px;
    }
}

@media (max-width: 4400px) {
    .truncate {
        width: 3930px;
    }
}

@media (max-width: 4390px) {
    .truncate {
        width: 3920px;
    }
}

@media (max-width: 4380px) {
    .truncate {
        width: 3910px;
    }
}

@media (max-width: 4370px) {
    .truncate {
        width: 3900px;
    }
}

@media (max-width: 4360px) {
    .truncate {
        width: 3890px;
    }
}

@media (max-width: 4350px) {
    .truncate {
        width: 3880px;
    }
}

@media (max-width: 4340px) {
    .truncate {
        width: 3870px;
    }
}

@media (max-width: 4330px) {
    .truncate {
        width: 3860px;
    }
}

@media (max-width: 4320px) {
    .truncate {
        width: 3850px;
    }
}

@media (max-width: 4310px) {
    .truncate {
        width: 3840px;
    }
}

@media (max-width: 4300px) {
    .truncate {
        width: 3830px;
    }
}

@media (max-width: 4290px) {
    .truncate {
        width: 3820px;
    }
}

@media (max-width: 4280px) {
    .truncate {
        width: 3810px;
    }
}

@media (max-width: 4270px) {
    .truncate {
        width: 3800px;
    }
}

@media (max-width: 4260px) {
    .truncate {
        width: 3790px;
    }
}

@media (max-width: 4250px) {
    .truncate {
        width: 3780px;
    }
}

@media (max-width: 4240px) {
    .truncate {
        width: 3770px;
    }
}

@media (max-width: 4230px) {
    .truncate {
        width: 3760px;
    }
}

@media (max-width: 4220px) {
    .truncate {
        width: 3750px;
    }
}

@media (max-width: 4210px) {
    .truncate {
        width: 3740px;
    }
}

@media (max-width: 4200px) {
    .truncate {
        width: 3730px;
    }
}

@media (max-width: 4190px) {
    .truncate {
        width: 3720px;
    }
}

@media (max-width: 4180px) {
    .truncate {
        width: 3710px;
    }
}

@media (max-width: 4170px) {
    .truncate {
        width: 3700px;
    }
}

@media (max-width: 4160px) {
    .truncate {
        width: 3690px;
    }
}

@media (max-width: 4150px) {
    .truncate {
        width: 3680px;
    }
}

@media (max-width: 4140px) {
    .truncate {
        width: 3670px;
    }
}

@media (max-width: 4130px) {
    .truncate {
        width: 3660px;
    }
}

@media (max-width: 4120px) {
    .truncate {
        width: 3650px;
    }
}

@media (max-width: 4110px) {
    .truncate {
        width: 3640px;
    }
}

@media (max-width: 4100px) {
    .truncate {
        width: 3630px;
    }
}

@media (max-width: 4090px) {
    .truncate {
        width: 3620px;
    }
}

@media (max-width: 4080px) {
    .truncate {
        width: 3610px;
    }
}

@media (max-width: 4070px) {
    .truncate {
        width: 3600px;
    }
}

@media (max-width: 4060px) {
    .truncate {
        width: 3590px;
    }
}

@media (max-width: 4050px) {
    .truncate {
        width: 3580px;
    }
}

@media (max-width: 4040px) {
    .truncate {
        width: 3570px;
    }
}

@media (max-width: 4030px) {
    .truncate {
        width: 3560px;
    }
}

@media (max-width: 4020px) {
    .truncate {
        width: 3550px;
    }
}

@media (max-width: 4010px) {
    .truncate {
        width: 3540px;
    }
}

@media (max-width: 4000px) {
    .truncate {
        width: 3530px;
    }
}

@media (max-width: 3990px) {
    .truncate {
        width: 3520px;
    }
}

@media (max-width: 3980px) {
    .truncate {
        width: 3510px;
    }
}

@media (max-width: 3970px) {
    .truncate {
        width: 3500px;
    }
}

@media (max-width: 3960px) {
    .truncate {
        width: 3490px;
    }
}

@media (max-width: 3950px) {
    .truncate {
        width: 3480px;
    }
}

@media (max-width: 3940px) {
    .truncate {
        width: 3470px;
    }
}

@media (max-width: 3930px) {
    .truncate {
        width: 3460px;
    }
}

@media (max-width: 3920px) {
    .truncate {
        width: 3450px;
    }
}

@media (max-width: 3910px) {
    .truncate {
        width: 3440px;
    }
}

@media (max-width: 3900px) {
    .truncate {
        width: 3430px;
    }
}

@media (max-width: 3890px) {
    .truncate {
        width: 3420px;
    }
}

@media (max-width: 3880px) {
    .truncate {
        width: 3410px;
    }
}

@media (max-width: 3870px) {
    .truncate {
        width: 3400px;
    }
}

@media (max-width: 3860px) {
    .truncate {
        width: 3390px;
    }
}

@media (max-width: 3850px) {
    .truncate {
        width: 3380px;
    }
}

@media (max-width: 3840px) {
    .truncate {
        width: 3370px;
    }
}

@media (max-width: 3830px) {
    .truncate {
        width: 3360px;
    }
}

@media (max-width: 3820px) {
    .truncate {
        width: 3350px;
    }
}

@media (max-width: 3810px) {
    .truncate {
        width: 3340px;
    }
}

@media (max-width: 3800px) {
    .truncate {
        width: 3330px;
    }
}

@media (max-width: 3790px) {
    .truncate {
        width: 3320px;
    }
}

@media (max-width: 3780px) {
    .truncate {
        width: 3310px;
    }
}

@media (max-width: 3770px) {
    .truncate {
        width: 3300px;
    }
}

@media (max-width: 3760px) {
    .truncate {
        width: 3290px;
    }
}

@media (max-width: 3750px) {
    .truncate {
        width: 3280px;
    }
}

@media (max-width: 3740px) {
    .truncate {
        width: 3270px;
    }
}

@media (max-width: 3730px) {
    .truncate {
        width: 3260px;
    }
}

@media (max-width: 3720px) {
    .truncate {
        width: 3250px;
    }
}

@media (max-width: 3710px) {
    .truncate {
        width: 3240px;
    }
}

@media (max-width: 3700px) {
    .truncate {
        width: 3230px;
    }
}

@media (max-width: 3690px) {
    .truncate {
        width: 3220px;
    }
}

@media (max-width: 3680px) {
    .truncate {
        width: 3210px;
    }
}

@media (max-width: 3670px) {
    .truncate {
        width: 3200px;
    }
}

@media (max-width: 3660px) {
    .truncate {
        width: 3190px;
    }
}

@media (max-width: 3650px) {
    .truncate {
        width: 3180px;
    }
}

@media (max-width: 3640px) {
    .truncate {
        width: 3170px;
    }
}

@media (max-width: 3630px) {
    .truncate {
        width: 3160px;
    }
}

@media (max-width: 3620px) {
    .truncate {
        width: 3150px;
    }
}

@media (max-width: 3610px) {
    .truncate {
        width: 3140px;
    }
}

@media (max-width: 3600px) {
    .truncate {
        width: 3130px;
    }
}

@media (max-width: 3590px) {
    .truncate {
        width: 3120px;
    }
}

@media (max-width: 3580px) {
    .truncate {
        width: 3110px;
    }
}

@media (max-width: 3570px) {
    .truncate {
        width: 3100px;
    }
}

@media (max-width: 3560px) {
    .truncate {
        width: 3090px;
    }
}

@media (max-width: 3550px) {
    .truncate {
        width: 3080px;
    }
}

@media (max-width: 3540px) {
    .truncate {
        width: 3070px;
    }
}

@media (max-width: 3530px) {
    .truncate {
        width: 3060px;
    }
}

@media (max-width: 3520px) {
    .truncate {
        width: 3050px;
    }
}

@media (max-width: 3510px) {
    .truncate {
        width: 3040px;
    }
}

@media (max-width: 3500px) {
    .truncate {
        width: 3030px;
    }
}

@media (max-width: 3490px) {
    .truncate {
        width: 3020px;
    }
}

@media (max-width: 3480px) {
    .truncate {
        width: 3010px;
    }
}

@media (max-width: 3470px) {
    .truncate {
        width: 3000px;
    }
}

@media (max-width: 3460px) {
    .truncate {
        width: 2990px;
    }
}

@media (max-width: 3450px) {
    .truncate {
        width: 2980px;
    }
}

@media (max-width: 3440px) {
    .truncate {
        width: 2970px;
    }
}

@media (max-width: 3430px) {
    .truncate {
        width: 2960px;
    }
}

@media (max-width: 3420px) {
    .truncate {
        width: 2950px;
    }
}

@media (max-width: 3410px) {
    .truncate {
        width: 2940px;
    }
}

@media (max-width: 3400px) {
    .truncate {
        width: 2930px;
    }
}

@media (max-width: 3390px) {
    .truncate {
        width: 2920px;
    }
}

@media (max-width: 3380px) {
    .truncate {
        width: 2910px;
    }
}

@media (max-width: 3370px) {
    .truncate {
        width: 2900px;
    }
}

@media (max-width: 3360px) {
    .truncate {
        width: 2890px;
    }
}

@media (max-width: 3350px) {
    .truncate {
        width: 2880px;
    }
}

@media (max-width: 3340px) {
    .truncate {
        width: 2870px;
    }
}

@media (max-width: 3330px) {
    .truncate {
        width: 2860px;
    }
}

@media (max-width: 3320px) {
    .truncate {
        width: 2850px;
    }
}

@media (max-width: 3310px) {
    .truncate {
        width: 2840px;
    }
}

@media (max-width: 3300px) {
    .truncate {
        width: 2830px;
    }
}

@media (max-width: 3290px) {
    .truncate {
        width: 2820px;
    }
}

@media (max-width: 3280px) {
    .truncate {
        width: 2810px;
    }
}

@media (max-width: 3270px) {
    .truncate {
        width: 2800px;
    }
}

@media (max-width: 3260px) {
    .truncate {
        width: 2790px;
    }
}

@media (max-width: 3250px) {
    .truncate {
        width: 2780px;
    }
}

@media (max-width: 3240px) {
    .truncate {
        width: 2770px;
    }
}

@media (max-width: 3230px) {
    .truncate {
        width: 2760px;
    }
}

@media (max-width: 3220px) {
    .truncate {
        width: 2750px;
    }
}

@media (max-width: 3210px) {
    .truncate {
        width: 2740px;
    }
}

@media (max-width: 3200px) {
    .truncate {
        width: 2730px;
    }
}

@media (max-width: 3190px) {
    .truncate {
        width: 2720px;
    }
}

@media (max-width: 3180px) {
    .truncate {
        width: 2710px;
    }
}

@media (max-width: 3170px) {
    .truncate {
        width: 2700px;
    }
}

@media (max-width: 3160px) {
    .truncate {
        width: 2690px;
    }
}

@media (max-width: 3150px) {
    .truncate {
        width: 2680px;
    }
}

@media (max-width: 3140px) {
    .truncate {
        width: 2670px;
    }
}

@media (max-width: 3130px) {
    .truncate {
        width: 2660px;
    }
}

@media (max-width: 3120px) {
    .truncate {
        width: 2650px;
    }
}

@media (max-width: 3110px) {
    .truncate {
        width: 2640px;
    }
}

@media (max-width: 3100px) {
    .truncate {
        width: 2630px;
    }
}

@media (max-width: 3090px) {
    .truncate {
        width: 2620px;
    }
}

@media (max-width: 3080px) {
    .truncate {
        width: 2610px;
    }
}

@media (max-width: 3070px) {
    .truncate {
        width: 2600px;
    }
}

@media (max-width: 3060px) {
    .truncate {
        width: 2590px;
    }
}

@media (max-width: 3050px) {
    .truncate {
        width: 2580px;
    }
}

@media (max-width: 3040px) {
    .truncate {
        width: 2570px;
    }
}

@media (max-width: 3030px) {
    .truncate {
        width: 2560px;
    }
}

@media (max-width: 3020px) {
    .truncate {
        width: 2550px;
    }
}

@media (max-width: 3010px) {
    .truncate {
        width: 2540px;
    }
}

@media (max-width: 3000px) {
    .truncate {
        width: 2530px;
    }
}

@media (max-width: 2990px) {
    .truncate {
        width: 2520px;
    }
}

@media (max-width: 2980px) {
    .truncate {
        width: 2510px;
    }
}

@media (max-width: 2970px) {
    .truncate {
        width: 2500px;
    }
}

@media (max-width: 2960px) {
    .truncate {
        width: 2490px;
    }
}

@media (max-width: 2950px) {
    .truncate {
        width: 2480px;
    }
}

@media (max-width: 2940px) {
    .truncate {
        width: 2470px;
    }
}

@media (max-width: 2930px) {
    .truncate {
        width: 2460px;
    }
}

@media (max-width: 2920px) {
    .truncate {
        width: 2450px;
    }
}

@media (max-width: 2910px) {
    .truncate {
        width: 2440px;
    }
}

@media (max-width: 2900px) {
    .truncate {
        width: 2430px;
    }
}

@media (max-width: 2890px) {
    .truncate {
        width: 2420px;
    }
}

@media (max-width: 2880px) {
    .truncate {
        width: 2410px;
    }
}

@media (max-width: 2870px) {
    .truncate {
        width: 2400px;
    }
}

@media (max-width: 2860px) {
    .truncate {
        width: 2390px;
    }
}

@media (max-width: 2850px) {
    .truncate {
        width: 2380px;
    }
}

@media (max-width: 2840px) {
    .truncate {
        width: 2370px;
    }
}

@media (max-width: 2830px) {
    .truncate {
        width: 2360px;
    }
}

@media (max-width: 2820px) {
    .truncate {
        width: 2350px;
    }
}

@media (max-width: 2810px) {
    .truncate {
        width: 2340px;
    }
}

@media (max-width: 2800px) {
    .truncate {
        width: 2330px;
    }
}

@media (max-width: 2790px) {
    .truncate {
        width: 2320px;
    }
}

@media (max-width: 2780px) {
    .truncate {
        width: 2310px;
    }
}

@media (max-width: 2770px) {
    .truncate {
        width: 2300px;
    }
}

@media (max-width: 2760px) {
    .truncate {
        width: 2290px;
    }
}

@media (max-width: 2750px) {
    .truncate {
        width: 2280px;
    }
}

@media (max-width: 2740px) {
    .truncate {
        width: 2270px;
    }
}

@media (max-width: 2730px) {
    .truncate {
        width: 2260px;
    }
}

@media (max-width: 2720px) {
    .truncate {
        width: 2250px;
    }
}

@media (max-width: 2710px) {
    .truncate {
        width: 2240px;
    }
}

@media (max-width: 2700px) {
    .truncate {
        width: 2230px;
    }
}

@media (max-width: 2690px) {
    .truncate {
        width: 2220px;
    }
}

@media (max-width: 2680px) {
    .truncate {
        width: 2210px;
    }
}

@media (max-width: 2670px) {
    .truncate {
        width: 2200px;
    }
}

@media (max-width: 2660px) {
    .truncate {
        width: 2190px;
    }
}

@media (max-width: 2650px) {
    .truncate {
        width: 2180px;
    }
}

@media (max-width: 2640px) {
    .truncate {
        width: 2170px;
    }
}

@media (max-width: 2630px) {
    .truncate {
        width: 2160px;
    }
}

@media (max-width: 2620px) {
    .truncate {
        width: 2150px;
    }
}

@media (max-width: 2610px) {
    .truncate {
        width: 2140px;
    }
}

@media (max-width: 2600px) {
    .truncate {
        width: 2130px;
    }
}

@media (max-width: 2590px) {
    .truncate {
        width: 2120px;
    }
}

@media (max-width: 2580px) {
    .truncate {
        width: 2110px;
    }
}

@media (max-width: 2570px) {
    .truncate {
        width: 2100px;
    }
}

@media (max-width: 2560px) {
    .truncate {
        width: 2090px;
    }
}

@media (max-width: 2550px) {
    .truncate {
        width: 2080px;
    }
}

@media (max-width: 2540px) {
    .truncate {
        width: 2070px;
    }
}

@media (max-width: 2530px) {
    .truncate {
        width: 2060px;
    }
}

@media (max-width: 2520px) {
    .truncate {
        width: 2050px;
    }
}

@media (max-width: 2510px) {
    .truncate {
        width: 2040px;
    }
}

@media (max-width: 2500px) {
    .truncate {
        width: 2030px;
    }
}

@media (max-width: 2490px) {
    .truncate {
        width: 2020px;
    }
}

@media (max-width: 2480px) {
    .truncate {
        width: 2010px;
    }
}

@media (max-width: 2470px) {
    .truncate {
        width: 2000px;
    }
}

@media (max-width: 2460px) {
    .truncate {
        width: 1990px;
    }
}

@media (max-width: 2450px) {
    .truncate {
        width: 1980px;
    }
}

@media (max-width: 2440px) {
    .truncate {
        width: 1970px;
    }
}

@media (max-width: 2430px) {
    .truncate {
        width: 1960px;
    }
}

@media (max-width: 2420px) {
    .truncate {
        width: 1950px;
    }
}

@media (max-width: 2410px) {
    .truncate {
        width: 1940px;
    }
}

@media (max-width: 2400px) {
    .truncate {
        width: 1930px;
    }
}

@media (max-width: 2390px) {
    .truncate {
        width: 1920px;
    }
}

@media (max-width: 2380px) {
    .truncate {
        width: 1910px;
    }
}

@media (max-width: 2370px) {
    .truncate {
        width: 1900px;
    }
}

@media (max-width: 2360px) {
    .truncate {
        width: 1890px;
    }
}

@media (max-width: 2350px) {
    .truncate {
        width: 1880px;
    }
}

@media (max-width: 2340px) {
    .truncate {
        width: 1870px;
    }
}

@media (max-width: 2330px) {
    .truncate {
        width: 1860px;
    }
}

@media (max-width: 2320px) {
    .truncate {
        width: 1850px;
    }
}

@media (max-width: 2310px) {
    .truncate {
        width: 1840px;
    }
}

@media (max-width: 2300px) {
    .truncate {
        width: 1830px;
    }
}

@media (max-width: 2290px) {
    .truncate {
        width: 1820px;
    }
}

@media (max-width: 2280px) {
    .truncate {
        width: 1810px;
    }
}

@media (max-width: 2270px) {
    .truncate {
        width: 1800px;
    }
}

@media (max-width: 2260px) {
    .truncate {
        width: 1790px;
    }
}

@media (max-width: 2250px) {
    .truncate {
        width: 1780px;
    }
}

@media (max-width: 2240px) {
    .truncate {
        width: 1770px;
    }
}

@media (max-width: 2230px) {
    .truncate {
        width: 1760px;
    }
}

@media (max-width: 2220px) {
    .truncate {
        width: 1750px;
    }
}

@media (max-width: 2210px) {
    .truncate {
        width: 1740px;
    }
}

@media (max-width: 2200px) {
    .truncate {
        width: 1730px;
    }
}

@media (max-width: 2190px) {
    .truncate {
        width: 1720px;
    }
}

@media (max-width: 2180px) {
    .truncate {
        width: 1710px;
    }
}

@media (max-width: 2170px) {
    .truncate {
        width: 1700px;
    }
}

@media (max-width: 2160px) {
    .truncate {
        width: 1690px;
    }
}

@media (max-width: 2150px) {
    .truncate {
        width: 1680px;
    }
}

@media (max-width: 2140px) {
    .truncate {
        width: 1670px;
    }
}

@media (max-width: 2130px) {
    .truncate {
        width: 1660px;
    }
}

@media (max-width: 2120px) {
    .truncate {
        width: 1650px;
    }
}

@media (max-width: 2110px) {
    .truncate {
        width: 1640px;
    }
}

@media (max-width: 2100px) {
    .truncate {
        width: 1630px;
    }
}

@media (max-width: 2090px) {
    .truncate {
        width: 1620px;
    }
}

@media (max-width: 2080px) {
    .truncate {
        width: 1610px;
    }
}

@media (max-width: 2070px) {
    .truncate {
        width: 1600px;
    }
}

@media (max-width: 2060px) {
    .truncate {
        width: 1590px;
    }
}

@media (max-width: 2050px) {
    .truncate {
        width: 1580px;
    }
}

@media (max-width: 2040px) {
    .truncate {
        width: 1570px;
    }
}

@media (max-width: 2030px) {
    .truncate {
        width: 1560px;
    }
}

@media (max-width: 2020px) {
    .truncate {
        width: 1550px;
    }
}

@media (max-width: 2010px) {
    .truncate {
        width: 1540px;
    }
}

@media (max-width: 2000px) {
    .truncate {
        width: 1530px;
    }
}

@media (max-width: 1990px) {
    .truncate {
        width: 1520px;
    }
}

@media (max-width: 1980px) {
    .truncate {
        width: 1510px;
    }
}

@media (max-width: 1970px) {
    .truncate {
        width: 1500px;
    }
}

@media (max-width: 1960px) {
    .truncate {
        width: 1490px;
    }
}

@media (max-width: 1950px) {
    .truncate {
        width: 1480px;
    }
}

@media (max-width: 1940px) {
    .truncate {
        width: 1470px;
    }
}

@media (max-width: 1930px) {
    .truncate {
        width: 1460px;
    }
}

@media (max-width: 1920px) {
    .truncate {
        width: 1450px;
    }
}

@media (max-width: 1910px) {
    .truncate {
        width: 1440px;
    }
}

@media (max-width: 1900px) {
    .truncate {
        width: 1430px;
    }
}

@media (max-width: 1890px) {
    .truncate {
        width: 1420px;
    }
}

@media (max-width: 1880px) {
    .truncate {
        width: 1410px;
    }
}

@media (max-width: 1870px) {
    .truncate {
        width: 1400px;
    }
}

@media (max-width: 1860px) {
    .truncate {
        width: 1390px;
    }
}

@media (max-width: 1850px) {
    .truncate {
        width: 1380px;
    }
}

@media (max-width: 1840px) {
    .truncate {
        width: 1370px;
    }
}

@media (max-width: 1830px) {
    .truncate {
        width: 1360px;
    }
}

@media (max-width: 1820px) {
    .truncate {
        width: 1350px;
    }
}

@media (max-width: 1810px) {
    .truncate {
        width: 1340px;
    }
}

@media (max-width: 1800px) {
    .truncate {
        width: 1330px;
    }
}

@media (max-width: 1790px) {
    .truncate {
        width: 1320px;
    }
}

@media (max-width: 1780px) {
    .truncate {
        width: 1310px;
    }
}

@media (max-width: 1770px) {
    .truncate {
        width: 1300px;
    }
}

@media (max-width: 1760px) {
    .truncate {
        width: 1290px;
    }
}

@media (max-width: 1750px) {
    .truncate {
        width: 1280px;
    }
}

@media (max-width: 1740px) {
    .truncate {
        width: 1270px;
    }
}

@media (max-width: 1730px) {
    .truncate {
        width: 1260px;
    }
}

@media (max-width: 1720px) {
    .truncate {
        width: 1250px;
    }
}

@media (max-width: 1710px) {
    .truncate {
        width: 1240px;
    }
}

@media (max-width: 1700px) {
    .truncate {
        width: 1230px;
    }
}

@media (max-width: 1690px) {
    .truncate {
        width: 1220px;
    }
}

@media (max-width: 1680px) {
    .truncate {
        width: 1210px;
    }
}

@media (max-width: 1670px) {
    .truncate {
        width: 1200px;
    }
}

@media (max-width: 1660px) {
    .truncate {
        width: 1190px;
    }
}

@media (max-width: 1650px) {
    .truncate {
        width: 1180px;
    }
}

@media (max-width: 1640px) {
    .truncate {
        width: 1170px;
    }
}

@media (max-width: 1630px) {
    .truncate {
        width: 1160px;
    }
}

@media (max-width: 1620px) {
    .truncate {
        width: 1150px;
    }
}

@media (max-width: 1610px) {
    .truncate {
        width: 1140px;
    }
}

@media (max-width: 1600px) {
    .truncate {
        width: 1130px;
    }
}

@media (max-width: 1590px) {
    .truncate {
        width: 1120px;
    }
}

@media (max-width: 1580px) {
    .truncate {
        width: 1110px;
    }
}

@media (max-width: 1570px) {
    .truncate {
        width: 1100px;
    }
}

@media (max-width: 1560px) {
    .truncate {
        width: 1090px;
    }
}

@media (max-width: 1550px) {
    .truncate {
        width: 1080px;
    }
}

@media (max-width: 1540px) {
    .truncate {
        width: 1070px;
    }
}

@media (max-width: 1530px) {
    .truncate {
        width: 1060px;
    }
}

@media (max-width: 1520px) {
    .truncate {
        width: 1050px;
    }
}

@media (max-width: 1510px) {
    .truncate {
        width: 1040px;
    }
}

@media (max-width: 1500px) {
    .truncate {
        width: 1030px;
    }
}

@media (max-width: 1490px) {
    .truncate {
        width: 1020px;
    }
}

@media (max-width: 1480px) {
    .truncate {
        width: 1010px;
    }
}

@media (max-width: 1470px) {
    .truncate {
        width: 1000px;
    }
}

@media (max-width: 1460px) {
    .truncate {
        width: 990px;
    }
}

@media (max-width: 1450px) {
    .truncate {
        width: 980px;
    }
}

@media (max-width: 1440px) {
    .truncate {
        width: 970px;
    }
}

@media (max-width: 1430px) {
    .truncate {
        width: 960px;
    }
}

@media (max-width: 1420px) {
    .truncate {
        width: 950px;
    }
}

@media (max-width: 1410px) {
    .truncate {
        width: 940px;
    }
}

@media (max-width: 1400px) {
    .truncate {
        width: 930px;
    }
}

@media (max-width: 1390px) {
    .truncate {
        width: 920px;
    }
}

@media (max-width: 1380px) {
    .truncate {
        width: 910px;
    }
}

@media (max-width: 1370px) {
    .truncate {
        width: 900px;
    }
}

@media (max-width: 1360px) {
    .truncate {
        width: 890px;
    }
}

@media (max-width: 1350px) {
    .truncate {
        width: 880px;
    }
}

@media (max-width: 1340px) {
    .truncate {
        width: 870px;
    }
}

@media (max-width: 1330px) {
    .truncate {
        width: 860px;
    }
}

@media (max-width: 1320px) {
    .truncate {
        width: 850px;
    }
}

@media (max-width: 1310px) {
    .truncate {
        width: 840px;
    }
}

@media (max-width: 1300px) {
    .truncate {
        width: 830px;
    }
}

@media (max-width: 1290px) {
    .truncate {
        width: 820px;
    }
}

@media (max-width: 1280px) {
    .truncate {
        width: 810px;
    }
}

@media (max-width: 1270px) {
    .truncate {
        width: 800px;
    }
}

@media (max-width: 1260px) {
    .truncate {
        width: 790px;
    }
}

@media (max-width: 1250px) {
    .truncate {
        width: 780px;
    }
}

@media (max-width: 1240px) {
    .truncate {
        width: 770px;
    }
}

@media (max-width: 1230px) {
    .truncate {
        width: 760px;
    }
}

@media (max-width: 1220px) {
    .truncate {
        width: 750px;
    }
}

@media (max-width: 1210px) {
    .truncate {
        width: 740px;
    }
}

@media (max-width: 1200px) {
    .truncate {
        width: 730px;
    }
}

@media (max-width: 1190px) {
    .truncate {
        width: 720px;
    }
}

@media (max-width: 1180px) {
    .truncate {
        width: 710px;
    }
}

@media (max-width: 1170px) {
    .truncate {
        width: 700px;
    }
}

@media (max-width: 1160px) {
    .truncate {
        width: 690px;
    }
}

@media (max-width: 1150px) {
    .truncate {
        width: 680px;
    }
}

@media (max-width: 1140px) {
    .truncate {
        width: 670px;
    }
}

@media (max-width: 1130px) {
    .truncate {
        width: 660px;
    }
}

@media (max-width: 1120px) {
    .truncate {
        width: 650px;
    }
}

@media (max-width: 1110px) {
    .truncate {
        width: 640px;
    }
}

@media (max-width: 1100px) {
    .truncate {
        width: 630px;
    }
}

@media (max-width: 1090px) {
    .truncate {
        width: 620px;
    }
}

@media (max-width: 1080px) {
    .truncate {
        width: 610px;
    }
}

@media (max-width: 1070px) {
    .truncate {
        width: 600px;
    }
}

@media (max-width: 1060px) {
    .truncate {
        width: 590px;
    }
}

@media (max-width: 1050px) {
    .truncate {
        width: 580px;
    }
}

@media (max-width: 1040px) {
    .truncate {
        width: 570px;
    }
}

@media (max-width: 1030px) {
    .truncate {
        width: 560px;
    }
}

@media (max-width: 1020px) {
    .truncate {
        width: 550px;
    }
}

@media (max-width: 1010px) {
    .truncate {
        width: 540px;
    }
}

@media (max-width: 1000px) {
    .truncate {
        width: 530px;
    }
}

@media (max-width: 990px) {
    .truncate {
        width: 520px;
    }
}

@media (max-width: 980px) {
    .truncate {
        width: 510px;
    }
}

@media (max-width: 970px) {
    .truncate {
        width: 500px;
    }
}

@media (max-width: 960px) {
    .truncate {
        width: 490px;
    }
}

@media (max-width: 950px) {
    .truncate {
        width: 480px;
    }
}

@media (max-width: 940px) {
    .truncate {
        width: 470px;
    }
}

@media (max-width: 930px) {
    .truncate {
        width: 460px;
    }
}

@media (max-width: 920px) {
    .truncate {
        width: 450px;
    }
}

@media (max-width: 910px) {
    .truncate {
        width: 440px;
    }
}

@media (max-width: 900px) {
    .truncate {
        width: 430px;
    }
}

@media (max-width: 890px) {
    .truncate {
        width: 420px;
    }
}

@media (max-width: 880px) {
    .truncate {
        width: 410px;
    }
}

@media (max-width: 870px) {
    .truncate {
        width: 400px;
    }
}

@media (max-width: 860px) {
    .truncate {
        width: 390px;
    }
}

@media (max-width: 850px) {
    .truncate {
        width: 380px;
    }
}

@media (max-width: 840px) {
    .truncate {
        width: 370px;
    }
}

@media (max-width: 830px) {
    .truncate {
        width: 360px;
    }
}

@media (max-width: 820px) {
    .truncate {
        width: 350px;
    }
}

@media (max-width: 810px) {
    .truncate {
        width: 340px;
    }
}

@media (max-width: 800px) {
    .truncate {
        width: 330px;
    }
}

@media (max-width: 790px) {
    .truncate {
        width: 320px;
    }
}

@media (max-width: 780px) {
    .truncate {
        width: 310px;
    }
}
@media (max-width: 770px) {
    .truncate {
        width: 300px;
    }
}