.viewbarContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // position: fixed;
  bottom: 0px; // 12px;
  z-index: 2;  
  width: 100vw;
  // max-height: calc(100vh - 200px);
  pointer-events: none;
  @media (max-width: 520px) {
    bottom: 2px;
  }
  &.inputBar {
    bottom: 68px;
    @media (max-width: 520px) {
      bottom: 56px;
    }
  }
}
.viewbarExpander {
  height: 28px;
  width: 112px;
  &:hover {
    cursor: pointer;
  }
  .viewbarExpanderHandle {
    width: 56px;
    height: 8.6px;
    // box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-200),
    //   0 6px 10px -2px var(--GREYS-NORMAL-150),
    //   0 8px 18px 0 var(--GREYS-NORMAL-100);
    background-color: var(--WHITES-NORMAL-1000);
  }
}
.viewbarActions {
  display: flex;
  flex: 1;
  &.hide {
    display: none;
  }
  width: 100%;
  max-width: 375px;
  // -webkit-backdrop-filter: blur(2px);
  // backdrop-filter: blur(2px);
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  // box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-100),
  //   0 6px 10px -2px var(--GREYS-NORMAL-50), 0 7px 18px 0 var(--GREYS-NORMAL-100);
  background-color: var(--WHITES-NORMAL-1000);  
  pointer-events: all;
  .rootViewbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    z-index: 1;
    // box-shadow: 0 2px 3px 0 var(--GREYS-NORMAL-200),
    //   0 6px 10px -2px var(--GREYS-NORMAL-150),
    //   0 8px 18px 0 var(--GREYS-NORMAL-100);
    background-color: var(--WHITES-NORMAL-1000);
  }
  .viewbarAction {
    &:hover {
      cursor: pointer;
    }
  }
  .viewbarSelectedAction {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }
  .actionHeader {
    @extend .TEXTSUBTITLEblackmedium-emphasisright;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
  }
  .actionHeaderSeparator {
    // width: 355px;
    width: 100%;
    height: 1px;
    background-color: var(--COLOR-SECONDARY-200);
  }
  .exitAction {
    margin-left: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  .actionType {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;
  }
  .actionIcon {
    margin-left: 10px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    &:hover {
      cursor: unset;
    }
  }
}

