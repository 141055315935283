@import '../../../typography';
.inputBarEditorContainer {
  display: flex;
  align-items: center;  
  border: none !important;  border-radius: 18px;
  background-color: var(--COLOR-SECONDARY-200);
  &.dark {
    // background-color: rgba(255, 255, 255, .2);
    background-color: transparent;
  }
  padding: 2px;
  width: 100%;
  max-width: 752px;
  .inputBarInnerBorder {
    min-height: 36px;
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 18px;
    background-color: #fcfcfc;
    &.dark {
      background-color: rgba(255, 255, 255, .2);
    }
    position: relative;
    textarea {
      // background-color: var(--WHITES-NORMAL-100);
      padding: 0px 0px 0px 6px;
      margin-bottom: 3px;
      @extend .TEXTBODYblackregularhigh-emphasisleft;
      background-color: transparent;
      height: 24px;
      min-height: 24px;
      max-height: 133px;
      box-shadow: none;
      resize: none;
      border: none;
      outline: none;
      display: flex;
      flex: 1;
      ::placeholder {
        @extend .TEXTBODYblackregularlow-emphasisleft;
      }
    }
    .ellipsis {
      @extend .TEXTBODYblackregularhigh-emphasisleft;
      display: none;
      &.showEllipsis {
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        &.dark {
          color: white
        }
      }
    }
  }
}
