.quickSlideTypeChooserContainer {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 2px;
  padding-bottom: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .settings {
    display: flex;
    justify-content: space-between;
  }
  .quickSlideTypeSelections {
    display: flex;
    flex-wrap: wrap;
  }
}