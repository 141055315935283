@use '../../../../typography';
@use '../../../../gides-colors';

.gideListItemPreviewContainer {
  display: flex;
  flex: 1;
  position: relative;
  max-width: 100%;
  .logo {
    margin-top: 15px;
    margin-left: 26px;
    position: absolute;
    z-index: 100;
    color: var(--WHITES-NORMAL-1000);
  }
  .logoBackground {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    margin-top: 14px;
    margin-left: 23px;
    background: var(--GREYS-NORMAL-100);
  }
  .gideSelection {
    width: 18px;
    height: 18px;
    margin: 12px 5px 0 5px;
    border-radius: 50%;
    background: white;
  }
}
.gideListItemImage {
  img {
    width: 76px;
    height: 54px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.gideListItemPreviewDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 8px;
  margin-top: 10px;
  max-width: calc(100% - 95px);
  .gideListPreviewAvatar {
    display: flex;
    align-items: center;
    padding: 0px 0px 5px 0px;
  }
}
.gideListItemTitle {
  height: 18px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @extend .TEXTSUBTITLEblackhigh-emphasisleft;
}
.gideListItemGideDetails {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
}
.gideInfo {
  display: flex;
  align-items: center;
  .calendarImage {
    margin: 0px 0px 0px 9px;
  }
}
.gideInfoDetail {
  margin-left: 2px;
}
.clickable {
  cursor: pointer;
  &:hover {
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}
