.linkSlideEditorContainer {
  @extend %flexColumnFull;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .linkSlideEditorAddContainer,
  .linkSlideEditorEditContainer {
    width: 100%;
    max-height: 100%;
    max-width: 752px;
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    &.slideChooserMode {
      max-width: 100%;
    }
    padding: 24px 16px;
    margin-bottom: 94px;
  }
  .linkSlideEditorAddContainer {
    .channelChooserContainer,
    .articleChooserContainer {
      max-width: 752px;
    }
  }

  .linkEditor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
