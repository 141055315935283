.imageDrawContainer {
  @extend %flexColumnFull;
  width: 100%;
  position: relative;
  .imageDraw {
    flex: 1;
    max-width: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .sourceImage {
      // object-fit: scale-down;
      width: 100%;
      min-width: 100%;
      visibility: collapse;
      position: absolute;
      top: 0px;
      left: 0px;
      &.useNativeResolution {
        width: auto !important;
        min-width: auto !important;
        max-width: 100% !important;
        object-fit: scale-down;
      }
    }

    .imageDrawSketchField {
      &.hideSketch {
        display: none;
      }
    }
  }

  .imageDrawActionContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .imageDrawActions {
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      .ms-Button.ms-Button--action.ms-Button--command {
        border: solid 2px white !important;
      }

      .imageDrawActionButtons {
        position: absolute;
        right: 14px;

        display: flex;
        justify-content: space-between;
      }
    }
  }
  .imageDrawFloatingActions {
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .alignmentOptions {
      display: flex;
      flex-direction: row;
    }
  }
}
@media only screen and (max-width: 520px) {
  .imageDrawContainer {
    .imageDrawActions {
      flex-direction: column;
      align-items: flex-end !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .imageDrawContainer {
    .imageDrawActions {
      .imageDrawActionButtons {
        position: initial !important;
      }
    }
  }
}

.colorCell-47 {
  padding: 0px !important;
  width: 36px;
  height: 36px;
}
.colorCell-47::before {
  display: none;
}

