.gideImageSwipeContainer {
  // max-height: 482px;
  max-width: $MAXSLIDEWIDTH;
  display: flex;
  align-items: center;
  .gideImageSwipe {
    position: relative;
    display: flex;
    flex: 1;
    // margin-top: 44px;
    padding-top: 60.25%;

    .gideImageSwipeImage1 {
      display: flex;
      flex: 1;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 50%;
      bottom: 50%;
      object-fit: cover;
      img {
        object-fit: cover;
        width: 100%;
      }
      // background: red;
    }
    .gideImageSwipeImage2 {
      display: flex;
      flex: 1;
      position: absolute;
      left: 50%;
      top: 0px;
      right: 0px;
      bottom: 50%;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    .gideImageSwipeImage3 {
      display: flex;
      flex: 1;
      position: absolute;
      left: 0px;
      top: 50%;
      right: 50%;
      bottom: 0px;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    .gideImageSwipeImage4 {
      display: flex;
      flex: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      right: 0px;
      bottom: 0px;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    .lastImageOverlay {
      display: flex;
      flex: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      right: 0px;
      bottom: 0px;
      justify-content: center;
      align-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      font-family: Aileron;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(255, 255, 255, 0.95);
      &:hover {
        // border: solid 2px $gold;
      }
    }

    .swipeRow {
      display: flex;
    }
    .swipeImage {
      width: 313px;
      height: 221px;
      object-fit: cover;
      // border: solid 1px black;

      &:hover {
        // border: solid 2px $gold;
      }
    }
    .lastImage {
      width: 313px;
      height: 221px;
      position: relative;
      .swipeImage {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
      }
      .lastImageOverlay {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        font-family: Aileron;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.95);
        &:hover {
          // border: solid 2px $gold;
        }
      }
    }
    .centerIconContainer {
      position: absolute;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      background: black;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      img {
        width: 32px;
        height: 32px;
        margin-left: 3px;
        margin-bottom: 2px;
        // background: white;
      }
    }
  }
}

// apply this when width is greater than or equal to max slide width (currently 752px)
@media (min-width: #{$MAXSLIDEWIDTH}) {
  .website {
    // .imageSlideViewerContainer {
      .card.EDGE,
      .card.HALF {
        .gideImageSwipeContainer {
          margin-left: 0px;
          max-width: 100%;
        }
      }
    // }
  }
  .EDGE, .HALF {
    .gideImageSwipeContainer {
      margin-left: 0px;
      max-width: 100%;
      .gideImageSwipe {
        min-width: 100%;
      }
    }
  }
}
