.audioSlideEditor {
  @extend %flexColumnBase;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  justify-content: center;
  position: relative;
  align-items: center;
  display: flex;

  &.stacked {
    justify-content: flex-start;
  }
  .audioDropZone {
    width: 100%;
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    &.hidden {
      display: none;
    }
    .uploadIndicator {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      background-color: var(--COLOR-BLUE-100);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 103px;
      height: 30px;
      border-radius: 5px;
    }

    .uploadLabel {      
      @extend .TEXTH4whitemedium-emphasiscenter;
      margin-top: 5px;
    }
  }
  .audioSlideViewerContainer {
    padding-bottom: 93px;
  }
  // .audioLinkContainer {
  //   width: 100%;
  // }
  .audioViewContainer,
  .audioLinkContainer,
  .audioSlideViewerContainer {
    width: 100%;
    max-width: $MAXSLIDEWIDTH;
  }
  .audioLinkContainer {
    margin-bottom: 24px;
  }
  .embedIcons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 300px;
    margin-top: 6px;
    &.hasMediaLink {
      justify-content: left;
      max-width: $MAXSLIDEWIDTH;
      margin-top: 6px;
    }
  }
  .audioDropZone {
    width: 100%;
    height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    &.hidden {
      display: none;
    }
    .uploadIndicator {
      @extend .TEXTSUBTITLEwhitehigh-emphasisleft;
      background-color: var(--COLOR-BLUE-100);
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 163px;
      height: 32px;
      padding-left: 7px;
      border-radius: 3px;
    }

    .uploadLabel {
      margin-top: 5px;
      @extend .TEXTH4blackmedium-emphasiscenter;
    }
  }
}
.EDGE {
  .audioSlideEditor {
    // .audioViewContainer,
    // .audioLinkContainer
    .audioSlideViewerContainer {
      width: 100%;
      max-width: 100%;
      .audioViewContainer, .LinkInput {
        max-width: 100%;
      }
    }
  }
}


.audioSlideEditorCommandTrimExpandingIconButtonLabel {
  color: var(--COLOR-SECONDARY-600);
}