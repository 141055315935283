
.headerContainer {
  display: flex;
  flex-direction:column;  
  justify-content: center;
 
  box-shadow: 0 3px 2px 0 
    rgba(0, 0, 0, 0.08), 0 2px 1px -1px 
    rgba(0, 0, 0, 0.14), 0 1px 1px 0 
    rgba(170, 190, 190, 0.1);

  .headerMain {
    display: flex;
    flex: 1;
    align-items: center;
    z-index: 2;
    background-color: white;
    min-height: 52px;
    @media (max-width: 520px) {
      height: 48px;
      background-color: white;
      &.dropDownTitlePanelOpen {
        // height: 96px;
        min-height: 52px;
      }
    }
    .hamburgerMenu {
      display: flex;
      align-items: center;
      margin: 0px 12px 0 12px;
    }
    .menuSeparator {
      background-color: #dddddd;
      height: 52px;
      width: 1px;
      @media (max-width: 520px) {
        height: 39px;
        &.dropdownMenuOpen {
          margin-top: 5px;
          height: 43px;
        }
        &.guestViewer {
          @media(max-width: 520px) {
            display: none;
          }
        }
      }
    }
    .gidesLogoHomeNavigation {
      display: flex;
      align-items: center;
      margin-left: 18px;
      width: 265px;
      @media (max-width: 1074px) {
        display: none;
      }
    }
    .headerMainTitle {
      margin-left: 14px;
      &.guestViewer { // Gide is being viewed by someone that is not the owner.
        margin-left: 16px;
      }
      @media (max-width: 520px) {
        margin-left: 0px;
        &.imageNotSet {
          margin-left: 11px;
        }
        &.guestViewer {
          margin-left: 4px;
        }
      }
    }
    svg {
      cursor: pointer;
    }
  }
}
