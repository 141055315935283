@import '../../typography';
.transferSlidesModalContainer {
  &.showAsModal {
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    bottom:0;
    @media (max-width: 520) {
      top: 48px;
    }
  }
  min-width: 100%;
  display: flex;
  justify-content: center;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.8);
  .transferSlidesModal {
    background: transparent;
    // max-width: 752px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    .selectedSlidesForTransfer {
      padding-top: 12px;
      background-color: transparent;
      min-height: calc((100vh - 100px) / 2);
      max-height: calc((100vh - 100px) / 2);
      @media (max-width: 520) {
        min-height: calc((100vh - 104px) / 2);
        max-height: calc((100vh - 104px) / 2);
      }
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      :global(.slideView) { // slideview is not in a module so this allows it to be nexted in a module scss file
        padding-bottom: 3px !important;
      }
    }
    .selectedSlidesForMoveToAttachments {
      padding-top: 12px;
      background-color: transparent;
      min-height: calc((100vh - 30px) / 2);
      max-height: calc((100vh - 30px) / 2);
      @media (max-width: 520) {
        min-height: calc((100vh - 34px) / 2);
        max-height: calc((100vh - 34px) / 2);
      }
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      :global(.slideView) { // slideview is not in a module so this allows it to be nexted in a module scss file
        padding-bottom: 3px !important;
      }
    }
    .selectedSlidesForCopy {
      padding-top: 12px;
      background-color: transparent;
      // height: 100%;
      max-height: calc(100vh - 193px);
      @media (max-width: 520) {
        max-height: calc(100vh - 189px);
      }
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      :global(.slideView) { // slideview is not in a module so this allows it to be nexted in a module scss file
        padding-bottom: 3px !important;
      }
    }
    .messageDialogContainer {
      display: flex;
      // background: rgba(0, 0, 0, 0.4);
      position: relative;
      width: 100%;
      justify-content: center;      
      .centerModalPointerTriangle {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid white;
        position: absolute;
        right: calc(50% - 7px);
        top: -2px;
      }
  
      .messageDialog {
        border-radius: 2px;
        background-color: var(--WHITES-NORMAL-1000);
        background-color: #fafafa;
        margin-top: 3px;
        width: 360px;
        padding: 15px 13px 10px 13px;
        max-height: 129px;
        min-height: 129px;
        .messageDialogTitle {
          @extend .TEXTSUBTITLE-2blackmedium-emphasisleft !optional;
          display: flex;
          margin-left: 2px;
          margin-top: 3px;
          margin-bottom: 8px;
        }
        .slidePositionContainer {
          :global(.ui.input) {
            width: 334px;
            :global(input) {
              border-width: 0 0 1px 0;
            }
          }
        }
        .messageDialogActions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 9px;
          .messageDialogAction {
            @extend .TEXTSUBTITLEblacklow-emphasisleft !optional;
            width: 97px;
            height: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            &.cancelButton {
              @extend .TEXTSUBTITLEorangeleft !optional;
              background: rgba(255, 255, 255, 0.95);
              padding-left: 10px;
            }
            &.doneButton {
              padding-left: 10px;
              padding-right: 5px;
              // background-image: linear-gradient(284deg, #ffb213, #fab317);
              background-color: var(--COLOR-SECONDARY-200);
              // color: white;
            }
  
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .centerContent {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: transparent;
    }
    .slideSelection {
      min-height: calc((100vh - 100px) / 2);
      max-height: calc((100vh - 100px) / 2);
      @media (max-width: 520) {
        min-height: calc((100vh - 104px) / 2);
        max-height: calc((100vh - 104px) / 2);
      }
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }    
    .articleChooserContainer {
      &.slideTransfer {
        min-height: calc((100vh - 100px) / 2);
        max-height: calc((100vh - 100px) / 2);
        @media (max-width: 520) {
          min-height: calc((100vh - 104px) / 2);
          max-height: calc((100vh - 104px) / 2);
        }
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}