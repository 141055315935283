
.gidevideoSwipeContainer {
  display: flex;
  align-items:center;
  .gidevideoSwipe {      
      position: relative;
      display: flex;
      flex: 1;
      padding-top: 60.25%;
      .videoViewVideoContainer {
        justify-content: space-between;
        height: 100%;
        .videoViewCaptionContainer {
          display: none;
        }
        div {
          height: 100%;
          iframe {
            height: 100%;
          }
        }
      }
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
      img {
        object-fit: cover;
        width: 100%
      }
      .gidevideoSwipevideo1 {
        display: flex;
        flex: 1;
        position: absolute;
        left: 0px;
        top: 0px;
        right: 50%;
        bottom: 50%;
        // object-fit: cover;
        background: rgba(0,0,0,.8);
      }
      .gidevideoSwipevideo2 {
        display: flex;
          flex: 1;
          position: absolute;
          left: 50%;
          top: 0px;
          right: 0px;
          bottom: 50%;
          // object-fit: cover;
          background: rgba(0,0,0,.8);
      }
      .gidevideoSwipevideo3 {
        display: flex;
          flex: 1;
          position: absolute;
          left: 0px;
          top: 50%;
          right: 50%;
          bottom: 0px;
          background: rgba(0,0,0,.8);
      }
      .gidevideoSwipevideo4 {
        display: flex;
          flex: 1;
          position: absolute;
          left: 50%;
          top: 50%;
          right: 0px;
          bottom: 0px;
          background: rgba(0,0,0,.8);
      }
      .lastvideoOverlay {
        display: flex;
        flex: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        right: 0px;
        bottom: 0px;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.7);
        font-family: Aileron;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.95);
      }


      .swipeRow {
          display: flex;
      }
      .swipevideo {
          width: 313px;
          height: 221px;
          object-fit: cover;
      }
      .lastvideo {
        width: 313px;
        height: 221px;
        position: relative;
        .swipevideo {
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
        }
        .lastvideoOverlay {
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          background-color: rgba(0,0,0,.7);
          font-family: Aileron;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: center;
          color: rgba(255, 255, 255, 0.95);
        }
      }
      .centerIconContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        background: black;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        img {
          width: 32px;
          height: 32px;
          margin-left: 3px;
          margin-bottom: 2px;
        }
      }
  }
}
