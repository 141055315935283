%inputRange {
  @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
    box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
  }
  input {
    height: 1px;
    // width: 356px;
    width: 100%;
    outline: none;
    transition: all 450ms ease-in;

    -webkit-appearance: none;
  }
  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 32px;
    margin-bottom: 20px;
    background-image: url('/icons/custom/pointer.svg');
    background-position: top;
    background-size:24px;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
.inputRange {
  @extend %inputRange;
}
