.customAudioPlayer {
  display: flex;
  flex: 1;
  justify-content: stretch;
  align-content: center;
  align-items: center;
  height: 33px;
  // font-size: 14px;
  // line-height: 24px;
  // letter-spacing: 0.3px;
  // color: rgba(255, 255, 255, 0.85);
  min-height: 30px;
  .ui.progress .bar {
    min-width: 0px;
  }
  &.audioSlide {
    flex-direction: column;
    height: auto;
    .ui.progress .bar {
      height: 18px;
    }
  }
  &.blue {
    color: var(--COLOR-BLUE-100);
    .ui.progress .bar {
      background: var(--COLOR-BLUE-100);
    }
  }
  .ui.tiny.progress {
    height: 6px;
    margin: 0px;
  }
  .ui.medium.progress {
    height: 18px;
    margin: 0px;
  }

  .audioCaptionLabel {
    margin-right: 16px;
    display: flex;
    flex-wrap: nowrap;
  }

  .customAudioPlayerIcons {
    width: 24px;
    height: 24px;
  }

  .customAudioPlayerTimeFonts {
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    &.audioSlideFonts {
      margin-top: 2px;
    }
  }
  .audioCaptionIcon {
    opacity: 0.6;
    cursor: pointer;
  }
  .audioVolumeSlider {
    -webkit-appearance: none;
    height: 5.1px;
    opacity: 0.1;
    background-color: rgba(255, 255, 255, 0.24);
    &.blue {
      background-color: var(--COLOR-BLUE-100);
    }
    border-radius: 5px;
    // background: rgba(255, 255, 255, 0.24);
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    width: 121.8px;
    margin-left: 3px;
  }

  .audioVolumeSlider:hover {
    opacity: 1;
  }

  .audioVolumeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
    &.blue {
      background: var(--COLOR-BLUE-100);
    }
    cursor: pointer;
  }

  .audioVolumeSlider::-moz-range-thumb {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  .timeInfo, .playerControls, .centerControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .timeInfo, .playerControls {
    width: 100%;
  }
  .playerControls {
    margin-top: 4px;
  }
  .centerControls {
    width: 100px;
  }
}
.SLIDE,
.SCROLL {
  .customAudioPlayer {
    background-color: transparent;
  }
  .audioCaptionLabel {
    margin-left: 10px;
  }
}
@media (max-width: 520px) {
  .audioVolumeSlider {
    width: 50px;
  }
  .audioCaptionLabel {
    margin-right: 2px;
  }
}
.EDGE {
  .customAudioPlayer {
    img {
      width: auto !important; // Images are stretched to 100% in EDGE. Don't want images in player stretched. TODO: Probably need to add a class on the images we want stretched to 100% on EDGE
    }
  }
}